/*
 ******** Jegs Custom JQuery Library @ MVC Pattern*****************
 ******* Manages the Year ,Make , Model selection filter **************
 **************  *************************
 */
var JEGSVFTDriver = {};
var JEGS = JEGS || {};
jQuery( function($) {
	JEGSVFTDriver.Controller = {
		currentSearch : "",
		MkMdFILTERYEARs : "",
		MkMdFILTERMAKEs : "",
		MkMdFILTERMODELs : "",
		MkMdFILTERSUBMODELs : "",
		MkMdFILTERSUBENGINEs : "",
		XMLPOSTSERVLETMkMdYEARs : "",
		init : function() {
			JEGSVFTDriver.Controller.XMLPOSTSERVLETMkMdYEARs = "/webapp/wcs/stores/servlet/JEGSVehileFitmentView?storeId=10001&langId=-1&catalogId=10002"; // I
																																						// created
																																						// a
																																						// new
																																						// view
			JEGSVFTDriver.Controller.MkMdFILTERYEARs = "YEAR";
			JEGSVFTDriver.Controller.MkMdFILTERMAKEs = "MAKE";
			JEGSVFTDriver.Controller.MkMdFILTERMODELs = "MODEL";
			JEGSVFTDriver.Controller.MkMdFILTERSUBMODELs = "TYPE";
			JEGSVFTDriver.Controller.MkMdFILTERSUBENGINEs = "SIZE";
			JEGSVFTDriver.Controller.listenMkMdSelectBoxEventss(); // Bind
																		// all
																		// select
																		// box
																		// events
			JEGSVFTDriver.Controller.clearMkMdSelectBoxValuesFromCaches(); // This
																				// is
																				// for
																				// back
																				// button
		},
		resetIfNeeded : function() {
			if (JEGSVFTDriver.Controller.currentSearch != "") { // opening
																		// the
																		// modal
																		// with
																		// history,
																		// clear
																		// all
																		// drop
																		// downs
																		// except
																		// year
				$('#YEAR').val("");
				JEGSVFTDriver.Controller.clearFilter("MAKE");
				JEGSVFTDriver.Controller.clearFilter("MODEL");
				JEGSVFTDriver.Controller.clearAndHideSubModelEngineFilters();
			}
		},
		
		doYMMSearch : function() {
			var dimYearVal = $("#vft-vehicle-search #YEAR").val();
			var dimMakeVal = $("#vft-vehicle-search #MAKE").val();
			var dimModelVal = $("#vft-vehicle-search #MODEL").val();
			var dimSubModelVal = $("#vft-vehicle-search #SUBMODEL").val();
			var itemId = $("#vftItemID").val();
			var doSearch = true;
			if($.trim(dimModelVal) == '') {
				$("#vft-YMM-error").show();
				return;
			}
			if (doSearch == true) {
				JEGSVFTDriver.Controller.submitYMMSearch(itemId, dimYearVal,dimMakeVal,dimModelVal,dimSubModelVal);
			}
		},

		submitYMMSearch : function(aItemID, aYear, aMake, aModel, aSubModel) {
			var params = {};
			params["pItemID"] = aItemID;
			params["pYear"] = aYear;
			params["pMake"] = aMake;
			params["pModel"] = aModel;
			if(aSubModel){
				params["pSubModel"] = aSubModel;
			}
			$.ajax({
				method: "POST",
				data: params,
			    url: "/webapp/wcs/stores/servlet/JEGSVehileFitmentSearchView?storeId=10001&langId=-1&catalogId=10002"
			 })
			 .done(function(response, ioArgs) {
				if($.trim(response) != null || $.trim(response) != undefined){
					$("#pvf-fit-search-results-container").empty();
					$("#vft-main-dsktp").hide();
					$("#pvf-fit-search-results-container").html(response);
				}
			})
			.fail(function( jqXHR, textStatus) {
			      console.error("HTTP status code: ", textStatus);
			 });
		},

		listenMkMdSelectBoxEventss : function() {
			$('#YEAR').bind('change', function() {
				// Call the existing JEGS JS function
					JEGSVFTDriver.Controller.runYearMakeSearchs();// pass
																		// value
																		// (dimid)
																		// ,text
																		// (year
																		// 2010),product
																		// id as
																		// parameters..

				});
			$('#MAKE').bind('change', function() {
				// Call the existing JEGS JS function
					JEGSVFTDriver.Controller.runYearMkModelSearchs();
				});
			$('#MODEL').bind('change', function() {
				// Call the existing JEGS JS function
					JEGSVFTDriver.Controller.runSelectModelSearchs();
			});
		},
		clearMkMdSelectBoxValuesFromCaches : function() {
			var selectedYearVal = $('#YEAR').val();
			var selectedMakeVal = $('#MAKE').val();
			var selectedModelVal = $('#MODEL').val();
			if (selectedYearVal != "" || selectedYearVal != null) {
				$('#YEAR').val("");
			}
			if (selectedMakeVal == "" || selectedMakeVal == null) {
				$('#MAKE').val("");
			}
			if (selectedModelVal == "" || selectedModelVal == null) {
				$('#MODEL').val("");
			}
		},
		runYearMakeSearchs : function() {
			JEGSVFTDriver.Controller.clearAndHideSubModelEngineFilters();
			JEGSVFTDriver.Controller.clearFilter("MAKE");
			JEGSVFTDriver.Controller.clearFilter("MODEL");

			JEGS.log("Inside runFilterMakeSearch");
			JEGSVFTDriver.Controller
					.createMkMdSearchXMLDoc(JEGSVFTDriver.Controller.MkMdFILTERMAKEs); // Search
																							// for
																							// makes...
			var dimYearVal = $("#YEAR").val();
			var yearVal = $('#YEAR :selected').text();
			JEGSVFTDriver.Controller.addMkMdSearchCriteria(
					JEGSVFTDriver.Controller.MkMdFILTERYEARs, dimYearVal,
					yearVal);
			$("#MAKE").attr("disabled", true);
			$("#MODEL").attr("disabled", true);
			JEGSVFTDriver.Controller.currentSearch = "make";
			JEGS.log("current search is "
					+ JEGSVFTDriver.Controller.currentSearch);
			JEGSVFTDriver.Controller.refreshMkMdSelectRequest();
		},
		runYearMkModelSearchs : function() {
			JEGSVFTDriver.Controller.clearAndHideSubModelEngineFilters();
			JEGSVFTDriver.Controller.clearFilter("MODEL");
			JEGS.log("Inside runFilterModelSearch");
			JEGSVFTDriver.Controller
					.createMkMdSearchXMLDoc(JEGSVFTDriver.Controller.MkMdFILTERMODELs);
			var dimYearVal = $("#YEAR").val();
			var yearVal = $('#YEAR :selected').text();
			JEGSVFTDriver.Controller.addMkMdSearchCriteria(
					JEGSVFTDriver.Controller.MkMdFILTERYEARs, dimYearVal,
					yearVal);
			var dimMakeVal = $("#MAKE").val();
			var makeVal = $('#MAKE :selected').text();
			JEGSVFTDriver.Controller.addMkMdSearchCriteria(
					JEGSVFTDriver.Controller.MkMdFILTERMAKEs, dimMakeVal,
					makeVal);
			$("#MODEL").attr("disabled", true);
			JEGSVFTDriver.Controller.currentSearch = "model";
			JEGSVFTDriver.Controller.refreshMkMdSelectRequest();
		},
		runSelectModelSearchs : function() {
			JEGS.log("Inside runFilterModelSearch");
			$("#vft-YMM-error").hide();
			JEGSVFTDriver.Controller
					.createMkMdSearchXMLDoc(JEGSVFTDriver.Controller.MkMdFILTERSUBMODELs);
			var dimYearVal = $("#YEAR").val();
			var yearVal = $('#YEAR :selected').text();
			JEGSVFTDriver.Controller.addMkMdSearchCriteria(
					JEGSVFTDriver.Controller.MkMdFILTERYEARs, dimYearVal,
					yearVal);
			var dimMakeVal = $("#MAKE").val();
			var makeVal = $('#MAKE :selected').text();
			JEGSVFTDriver.Controller.addMkMdSearchCriteria(
					JEGSVFTDriver.Controller.MkMdFILTERMAKEs, dimMakeVal,
					makeVal);
			var dimMakeVal = $("#MODEL").val();
			var makeVal = $('#MODEL :selected').text();
			JEGSVFTDriver.Controller.addMkMdSearchCriteria(
					JEGSVFTDriver.Controller.MkMdFILTERMODELs, dimMakeVal,
					makeVal);
			JEGSVFTDriver.Controller.currentSearch = "submodel";
			JEGSVFTDriver.Controller.refreshMkMdSelectRequest();
		},
		runSelectSubModelSearchs : function() {
			JEGS.log("Inside runFilterModelSearch");
			JEGSVFTDriver.Controller
					.createMkMdSearchXMLDoc(JEGSVFTDriver.Controller.MkMdFILTERSUBENGINEs);
			var dimYearVal = $("#YEAR").val();
			var yearVal = $('#YEAR :selected').text();
			JEGSVFTDriver.Controller.addMkMdSearchCriteria(
					JEGSVFTDriver.Controller.MkMdFILTERYEARs, dimYearVal,
					yearVal);
			var dimMakeVal = $("#MAKE").val();
			var makeVal = $('#MAKE :selected').text();
			JEGSVFTDriver.Controller.addMkMdSearchCriteria(
					JEGSVFTDriver.Controller.MkMdFILTERMAKEs, dimMakeVal,
					makeVal);
			var dimMakeVal = $("#MODEL").val();
			var makeVal = $('#MODEL :selected').text();
			JEGSVFTDriver.Controller.addMkMdSearchCriteria(
					JEGSVFTDriver.Controller.MkMdFILTERMODELs, dimMakeVal,
					makeVal);
			var dimMakeVal = $("#SUBMODEL").val();
			var makeVal = $('#SUBMODEL :selected').text();
			JEGSVFTDriver.Controller.addMkMdSearchCriteria(
					JEGSVFTDriver.Controller.MkMdFILTERSUBMODELs,
					dimMakeVal, makeVal);
			$("#SUBENGINE").attr("disabled", true);
			JEGSVFTDriver.Controller.currentSearch = "engine";
			JEGSVFTDriver.Controller.refreshMkMdSelectRequest();
		},
		createMkMdSearchXMLDoc : function(type) {
			xmlDoc = createXMLDoc(); // calls custom method defined in
										// utils.js
			var searchCriteria = xmlDoc.createElement("SEARCH");
			xmlDoc.appendChild(searchCriteria);
			setSearchAttribute("type", type);
			setSearchAttribute("parent", "");
		},
		refreshMkMdSelectRequest : function() {
			JEGS.log("Inside refreshMkMdSelectRequest");
			var xmlStr = getxmlDocAsString(xmlDoc);
			JEGS.log("XmlStr is " + xmlStr);
			JEGSVFTDriver.Service.JEGSPostMkMdXMLAjaxService(
					JEGSVFTDriver.Controller.XMLPOSTSERVLETMkMdYEARs,
					xmlStr);
		},
		addMkMdSearchCriteria : function(filterName, filterDimValue, filterText) {
			JEGS.log("Inside addMkMdSearchCriteria");
			JEGSVFTDriver.Controller.addProdMkMdSearchCriteria(filterName,
					filterDimValue, filterText);
		},
		addProdMkMdSearchCriteria : function(name, value, text) {
			JEGS.log("Inside addProdMkMdSearchCriteria");
			var field = xmlDoc.createElement(name);
			field.setAttribute('value', value);
			var value = xmlDoc.createTextNode(text);
			field.appendChild(value);
			var searchCriteria = getSearchCriteria();
			searchCriteria.appendChild(field);
		},
		mkMdPopulateResults : function(results) {
			JEGS.log("got response for "
					+ JEGSVFTDriver.Controller.currentSearch + " search");
			if (JEGSVFTDriver.Controller.currentSearch == 'make') {
				if ($.trim(results) != '') {
					// $("#MAKE").empty().html(results); //filter Section					
					$("#MAKE").replaceWith($(results).filter('#MAKE')); // filter					
					var modelOption = $(results).filter('#MODEL');					
					if (modelOption.length > 0) {
						JEGSVFTDriver.Controller.currentSearch = 'model';
						JEGSVFTDriver.Controller.mkMdPopulateResults(results);
					}
				}
			} else if (JEGSVFTDriver.Controller.currentSearch == 'model') {
				if ($.trim(results) != '') {					
					$("#MODEL").replaceWith($(results).filter('#MODEL')); // filter					
					var submodelModelOption = $(results).filter('#SUBMODEL');									
					if (submodelModelOption.length > 0) {
						JEGSVFTDriver.Controller.currentSearch = 'submodel';
						JEGSVFTDriver.Controller.mkMdPopulateResults(results);
					}
				}
			} else if (JEGSVFTDriver.Controller.currentSearch == 'submodel') {
				if ($.trim(results) != '') {
					// $("#SUBMODEL").replaceWith(results); //filter Section
					$("#SUBMODEL").replaceWith($(results).filter('#SUBMODEL')); // filter					
					var enginOption = $(results).filter('#ENGINE');					
					if (enginOption.length > 0) {
						$("#ENGINE").replaceWith(enginOption);
					} else {
						$("#ENGINE").empty();
						$("#ENGINE").css("display", "none");
					}
					JEGSVFTDriver.Controller.autoSubmit();
				} else {
					JEGSVFTDriver.Controller.doYMMSearch();
				}
			} else if (JEGSVFTDriver.Controller.currentSearch == 'engine') {
				if ($.trim(results) != '') {
					$("#ENGINE").replaceWith(results); // filter Section
					if($("#ENGINE").find("option").length > 2) {
						$("#ENGINE").css("display", "block");						
					} else {
						JEGSVFTDriver.Controller.doYMMSearch();
					}
				} else {
					JEGSVFTDriver.Controller.doYMMSearch();
				}
			}
		},
		autoSubmit : function() {
			var subLength = $("#SUBMODEL").find("option").length;
			var engLength = $("#ENGINE").find("option").length;
			if(subLength > 1) {
				$("#SUBMODEL").css("display", "block");
			} else if(engLength > 2) {
				$("#SUBMODEL").css("display", "block");
				$("#ENGINE").css("display", "block");
			} else {
				JEGSVFTDriver.Controller.doYMMSearch();
			}
		},
		formatAjaxResponse : function() {
			var mmyAjaxResponseVal = $("#mmyAjaxResponse").val();
			if (mmyAjaxResponseVal != null
					&& mmyAjaxResponseVal == 'submitMMYCmd') {
				mmySubmit();
			}
		},
		clearAndHideSubModelEngineFilters : function() {
			$("#SUBMODEL").empty();
			$("#SUBMODEL").css("display", "none");
		},
		clearFilter : function(filterName) {
			JEGS.log("Emptying make and model filters");
			$("#" + filterName).empty();
			if (filterName == 'MAKE') {
				$("#" + filterName).append(
						"<option value=''>Make</option>");
			}
			if (filterName == 'MODEL') {
				$("#" + filterName).append(
						"<option value=''>Model</option>");
			}
		},
		addMkMdSearchCriteria : function(filterName, filterDimValue, filterText) {
			JEGS.log("Inside addMkMdSearchCriteria");
			JEGSVFTDriver.Controller.addProdMkMdSearchCriteria(filterName,
					filterDimValue, filterText);
		}

	};
	JEGSVFTDriver.Service = {
		JEGSPostMkMdXMLAjaxService : function(XMLPOSTSERVLETMkMdYEARs, xmlStr) {
			JEGS.log("In JEGSVFTDriver.Service.JEGSPostMkMdXMLAjaxService");
			if (JEGSVFTDriver.Service.ajaxRequestObject) {
				JEGSVFTDriver.Service.ajaxRequestObject.abort();
			}
			JEGS.log("xmlStr is " + xmlStr);
			$("#vft-YMM-wait").show();
			JEGSVFTDriver.Service.ajaxRequestObject = $.ajax( {
				url : XMLPOSTSERVLETMkMdYEARs,
				data : xmlStr,
				type : "POST",
				contentType : "text/xml",
				success : function(results) {
				$("#pvf-fit-search-results-container").empty();
				JEGSVFTDriver.Controller.mkMdPopulateResults(results);
				JEGSVFTDriver.Controller.listenMkMdSelectBoxEventss();
				$("#vft-YMM-wait").hide();
			},
			error : function(results) {
				JEGS.log(results);
			},
			cache : true
			});
		}
	};

	JEGSVFTDriver.Controller.init();
});
JEGS.isLogging = false; // Enable it to true if you want to trace the program
						// execution control
JEGS.log = function() {
	if (JEGS.isLogging && console) {
		console.log.apply(console, arguments);
	}
}
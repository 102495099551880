/*
 ******** Jegs Custom JQuery Library @ MVC Pattern*****************
 ******* Manages the Year ,Make , Model selection filter **************
 **************  *************************
 */
var JEGSMkMdYearDriver = {};
var JEGS = JEGS || {};
jQuery( function($) {
	JEGSMkMdYearDriver.Controller = {
		currentSearch : "",
		MkMdFILTERYEARs : "",
		MkMdFILTERMAKEs : "",
		MkMdFILTERMODELs : "",
		MkMdFILTERSUBMODELs : "",
		MkMdFILTERSUBENGINEs : "",
		XMLPOSTSERVLETMkMdYEARs : "",
		init : function() {
			JEGSMkMdYearDriver.Controller.XMLPOSTSERVLETMkMdYEARs = "/webapp/wcs/stores/servlet/MMYCboView?storeId=10001&langId=-1&catalogId=10002"; // I
																																						// created
																																						// a
																																						// new
																																						// view
			JEGSMkMdYearDriver.Controller.MkMdFILTERYEARs = "YEAR";
			JEGSMkMdYearDriver.Controller.MkMdFILTERMAKEs = "MAKE";
			JEGSMkMdYearDriver.Controller.MkMdFILTERMODELs = "MODEL";
			JEGSMkMdYearDriver.Controller.MkMdFILTERSUBMODELs = "TYPE";
			JEGSMkMdYearDriver.Controller.MkMdFILTERSUBENGINEs = "SIZE";
			JEGSMkMdYearDriver.Controller.listenMkMdSelectBoxEventss(); // Bind
																		// all
																		// select
																		// box
																		// events
			JEGSMkMdYearDriver.Controller.clearMkMdSelectBoxValuesFromCaches(); // This
																				// is
																				// for
																				// back
																				// button
		},
		resetIfNeeded : function() {
			if (JEGSMkMdYearDriver.Controller.currentSearch != "") { // opening
																		// the
																		// modal
																		// with
																		// history,
																		// clear
																		// all
																		// drop
																		// downs
																		// except
																		// year
				$('#YEAR').val("");
				JEGSMkMdYearDriver.Controller.clearFilter("MAKE");
				JEGSMkMdYearDriver.Controller.clearFilter("MODEL");
				JEGSMkMdYearDriver.Controller
						.clearAndHideSubModelEngineFilters();
			}
		},
		doYMMSearch : function() {
			var searchURL = "/webapp/wcs/stores/servlet/SearchResultsPageCmd?storeId=10001&langId=-1&sbv=true";
			var dimYearVal = $("#vehicle-search #YEAR").val();
			var dimMakeVal = $("#vehicle-search #MAKE").val();
			var dimModelVal = $("#vehicle-search #MODEL").val();
			var dimSubModelVal = $("#vehicle-search #SUBMODEL").val();
			if (dimSubModelVal === undefined) {
				dimSubModelVal = $("#classic-submodel #SUBMODEL").val();
			}
			var dimEngineVal = $("#vehicle-search #ENGINE").val();
			if (dimEngineVal === undefined) {
				dimEngineVal = $("#classic-submodel #ENGINE").val();
			}
			var nValue = '';

			var YMM_SPLIT = '~';

			$("#YMM-error").hide();
			var doSearch = false;
			if ($.trim(dimEngineVal) != '' && dimEngineVal != null) {
				// nValue=dimEngineVal;
				nValue = nValue + 'engine=' + dimEngineVal + '&';
				// nValue += dimEngineVal.replace('?N=','enginetype=');
				doSearch = true;
			}
			if ($.trim(dimSubModelVal) != '' && dimSubModelVal != null) {
				// nValue=dimSubModelVal;
				nValue = nValue + 'submodel=' + dimSubModelVal + '&';
				// nValue += dimSubModelVal.replace('?N=','submodel=');
				doSearch = true;
			}
			if ($.trim(dimModelVal) != '' && dimModelVal != null) {
				// nValue=dimModelVal;
				nValue = nValue + 'model=' + dimModelVal + '&';
				// nValue += dimModelVal.replace('?N=','model=');
				doSearch = true;
			} 
			
			if ($.trim(dimMakeVal) != '' && dimMakeVal != null) {
				// nValue=dimMakeVal;
				nValue = nValue + 'make=' + dimMakeVal + '&';
				// nValue += dimMakeVal.replace('?N=','make=');
			} 

			if ($.trim(dimYearVal) != '' && dimYearVal != null) {
				// nValue=dimYearVal;
				nValue = nValue + 'year=' + dimYearVal;
				// nValue += dimYearVal.replace('?N=','year=');
			}
			
			if($.trim(dimYearVal) == '' || dimYearVal == null) {
				$("#YMM-error").html('Please select Year, Make & Model');
				$("#YMM-error").show();
			}else if($.trim(dimYearVal) != '' && dimYearVal != null && ($.trim(dimMakeVal) == '' || dimMakeVal == null)) {
				$("#YMM-error").html('Please select a Make');
				$("#YMM-error").show();
			}else if($.trim(dimYearVal) != '' && dimYearVal != null && $.trim(dimMakeVal) != '' && dimMakeVal != null && ($.trim(dimModelVal) == '' || dimModelVal == null)) {
				$("#YMM-error").html('Please select a Model');
				$("#YMM-error").show();
			}else{
				$("#YMM-error").html('Please select Year, Make & Model');
				$("#YMM-error").hide();
			}

			var COMMA_SPLIT = ",";
			if (doSearch == true) {
				if (nValue != '')
					searchURL += '&' + nValue;

				var hostName = window.location.hostname;
				var strHref = hostName + searchURL.toString();
				var textYear = $("#YEAR option:selected").text();
				var textMake = $("#MAKE option:selected").text();
				var textModel = $("#MODEL option:selected").text();
				var textSubModel = $("#SUBMODEL option:selected").text();
				var textEngine = $("#ENGINE option:selected").text();

				nValue = dimYearVal + YMM_SPLIT + dimMakeVal + YMM_SPLIT
						+ dimModelVal + YMM_SPLIT + dimSubModelVal + YMM_SPLIT
						+ dimEngineVal;
				//console.log('nval is ' + nValue.replace('&', YMM_SPLIT));
				var value = strHref + COMMA_SPLIT + textYear + COMMA_SPLIT
						+ textMake + COMMA_SPLIT + textModel + COMMA_SPLIT
						+ nValue; // .replace('&',PIPE_SPLIT)
				// alert(value);
				if (textMake != "Make" && textModel != "Model") {
					JEGS.header.addRSCookie(value);
				}
				var dataLayerObj = {
					  'selectYear': textYear,
					  'selectMake': textMake,
					  'selectModel': textModel,
					    'event': 'shopbyVehicle'};
				if (textSubModel.trim() !== "" && textSubModel !== "Select SubModel") {
				    dataLayerObj.selectSubModel = textSubModel;
				}

				if (textEngine.trim() !== "" && textEngine !== "Select Engine") {
				    dataLayerObj.selectEngine = textEngine;
				}
				dataLayer.push(dataLayerObj);
				$("#YMM-wait").show();
				JEGSMkMdYearDriver.Controller.submitYMMSearch(searchURL);
			}
		},

		submitYMMSearch : function(searchURL) {
			$(location).attr('href', searchURL);
		},

		listenMkMdSelectBoxEventss : function() {
			$('#YEAR').bind('change', function() {
				// Call the existing JEGS JS function
					JEGSMkMdYearDriver.Controller.runYearMakeSearchs();// pass
																		// value
																		// (dimid)
																		// ,text
																		// (year
																		// 2010),product
																		// id as
																		// parameters..

				});
			$('#MAKE').bind('change', function() {
				// Call the existing JEGS JS function
					JEGSMkMdYearDriver.Controller.runYearMkModelSearchs();
				});
			$('#MODEL').bind('change', function() {
				// Call the existing JEGS JS function
					JEGSMkMdYearDriver.Controller.runSelectModelSearchs();
				});
			$('#SUBMODEL').bind('change', function() {
				// Call the existing JEGS JS function
					JEGSMkMdYearDriver.Controller.runSelectSubModelSearchs();
				});
			$('#ENGINE').bind('change', function() {
				// Call the existing JEGS JS function
				if($('#ENGINE').val().length > 0) {					
					JEGSMkMdYearDriver.Controller.doYMMSearch();
				} else {
					return false;
				}
			});
			$('#closeMdButton').bind(
					'click',
					function() {
						// Call the existing JEGS JS function
						JEGSMkMdYearDriver.Controller
								.clearSubModelBoxValuesFromCaches();
					});
		},
		clearSubModelBoxValuesFromCaches : function() {
			var selectedSubModelVal = $('#SUBMODEL').val();
			var selectedSubEngVal = $('#SUBENGINE').val();
			if (selectedSubModelVal != "" || selectedSubModelVal != null) {
				$('#SUBMODEL').val("");
			}
			if (selectedSubEngVal != "" || selectedSubEngVal != null) {
				$('#SUBENGINE').val("");
			}
			$("#SubModelDiv").hide();
		},
		clearMkMdSelectBoxValuesFromCaches : function() {
			var selectedYearVal = $('#YEAR').val();
			var selectedMakeVal = $('#MAKE').val();
			var selectedModelVal = $('#MODEL').val();
			if (selectedYearVal != "" || selectedYearVal != null) {
				$('#YEAR').val("");
			}
			if (selectedMakeVal == "" || selectedMakeVal == null) {
				$('#MAKE').val("");
			}
			if (selectedModelVal == "" || selectedModelVal == null) {
				$('#MODEL').val("");
			}
		},
		runYearMakeSearchs : function() {
			JEGSMkMdYearDriver.Controller.clearAndHideSubModelEngineFilters();
			JEGSMkMdYearDriver.Controller.clearFilter("MAKE");
			JEGSMkMdYearDriver.Controller.clearFilter("MODEL");

			JEGS.log("Inside runFilterMakeSearch");
			JEGSMkMdYearDriver.Controller
					.createMkMdSearchXMLDoc(JEGSMkMdYearDriver.Controller.MkMdFILTERMAKEs); // Search
																							// for
																							// makes...
			var dimYearVal = $("#YEAR").val();
			var yearVal = $('#YEAR :selected').text();
			JEGSMkMdYearDriver.Controller.addMkMdSearchCriteria(
					JEGSMkMdYearDriver.Controller.MkMdFILTERYEARs, dimYearVal,
					yearVal);
			$("#MAKE").attr("disabled", true);
			$("#MODEL").attr("disabled", true);
			JEGSMkMdYearDriver.Controller.currentSearch = "make";
			JEGS.log("current search is "
					+ JEGSMkMdYearDriver.Controller.currentSearch);
			JEGSMkMdYearDriver.Controller.refreshMkMdSelectRequest();
			$("#YMM-error").hide();
		},
		runYearMkModelSearchs : function() {
			JEGSMkMdYearDriver.Controller.clearAndHideSubModelEngineFilters();
			JEGSMkMdYearDriver.Controller.clearFilter("MODEL");
			JEGS.log("Inside runFilterModelSearch");
			JEGSMkMdYearDriver.Controller
					.createMkMdSearchXMLDoc(JEGSMkMdYearDriver.Controller.MkMdFILTERMODELs);
			var dimYearVal = $("#YEAR").val();
			var yearVal = $('#YEAR :selected').text();
			JEGSMkMdYearDriver.Controller.addMkMdSearchCriteria(
					JEGSMkMdYearDriver.Controller.MkMdFILTERYEARs, dimYearVal,
					yearVal);
			var dimMakeVal = $("#MAKE").val();
			var makeVal = $('#MAKE :selected').text();
			JEGSMkMdYearDriver.Controller.addMkMdSearchCriteria(
					JEGSMkMdYearDriver.Controller.MkMdFILTERMAKEs, dimMakeVal,
					makeVal);
			$("#MODEL").attr("disabled", true);
			JEGSMkMdYearDriver.Controller.currentSearch = "model";
			JEGSMkMdYearDriver.Controller.refreshMkMdSelectRequest();
			$("#YMM-error").hide();
		},
		runSelectModelSearchs : function() {
			JEGS.log("Inside runFilterModelSearch");
			JEGSMkMdYearDriver.Controller
					.createMkMdSearchXMLDoc(JEGSMkMdYearDriver.Controller.MkMdFILTERSUBMODELs);
			var dimYearVal = $("#YEAR").val();
			var yearVal = $('#YEAR :selected').text();
			JEGSMkMdYearDriver.Controller.addMkMdSearchCriteria(
					JEGSMkMdYearDriver.Controller.MkMdFILTERYEARs, dimYearVal,
					yearVal);
			var dimMakeVal = $("#MAKE").val();
			var makeVal = $('#MAKE :selected').text();
			JEGSMkMdYearDriver.Controller.addMkMdSearchCriteria(
					JEGSMkMdYearDriver.Controller.MkMdFILTERMAKEs, dimMakeVal,
					makeVal);
			var dimMakeVal = $("#MODEL").val();
			var makeVal = $('#MODEL :selected').text();
			JEGSMkMdYearDriver.Controller.addMkMdSearchCriteria(
					JEGSMkMdYearDriver.Controller.MkMdFILTERMODELs, dimMakeVal,
					makeVal);
			JEGSMkMdYearDriver.Controller.currentSearch = "submodel";
			JEGSMkMdYearDriver.Controller.refreshMkMdSelectRequest();
			$("#YMM-error").hide();
		},
		runSelectSubModelSearchs : function() {
			JEGS.log("Inside runFilterModelSearch");
			JEGSMkMdYearDriver.Controller
					.createMkMdSearchXMLDoc(JEGSMkMdYearDriver.Controller.MkMdFILTERSUBENGINEs);
			var dimYearVal = $("#YEAR").val();
			var yearVal = $('#YEAR :selected').text();
			JEGSMkMdYearDriver.Controller.addMkMdSearchCriteria(
					JEGSMkMdYearDriver.Controller.MkMdFILTERYEARs, dimYearVal,
					yearVal);
			var dimMakeVal = $("#MAKE").val();
			var makeVal = $('#MAKE :selected').text();
			JEGSMkMdYearDriver.Controller.addMkMdSearchCriteria(
					JEGSMkMdYearDriver.Controller.MkMdFILTERMAKEs, dimMakeVal,
					makeVal);
			var dimMakeVal = $("#MODEL").val();
			var makeVal = $('#MODEL :selected').text();
			JEGSMkMdYearDriver.Controller.addMkMdSearchCriteria(
					JEGSMkMdYearDriver.Controller.MkMdFILTERMODELs, dimMakeVal,
					makeVal);
			var dimMakeVal = $("#SUBMODEL").val();
			var makeVal = $('#SUBMODEL :selected').text();
			JEGSMkMdYearDriver.Controller.addMkMdSearchCriteria(
					JEGSMkMdYearDriver.Controller.MkMdFILTERSUBMODELs,
					dimMakeVal, makeVal);
			$("#SUBENGINE").attr("disabled", true);
			JEGSMkMdYearDriver.Controller.currentSearch = "engine";
			JEGSMkMdYearDriver.Controller.refreshMkMdSelectRequest();
		},
		createMkMdSearchXMLDoc : function(type) {
			xmlDoc = createXMLDoc(); // calls custom method defined in
										// utils.js
			var searchCriteria = xmlDoc.createElement("SEARCH");
			xmlDoc.appendChild(searchCriteria);
			setSearchAttribute("type", type);
			setSearchAttribute("parent", "");
		},
		refreshMkMdSelectRequest : function() {
			JEGS.log("Inside refreshMkMdSelectRequest");
			var xmlStr = getxmlDocAsString(xmlDoc);
			JEGS.log("XmlStr is " + xmlStr);
			JEGSMkMdYearDriver.Service.JEGSPostMkMdXMLAjaxService(
					JEGSMkMdYearDriver.Controller.XMLPOSTSERVLETMkMdYEARs,
					xmlStr);
		},
		addMkMdSearchCriteria : function(filterName, filterDimValue, filterText) {
			JEGS.log("Inside addMkMdSearchCriteria");
			JEGSMkMdYearDriver.Controller.addProdMkMdSearchCriteria(filterName,
					filterDimValue, filterText);
		},
		addProdMkMdSearchCriteria : function(name, value, text) {
			JEGS.log("Inside addProdMkMdSearchCriteria");
			var field = xmlDoc.createElement(name);
			field.setAttribute('value', value);
			var value = xmlDoc.createTextNode(text);
			field.appendChild(value);
			var searchCriteria = getSearchCriteria();
			searchCriteria.appendChild(field);
		},
		mkMdPopulateResults : function(results) {
			JEGS.log("got response for "
					+ JEGSMkMdYearDriver.Controller.currentSearch + " search");
			if (JEGSMkMdYearDriver.Controller.currentSearch == 'make') {
				if ($.trim(results) != '') {
					// $("#MAKE").empty().html(results); //filter Section					
					$("#MAKE").replaceWith($(results).filter('#MAKE')); // filter					
					var modelOption = $(results).filter('#MODEL');					
					if (modelOption.length > 0) {
						JEGSMkMdYearDriver.Controller.currentSearch = 'model';
						JEGSMkMdYearDriver.Controller.mkMdPopulateResults(results);
					}
				}
			} else if (JEGSMkMdYearDriver.Controller.currentSearch == 'model') {
				if ($.trim(results) != '') {					
					$("#MODEL").replaceWith($(results).filter('#MODEL')); // filter					
					var submodelModelOption = $(results).filter('#SUBMODEL');									
					if (submodelModelOption.length > 0) {
						JEGSMkMdYearDriver.Controller.currentSearch = 'submodel';
						JEGSMkMdYearDriver.Controller.mkMdPopulateResults(results);
					}
				}
			} else if (JEGSMkMdYearDriver.Controller.currentSearch == 'submodel') {
				if ($.trim(results) != '') {
					// $("#SUBMODEL").replaceWith(results); //filter Section
					$("#SUBMODEL").replaceWith($(results).filter('#SUBMODEL')); // filter					
					var enginOption = $(results).filter('#ENGINE');					
					if (enginOption.length > 0) {
						$("#ENGINE").replaceWith(enginOption);
					} else {
						$("#ENGINE").empty();
						$("#ENGINE").css("display", "none");
					}
					JEGSMkMdYearDriver.Controller.autoSubmit();
				} else {
					JEGSMkMdYearDriver.Controller.doYMMSearch();
				}
			} else if (JEGSMkMdYearDriver.Controller.currentSearch == 'engine') {
				if ($.trim(results) != '') {
					$("#ENGINE").replaceWith(results); // filter Section
					if($("#ENGINE").find("option").length > 2) {
						$("#ENGINE").css("display", "block");						
					} else {
						JEGSMkMdYearDriver.Controller.doYMMSearch();
					}
				} else {
					JEGSMkMdYearDriver.Controller.doYMMSearch();
				}
			}
		},
		autoSubmit : function() {
			var subLength = $("#SUBMODEL").find("option").length;
			var engLength = $("#ENGINE").find("option").length;
			if(subLength > 1) {
				$("#SUBMODEL").css("display", "block");
			} else if(engLength > 2) {
				$("#SUBMODEL").css("display", "block");
				$("#ENGINE").css("display", "block");
			} else {
				JEGSMkMdYearDriver.Controller.doYMMSearch();
			}
		},
		formatAjaxResponse : function() {
			var mmyAjaxResponseVal = $("#mmyAjaxResponse").val();
			if (mmyAjaxResponseVal != null
					&& mmyAjaxResponseVal == 'submitMMYCmd') {
				mmySubmit();
			}
		},
		clearAndHideSubModelEngineFilters : function() {
			$("#SUBMODEL").empty();
			$("#ENGINE").empty();
			$("#SUBMODEL").css("display", "none");
			$("#ENGINE").css("display", "none");
		},
		clearFilter : function(filterName) {
			JEGS.log("Emptying make and model filters");
			$("#" + filterName).empty();
			if (filterName == 'MAKE') {
				$("#" + filterName).append(
						"<option value=''>Select Make</option>");
			}
			if (filterName == 'MODEL') {
				$("#" + filterName).append(
						"<option value=''>Select Model</option>");
			}
		},
		addMkMdSearchCriteria : function(filterName, filterDimValue, filterText) {
			JEGS.log("Inside addMkMdSearchCriteria");
			JEGSMkMdYearDriver.Controller.addProdMkMdSearchCriteria(filterName,
					filterDimValue, filterText);
		}

	};
	JEGSMkMdYearDriver.Service = {
		JEGSPostMkMdXMLAjaxService : function(XMLPOSTSERVLETMkMdYEARs, xmlStr) {
			JEGS
					.log("In JEGSMkMdYearDriver.Service.JEGSPostMkMdXMLAjaxService");
			if (JEGSMkMdYearDriver.Service.ajaxRequestObject) {
				JEGSMkMdYearDriver.Service.ajaxRequestObject.abort();
			}
			JEGS.log("xmlStr is " + xmlStr);
			$("#YMM-wait").show();
			JEGSMkMdYearDriver.Service.ajaxRequestObject = $.ajax( {
				url : XMLPOSTSERVLETMkMdYEARs,
				data : xmlStr,
				type : "POST",
				contentType : "text/xml",
				success : function(results) {
					// JEGS.log("Response got " + results);
				JEGSMkMdYearDriver.Controller.mkMdPopulateResults(results);
				JEGSMkMdYearDriver.Controller.listenMkMdSelectBoxEventss();
				// JEGSMkMdYearDriver.Controller.formatAjaxResponse();
				$("#YMM-wait").hide();
			},
			error : function(results) {
				JEGS.log(results);
			},
			cache : true
			});
		}
	};

	JEGSMkMdYearDriver.Controller.init();
});
JEGS.isLogging = false; // Enable it to true if you want to trace the program
						// execution control
JEGS.log = function() {
	if (JEGS.isLogging && console) {
		console.log.apply(console, arguments);
	}
}
// is ticker.js loaded - for later use
if (RT.load == undefined) {
  RT["load"] = {
    tick: function (a, b, c, d, e, f, g) {}
  }
};

$.extend(RT, {
  logger: 1,
  isREADYinit: false,
  isDOMREADYinit: false,
  isLOADEDinit: false,
  isREADY: {
    init: function (event) {
      console.log('isREADY');
      if (!RT.isREADYinit) {
        RT.setVars();
        RT.logger.create();
        RT.READY_modules.init();
        RT.isREADYinit = true;
      }
    }
  },
  isDOMREADY: {
    init: function (event) {
      console.log('isDOMREADY');
      if (!RT.isDOMREADYinit) {
      for (var module in DOMREADY) {
        DOMREADY[module].init(); 
      }
      RT.isDOMREADYinit = true;
      }
    }
  },
  isLOADED: {
    init: function (event) {
      if (!RT.isREADYinit) { // handle "LOAD" event firing before "READY" when cached files exist
        RT.isREADY.init();
      };
      if (!RT.isLOADEDinit) {
        RT.log('isLOADED', 1);
        RT.LOAD_modules.init();  
        RT.isLOADEDinit = true;
      };
    }
  },
  isRESIZED: {
    init: function (event) {
      for (var module in RESIZE) {
        RESIZE[module].init();
      }
    }
  },
  isSCROLLED: {
    init: function (event) {
      for (var module in SCROLL) {
        SCROLL[module].init();
      }
    }
  },
  isHASHCHANGED: {
    init: function () {
      RT.log('isHASHCHANGED ...', this);
      for (var module in HASHCHANGE) {
        HASHCHANGE[module].init();
      }
    }
  },
  isAJAX: {
    success: function (event, xhr, options) {
      RT.log('isAJAX.success ...', this);
      RT.AJAX_success_modules.init(event, xhr, options);
    }
  },
  READY_modules: {
    // run all "READY" [module] init() fxns 
    init: function () {
      RT.load.tick('Runtime', 'READY init', 0, 1, 0, 0, 0);
      for (var module in READY) {
        RT.load.tick('Runtime', 'READY_' + module, 0, 1, 0, 0, 0);
        READY[module].init();
        RT.load.tick('Runtime', 'READY_' + module, 0, 0, 1, 0, 0);
      }
      RT.load.tick('Runtime', 'READY init', 0, 0, 1, 0, 0);
    }
  },
  LOAD_modules: {
    // run all "LOAD" [module] init() fxns 
    init: function () {
      RT.load.tick('Runtime', 'LOAD init', 0, 1, 0, 0, 0);
      for (var module in LOAD) {
        RT.load.tick('Runtime', 'LOAD_' + module, 0, 1, 0, 0, 0);
        LOAD[module].init();
        RT.load.tick('Runtime', 'LOAD_' + module, 0, 0, 1, 0, 0);
      }
      RT.load.tick('Runtime', 'LOAD init', 0, 0, 1, 0, 0);

      // call "afterLoad" fxns (3rd party) which should not affect page load time
      setTimeout(function () {
        RT.load.tick('Runtime', 'AfterLOAD JS', 0, 1, 0, 0, 0);
        RT.AFTER_LOAD_modules.init();
        RT.load.tick('Runtime', 'AfterLOAD JS', 0, 0, 1, 0, 0);

        // END overall runtime events
        RT.load.tick('Runtime','', 0 , 0, 0, 1, 0);
      }, 100);
    }
  },
  AFTER_LOAD_modules: {
    init: function () {
      for (var module in AFTERLOAD) {
        AFTERLOAD[module].init();
      }
    }
  },
	AJAX_success_modules: {
    init: function (e, xhr, options) {
      RT.load.tick('Runtime', 'AJAX SUCCESS init', 0, 1, 0, 0, 0);
      for (var module in AJAX.SUCCESS) {
        RT.load.tick('Runtime', 'AJAX_SUCCESS_' + module, 0, 1, 0, 0, 0);
        AJAX.SUCCESS[module].init(e, xhr, options);
        RT.load.tick('Runtime', 'AJAX_SUCCESS_' + module, 0, 0, 1, 0, 0);
      }
      RT.load.tick('Runtime', 'AJAX SUCCESS init', 0, 0, 1, 0, 0);
    }
	},
  log: function (a, b) { //default empty
  },
  // loaded before onReady - no jQuery functionality b/c not loaded yet
  vars: {
    logging: 0, // turn on/off all console.logs where JEGS.[module].logger=1 (url override: ?logs=1)
    logall: 0, // turn on/off all console.logs - regardless (url override: ?logall=1)
		jQueryVers: jQuery.fn.jquery,
		jsPath: "/wcsstore/JegsDirect",
		jsVers: "/jeg_ver_xxx", // TODO: update based on time
		body: $('body'),
    ua: window.navigator.userAgent.toLowerCase(),
    iOS: /iPad|iPhone|iPod/.test(navigator.platform),
    Safari: !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/),
    staticDomain: '',
    domain: document.location.hostname,
    href: document.location.href,
    page: document.location.pathname,
    params: {},
    queryString: document.location.search,
    bp: {
      xs: 400,
      sm: 600,
      md: 800,
      lg: 1024, // desk
      xl: 1440 // large
    },
    touch: 0,
    webView: 0,
    IE_Edge: 0,
    ieVersion: 0,
    useGIFs: false,
    keyCodes: {
      'TAB': 9,
      'RETURN': 13,
      'ESC': 27,
      'SPACE': 32,
      'PAGEUP': 33,
      'PAGEDOWN': 34,
      'END': 35,
      'HOME': 36,
      'LEFT': 37,
      'UP': 38,
      'RIGHT': 39,
      'DOWN': 40
    }
  },
  setVars: function () {
    RT.vars.params = RT.url.params();
    RT.vars.paramsArray = RT.url.paramsArray();
    RT.vars.isLoggedIn = $('#LOGGEDinID').length;
    RT.fxns.ieVersion();
    RT.fxns.isIE_Edge();
    RT.fxns.isTouch_WebView();
    RT.fxns.hasStorage();
    RT.touch.detect();
    RT.ui.bodyClasses();
    RT.vars.useGIFs = ($(window).width() < RT.vars.bp.md ? true : false);
    RT.fxns.ticker();
    RT.ui.helper();
    RT.ui.setStaticSpinnerURL();
	},
  logger: {
    // custom console.log fxn - utilize logging for individual modules on/off
    create: function () {
			var isDev = /192/.test(RT.vars.domain);
      var logging = RT.vars.params.logging; //queryString param
      var logall = RT.vars.params.logall; //queryString param
      if (logging == 1 || isDev) {
        RT.vars.logging = 1;
      }
      if (logall == 1) {
        RT.vars.logall = 1;
      }
      // obj passed is the caller fxn's JSON parent OR num: 1/0
      if (RT.vars.logging == 1 || logall == 1) {
        console.log("create RT.log")
        RT.log = function (txt, obj) {
          var log = 1; // default ON when -> ex. RT.log("text")
          // console.log("object type: " + typeof (obj));
          if (typeof (obj) == "number") {
            //console.log("number: ", obj)
            if (obj == 1) {
              log = 1
            } else {
              log = 0
            } //  obj passed in value = 0/1
          } else if (typeof (obj) == "object") {
            //console.log("object: ", obj)
            try {
              var str = JSON.stringify(obj);
            } catch (e) {
              //console.log("return...")
              return
            }
            //console.log("str: "+str);
            var module = RT.data.getObjectFromString(str);
            log = module.logger;
            //console.log("log: "+module.logger)
          }

          if (log == 1 && RT.vars.logging || RT.vars.logall) {
            // console.log("log vals: " + " | " +log+ " | " + RT.vars.logging)
            console.log(txt);
          }
        }
      }
    }
  },
  // add runtime functions which set variables - (then, delete the fxn?)
  fxns: {
	ieVersion: function(){
	  var sAgent = window.navigator.userAgent;
	  var Idx = sAgent.indexOf("MSIE");
      var val = 0;
	  // If IE, return version number.
	  if (Idx > 0) 
	  	val = parseInt(sAgent.substring(Idx+ 5, sAgent.indexOf(".", Idx)));
       // If IE 11 then look for Updated user agent string.
	  else if (!!navigator.userAgent.match(/Trident\/7\./)) 
		  val = 11;
	  RT.vars.ieVersion = val;

    },
    isIE_Edge: function () {
      var val = 0,
        ua = window.navigator.userAgent,
        trident = ua.indexOf('Trident/'),
        edge = ua.indexOf('Edge/');
      if (trident > 0) { // IE 11 =>  return version number
        val = 1;
      } else if (edge > 0) { // Edge (IE 12+) => return version number
        val = 1;
      }
      RT.vars.IE_Edge = val;
      if (val == 1) {$('body').addClass('ie')}
    },
    isTouch_WebView: function () {
      var standalone = window.navigator.standalone, // iOS full screen mode
        userAgent = RT.vars.ua,
        ios = /iphone|ipod|ipad/.test(userAgent),
        safari = /safari/.test(userAgent),
        mobile = /mobile/.test(userAgent),
        isNativeApp = /version\/[0-9]/.test(userAgent),
        val = 0;
      
      RT.vars.iOS = ios;
      RT.vars.Safari = safari;
      if (ios || mobile) {
        RT.touch.set();
      };
      if ((ios && !standalone && !safari) || (!ios && isNativeApp && mobile)) {
        RT.vars.webView = 1
      };
      window.addEventListener('touchstart', function() {
    	 $('body').addClass('touch-enabled');
      });
    },
    hasStorage: function () {
      // check if cookies / localStorage / sessionStorage is allowed
      RT.vars.hasStorage = 1;
      try {
        localStorage.setItem("name", "storage");
      } catch (e) {
        RT.vars.hasStorage = 0;
      }
    },
    cookie: {
      get: function (name) {
        var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
        return v ? v[2] : null;
      },
      set: function (name, value, days) {
        var d = new Date;
        d.setTime(d.getTime() + 24*60*60*1000*days);
        document.cookie = name + "=" + value + ";path=/;expires=" + d.toGMTString();
      },
      del: function (name) {
        RT.fxns.cookie.set(name, '', -1);
      }
    },
    ticker: function () {
      var tickerOnOff = RT.vars.params.ticker;
      if (tickerOnOff === '0') {
        RT.fxns.cookie.del('ticker')
      } else if (tickerOnOff == 1) {
        RT.fxns.cookie.set('ticker', 1, 1)
      }
    },
		setLastFocus: function (e) {
			RT.vars.lastFocusObj = $(e.currentTarget);
		}
  },
	touch: {
		detect: function () {
      // simulate iOS Safari browser for trouble-shooting
      if (RT.vars.params.iOSSafari || RT.vars.params.touch) { // ?=1
        RT.vars.iOS = 1;
        RT.vars.Safari = 1;
        RT.touch.set();
      }
		},
		set: function () {
      $('body').addClass('touch-enabled');
			RT.vars.touch = true;
		}
	},
	url: {
		logger: 0,
		param: function (key) { // ?[key]=[r]
			// global regex parsing of the queryString
			var re = new RegExp('(?:\\?|&)' + key + '=(.*?)(?=&|$)', 'gi');
			var r = [], m;
			while ((m = re.exec(document.location.search)) != null) {
				r.push(m[1]);
			}
			return r;
		},
		// load the URL params into RT.vars.params = {} key/val pairs
		params: function (url) {
      if (!url) {
        var url = document.location.toString().replace('#' + RT.url.hash(), '');
      }
			var qs = url.split('?');
      var QSparams = {};
			if (qs.length > 1) {
				// RT.log("extend URL params to JSON", this);
				QSparams = RT.url.QStoJSON(qs[1]);
			}
			return QSparams;
    },
    paramsArray: function (url) {
        if (!url) {
          var url = document.location.toString().replace('#' + RT.url.hash(), '');
        }
  			var qs = url.split('?');
        var QSparams = {};
  			if (qs.length > 1) {
  				// RT.log("extend URL params to JSON", this);
  				QSparams = RT.url.QStoJSONArray(qs[1]);
  			}
  			return QSparams;
      },
    JSONtoQS: function (obj) {
      var str = '';
      for(key in obj) {
        str += key + '=' + obj[key] + '&';
      };
      str = str.slice(0, str.length - 1);
      return str; 
    },
		// queryString to JSON variables
		QStoJSON: function (QS) {
			var arr = QS.split("&");
			var data = {};
			
			for (var i = 0; i < arr.length; i++) {
				keyVal = arr[i];
				var idx = keyVal.indexOf("="),
					key = keyVal.substring(0, idx),
					val = keyVal.substring(idx + 1, keyVal.length);
					data[key] = val;	
			}
			return data;
		},
		QStoJSONArray: function(QS){
			var arr = QS.split("&");
			var data = {};
			
			for (var i = 0; i < arr.length; i++) {
				keyVal = arr[i];
				var idx = keyVal.indexOf("="),
					key = keyVal.substring(0, idx),
					val = keyVal.substring(idx + 1, keyVal.length);
				if(data[key] != undefined){
					data[key].push(val);
				}
				else{
					data[key] = [val];
				}
				
			}
			return data;	
		},
		hash: function () { // #digitalCard
			var url = document.location,
				urlStr = url.toString(),
				arr = urlStr.split('#'),
				tag = ''
			if (arr.length == 2) {
				tag = arr[1];
			}
			return tag;
		},
		loc: function (path) {
			document.location = path;
		}
	},
	ajax: {
    script: function (scripts, callback) {
      // allows for multiple scripts to be requested with a callback
      // https://css-tricks.com/snippets/javascript/async-script-loader-with-callback/

      var Loader = function () { }
      Loader.prototype = {
        require: function (scripts, callback) {
          this.loadCount      = 0;
          this.totalRequired  = scripts.length;
          this.callback       = callback;
  
          for (var i = 0; i < scripts.length; i++) {
              this.writeScript(scripts[i]);
          }
        },
        loaded: function (evt) {
          this.loadCount++;
          if (this.loadCount == this.totalRequired && typeof this.callback == 'function') this.callback.call();
        },
        writeScript: function (src) {
          var self = this;
          var s = document.createElement('script');
          s.type = "text/javascript";
          s.async = true;
          s.src = src;
          s.addEventListener('load', function (e) { self.loaded(e); }, false);
          var head = document.getElementsByTagName('head')[0];
          head.appendChild(s);
        }
      };

      var fn = function (scriptsArr, callBack) {
        var l = new Loader();
        l.require(scriptsArr, callBack);
      };
      
      fn(scripts, callback);

      /*return {
        // load: Loader
        loadAndCall: fn
      }*/
    },
		getScript: function (scriptData, classSelector, callBack) { // , callBack
			// non-cache-busting ajax request : will load a script already in cache
			var id = scriptData.id;
	    var scriptObj = $('#' + id);
	    if (scriptObj.length) {
        RT.log("script: " + id + " exists, cancelling JS request...", this);
        if (scriptData.module && scriptData.file) { // see feedback.js  //  && JEGS[scriptData.module].load != undefined
          JEGS[scriptData.module].load();
        }
				return false;
	    } else {
	    	RT.log("RT.ajax.getScript: " + JSON.stringify(scriptData), this);
        var head = document.getElementsByTagName("head")[0],
            classSelector = scriptData.targetClassName,
            url = scriptData.url,
            file = scriptData.file,
            jsSrc = RT.vars.ajaxPath + file,
	    	    js = document.createElement("script");
						  js.type = "text/javascript";
              js.id = scriptData.id;

        if (url) { jsSrc = url }
        RT.log("jsSrc: " + jsSrc, this);
        if (classSelector) {
          RT.log("find-> " + classSelector, this)
          document.getElementsByClassName(classSelector)[0].appendChild(js);
        } else {
          head.appendChild(js);
        };
				js.src = jsSrc;
			}
		},
		send : function(url,statusFx,successFx,errorFx){
		  $.ajax({	
			  url: url,
		      type: "GET",
		      cache: true,
		      async: false,
		      beforeSend: function (xhr) {
		       if(statusFx != undefined)
		    	 statusFx(); 
		      },
		      complete: function (xhr, status) {
		        RT.log("complete", 1);
		      },
		      error: function (xhr, status, error) {
		        RT.log("error", 1);
		        console.log(error);
		        if(errorFx != undefined)
		          errorFx(error);
		      },  			
		      success: function (results, status, xhr) {
		        RT.log("success", 1);
		        if(successFx != undefined)
		          successFx(results, status, xhr);
		      }
		  });   
		}
	},
  data: {
    logger: 0,
    str: function (obj) {
      return JSON.stringify(obj)
    },
    retJSONasArrayOfObjects: function (OBJECT) {
      var arr = []; // create Array of {objects}
      for (var key in OBJECT) {
        if (OBJECT.hasOwnProperty(key)) {
          arr[key] = OBJECT[key]
        }
      };
      return arr;
    },
    // use this to evealuate an object / JSON formatted string to return JSON/object
    // used by RT.log() fxn by passing in "this"
    getObjectFromString: function (objString) {
      if (objString === undefined) {
        return undefined;
      }
      try {
        return $.parseJSON(objString);
      } catch (e) {
        try {
          return $.parseJSON("{" + objString.replace(/'/g, '"') + "}");
        } catch (e) {
          return eval("(" + objString + ")");
        }
      }
		},
		validate: {
			email: function (val) {
				var TF = false;
				if (val.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
					TF = true
				}
				return TF
			},
			phone: function (val) {
				var TF = false;
				if (val.match(/^((\+*[1-9]{1,4}[ \-]*)|(\([0-9]{2,3}\)[ \-]*)|([0-9]{2,4})[ \-]*)*?[0-9]{3,4}?[ \-]*[0-9]{3,4}?$/)) {
					TF = true
				}
				return TF
			},
			lengthRange: function (val, min, max) {
				var TF = false,
				len = val.length;
				if (min <= len && len <= max) {
					TF = true
				}
				return TF
			}
		}
	},
	ui: {
    XY: function (e) { // keep current X|Y corrdinates saved
      var x = e.clientX, y = e.clientY;
      RT.vars.mouse = {x: x, y: y}
    },
    bodyClasses: function () {
      // EXECUTED PRIOR TO CSS RENDERING, some jumpiness will be visible
      if (RT.vars.page == "/") {
        $('body').addClass('home');
        if (RT.vars.touch === 0 && $(window).width() >= RT.vars.bp.lg) { // 1400px wrapper
          // $('body').find('> header, > footer, > .page-content').wrap('<section/>');
        }
      }
    },
    helper: function () {
      if (RT.vars.params.uihelper) {
        $('body').addClass('uiHelper');
      }
    },
		setFocusableSection: function (parentSelector, activeSelectors, TF) {
			// this allows us to turn off tabindex for elements behind a modal or nav menu that is open
			var allSelectors = 0, parentChildSelectors = -1; // default is a reset
			if (!activeSelectors) { activeSelectors = 'a, input, textarea, select, button' }
			if (TF == true) {
				allSelectors = -1, parentChildSelectors = 1;
			}

      $(activeSelectors).attr('tabindex', allSelectors);
			if (!parentSelector) {
				parentSelector = '.modal'
			}
			$(parentSelector).find(activeSelectors).attr('tabindex', parentChildSelectors); // see '.modalFeedback'
			// TODO: set category navigation tabindex based on visibility
    },
    isScrolledIntoView: function (elem, overlap) {
      if (RT.vars.params.noImages) {return false} //RT.vars && RT.vars.params && 
      if (elem.length === 0) {return false}
      if (!overlap) { overlap = 0 };
      // TOP/BOTTOM scroll
      var docViewTop = $(window).scrollTop(),
        docViewBottom = docViewTop + $(window).height(),  
        elemTop = $(elem).offset().top + overlap,
        elemBottom = elemTop + ($(elem).height() / 2) - overlap;

      // LEFT/RIGHT scroll / slick

      // RT.log(elemBottom + " <= " + docViewBottom + " && " + elemTop  + " >= " + docViewTop, this);
      return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));  
    },
    boldKeyword: function (text, keyword) {
      return text.replace(new RegExp('(^|)(' + keyword + ')(|$)','ig'), '$1<strong>$2</strong>$3');
    },
    setStaticSpinnerURL: function () {
      RT.ui.spinner = '<img class="spinner-gif" alt="loading..." src="' + RT.vars.staticDomain + '/images/spinner_120px.gif">'
    },
    spinner: '<img class="spinner-gif" alt="loading..." src="/images/spinner_120px.gif">'
	},
	form: {

	}
});
// end extend RT namespace
READY.supportedCountries = {
  init: function () {
    JEGS.supportedCountries.ready();
  }
};
/*
check if cookie exists
  - YES: get localStorage data, then set to an object, then insert as array of objects and assign to window.supportedCountries
  - NO: get window.SUPPORTED variable, update to STRING, save as 'supportedCountries' localStorage, set 'supportedCountries' cookie for 'x' days
*/
JEGS.supportedCountries = {
  logger: 0,  
  ready: function () {
    var cookieSC = RT.fxns.cookie.get('supportedcountries');
    var supportedObj = window.SUPPORTED;
    var localStorageVersion = window.localStorageVersion;    
    RT.log('cookie -> supportedcountries: ' + cookieSC, this);
    RT.log('supportedCountries: ', this);
    RT.log(supportedObj, this);
    if ((!cookieSC && RT.vars.hasStorage && typeof supportedObj == 'object') || (typeof localStorageVersion != 'undefined' && localStorageVersion != cookieSC) ) {
      // written to localStorage & cookie on 1st visit    	
      JEGS.supportedCountries.setVal(supportedObj)
    } else if (RT.vars.hasStorage) {
      // retrived from localStorage and set to the window
      JEGS.supportedCountries.getAndSetObj();
    }
  },
  getAndSetObj: function () {
    var SUPPORTEDstr = localStorage.getItem('supportedCountries');
    if (SUPPORTEDstr) {
      var obj = $.parseJSON(SUPPORTEDstr);
      var SUPPORTED = RT.data.retJSONasArrayOfObjects(obj);
      window.supportedCountries = SUPPORTED;
      window.SUPPORTED = null;
    }
  },
  setVal: function (data) {
    var SUPPORTEDstr = RT.data.str(data);
    localStorage.setItem('supportedCountries', SUPPORTEDstr);
    RT.fxns.cookie.set('supportedcountries', localStorageVersion, 180); // days
  }
};
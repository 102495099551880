READY.header = {
  init: function () {
    JEGS.header.ready();
  }
};
LOAD.header = {
  init: function () {
    JEGS.header.load();
  }
};
// AFTERLOAD - see afterLoad.js for 3rd party script loading
SCROLL.header = {
  init: function () {
    JEGS.header.scroll();
  }
};
RESIZE.header = {
  init: function () {
    JEGS.header.resize();
  }
};
AJAX.header = {
  init: function () {
    JEGS.header.ajax();
  }
};

JEGS.header = {
  logger: 1, // uses custom console.log(), set as 1 or 0
  newscroll : null,
  mypos : null,
  headerWrapperHeight : null,
  rsCookie : null,
  accountMenuTimeOut : null,
  accountMenuMouseOver : false,
  ready: function () {
	JEGS.header.initVars();
	JEGS.header.setUserType();
    JEGS.header.setYMMHeader();
    JEGS.header.loadAccountMenuEvents();
    JEGS.header.registerEvents();
    // Based On Conversion Science Vendor Suggestion. This feature is commented.
    // JEGS.header.loadSignInModal();
  },
  loadSignInModal: function(){
	  $('#header-wrap #signin-dropdown').fadeIn(2000);
		 setTimeout(function(){		
					$('#header-wrap #signin-dropdown').fadeOut();				 
				}, 8000);
  },
  registerEvents: function(){
	  $('body').on('click', '#magnifySearch,#close-search,#main-search-overlay', JEGS.header.toggleSearchMagnifier);
	  $('body').on('click', '#shopByVehicle', JEGS.header.showVehicleSearch);
	  $('body').on('click', '#home-vehicle-search-overlay,#close-ymm,#vehicle-search-overlay', JEGS.header.hideVehicleSearch);
	  $('body').on('click', '#main-menu-overlay,#close-tab', JEGS.header.hideMobileNav);
	  $('body').on('click', '.promoExcLink', JEGS.header.popPromoExclusions.init);
	   
	  $('body').on('keydown', function(event) {
	       if (event.key === "Escape") {
	           //Overlay Hide
	   		$("#vehicle-search-overlay").removeClass("visible");
	   		$("#home-vehicle-search-overlay").removeClass("visible");
	   		
	   		//Vehicle Search Hide
	   		$("#vehicle-search").removeClass("visible");

	   		//Menu Hide
	   		$("#shop-by-category").removeClass("visible");

	   		//Changes Arrow from Open to Close
	   		$("#menu-arrow").removeClass("fa-caret-down");
	   		$("#menu-arrow").addClass("fa-caret-right");
	   		$("body").removeClass("scrollLock");
			$("#main-menu-overlay").removeClass("visible");

	       }
	   });  
	  	  
	 /* if($(window).width() >= RT.vars.bp.lg){
		  $('#search-bar #keyword-search').click( function () {
				$("#mid-header").show(); 
				$("#mid-header #search-wrapper").addClass("lockSearch");
				$("#mid-header #search-wrapper #close-search").show();
				$("#search-bar #keyword-search").focus();
				$("#main-search-overlay").addClass("visible");
		  });
	  }
	  if($(window).width() < 1024){
		  $('#keyword-search').attr("placeholder", "Search for parts");
	  }
	  */ 
  },
  initVars: function(){
	  JEGS.header.mypos = $(window).scrollTop();
	  JEGS.header.headerWrapperHeight = $(".page-content").offset().top;
	  JEGS.header.rsCookie = "jeg_RS";
  },
  load: function () {
    // typically used for repaint events / image loading
  },
  scroll: function () {
	  JEGS.header.newscroll = $(window).scrollTop();
	  var newscroll = JEGS.header.newscroll;
	  var headerWrapperHeight = JEGS.header.headerWrapperHeight;
	  if(RT.vars.page == "/") 
	   var homePageOffset = $(".page-content.home .main-content").offset().top;
	  if(newscroll < JEGS.header.mypos &&  newscroll > headerWrapperHeight && 
					($(window).width() <= RT.vars.bp.lg || (homePageOffset != undefined  && newscroll > homePageOffset) || RT.vars.page != "/")){	
		  JEGS.header.changeHeaderLocking(true);
		}
		else{
		  JEGS.header.changeHeaderLocking(false);
		}
		
		JEGS.header.mypos = newscroll;
  },
  resize: function () { 

  },
  ajax: function () {
    // typically use for AJAX'd form response
  },
  setYMMHeader: function(){

		//console.log( "ready!" );
		var tempCookieArray = JEGS.header.printCookies();
		if(tempCookieArray == null || tempCookieArray == ''){  
			//$('#N').val('0');
			JEGS.header.cleanHiddenYMMVars();
			$('#shop-by-vehicle').css('display','block');
	  	} else{
	  		//Change Request: Avoid persistence of YMM after first page
	  		if(!(RT.vars.params.ymm != null || RT.vars.params.year != null)){
	  			JEGS.header.cleanHiddenYMMVars();
	  			$('#shop-by-vehicle').css('display','block');
	  			 RT.fxns.cookie.del(JEGS.header.rsCookie);
	  			return;
	  		}  		
	  		var lowArray2 = new Array();
	  		var refinementsLabel = $("#refinementsLabel").val(); 
	  		var refinementsVehicleLabel = $("#refinementsVehicleLabel").val(); 
	  		for (i=0; i < tempCookieArray.length ; i++){
	    		lowArray2[i] =  tempCookieArray[i].split(",");
			    var year = lowArray2[i][1];		 
			 	var make = lowArray2[i][2];
				var model = lowArray2[i][3];
				//console.log(year+make+model);
				if($.trim(refinementsVehicleLabel).length > 0) {
					$('#lastYMMSearch').html(refinementsVehicleLabel);				
					var selectedVehicle = "Search within " +refinementsVehicleLabel;
				} else if($.trim(refinementsLabel).length > 0) {
					$('#lastYMMSearch').html(refinementsLabel);				
					var selectedVehicle = "Search within " +refinementsLabel;				
				} else {
					$('#lastYMMSearch').html(year+' '+make+ ' '+model);
					var selectedVehicle = "Search within " +year+' '+make+ ' '+model;				
				}
				$('#lastYMMSearchDiv').css('display','block');
				if (selectedVehicle.length > 48){
					selectedVehicle = selectedVehicle.substring(0, 48) + '...';
				}
				$('#keyword-search').attr("placeholder", selectedVehicle);
				var NVal = lowArray2[i][4].toString();
				//console.log('nval is ' + NVal);
				var ymmArray = NVal.split('~');
				if(ymmArray[0] && ymmArray[0] != 'null') {
					$('#keyword-search-form #year').val(ymmArray[0]);
				}
				if(ymmArray[1] && ymmArray[1] != 'null') {
					$('#keyword-search-form #make').val(ymmArray[1]);
				}
				if(ymmArray[2] && ymmArray[2] != 'null') {
					$('#keyword-search-form #model').val(ymmArray[2]);
				}
				if(ymmArray[3] && ymmArray[3] != 'null') {
					$('#keyword-search-form #submodel').val(ymmArray[3]);
				}
				if(ymmArray[4] && ymmArray[4] != 'null') {
					$('#keyword-search-form #engine').val(ymmArray[4]);
				}			
				//$('#N').val(NVal.substring(1));//remove ? from N value in hidden var
				return;
			}
	  		$('#shop-by-vehicle').css('display','block'); //if it comes here, for loop is not executed.
	  	}

  },
  cleanHiddenYMMVars: function(){
		$('#keyword-search-form #year').val('');
		$('#keyword-search-form #make').val('');
		$('#keyword-search-form #model').val('');
		$('#keyword-search-form #submodel').val('');
		$('#keyword-search-form #engine').val('');
	},
  loadAccountMenuEvents: function(){
	 
	  $('#floating-top-nav #accountHeader').mouseover( function () {
		     JEGS.header.accountMenuMouseOver = true;
			 $('#header-wrap #account-dropdown.sticky').show();
			 clearTimeout(JEGS.header.accountMenuTimeOut);
		 });
		 $('#header-wrap #account-dropdown.sticky').mouseover( function () {
			 JEGS.header.accountMenuMouseOver = true;
			 $('#header-wrap #account-dropdown.sticky').show();
			 clearTimeout(JEGS.header.accountMenuTimeOut);
		 });
		 	 
		 $('#floating-top-nav #accountHeader').mouseleave(  function() {
			 JEGS.header.accountMenuMouseOver=false;
			 JEGS.header.hideAccountDropDownMenu();
		 });
			
		 $('#header-wrap #account-dropdown.sticky').mouseleave(  function() {
			 JEGS.header.accountMenuMouseOver=false;
			 JEGS.header.hideAccountDropDownMenu();
		 });
		 
		 $('#myAccountDropdown').mouseover( function () {
			 JEGS.header.accountMenuMouseOver = true;
			 $('#account-dropdown.sticky').show();
			 clearTimeout(JEGS.header.accountMenuTimeOut);
		 });
		 	 
		 $('#myAccountDropdown').mouseleave(  function() {
			 JEGS.header.accountMenuMouseOver=false;
			 JEGS.header.hideAccountDropDownMenu();
		 });
  },
  
  changeHeaderLocking: function(lock){
	  var navSection = $('#shop-by-category');
	  if (lock && !$("#header-wrap").hasClass("lock-header")) {
			$("#header-wrap").addClass("lock-header");
			$(".page-content").addClass("floatDeskHeader");
			
			// lock the scroll when nav is open and scrolling the page
			if (RT.vars.page != "/" && navSection.hasClass('visible')) {
				$("body").addClass("scrollLock");
			}

		} else if (!lock && $("#header-wrap").hasClass("lock-header")) {
			$("#header-wrap").removeClass("lock-header");
			$(".page-content").removeClass("floatDeskHeader");
			$('#right-cell #search-icon').css("visibility","visible");
			$('#mid-header').removeClass("desk-fixed");
			$('#shop-by-category').removeAttr('style');
	 } 
  },
  printCookies: function(){
	  var tempCookieArray = new Array();
	    	if (document.cookie.length>0){
	  			c_start=document.cookie.indexOf(JEGS.header.rsCookie + "=");
		  		if (c_start!=-1)
		    	{
		    		c_start=c_start + JEGS.header.rsCookie.length+1;
		    		c_end=document.cookie.indexOf(";",c_start);
		    		if (c_end==-1) c_end=document.cookie.length;
		    		strCookie =  unescape(document.cookie.substring(c_start,c_end));
		    		tempCookieArray = strCookie.split("|");
		    	}	
		  	}	
	 return tempCookieArray;
  },
  toggleSearchMagnifier : function(e){
	  if($(window).width() > RT.vars.bp.lg){
		  if ($("#mid-header #search-wrapper").hasClass("lockSearch")){
			  $("#mid-header #search-wrapper").removeClass("lockSearch");
			  $("#mid-header #search-wrapper #close-search").hide();
			  $("#main-search-overlay").removeClass("visible");
		  }	
		  else{	
			$("#mid-header").show(); 
			$("#mid-header #search-wrapper").addClass("lockSearch");
			$("#mid-header #search-wrapper #close-search").show();
			$("#search-bar #keyword-search").focus();
			$("#main-search-overlay").addClass("visible");
		  }
	  }
	  else{
		  $('#mid-header').addClass("desk-fixed");
		  $('#right-cell #search-icon').css("visibility","hidden");
		  $('#keyword-search').focus(); 
	  }
	},
   showVehicleSearch : function(e){
		//Menu Hide
		$("#shop-by-category").removeClass("visible");

		//Changes Arrow from Open to Close
		$("#menu-arrow").removeClass("fa-caret-down");
		$("#menu-arrow").addClass("fa-caret-right");
	    //$("#vehicle-search #YEAR").html(JEGS.nav.getVehicleYears.yearsData);
		//Overlay Show
		if ($("#shop-by-category").hasClass("showLeftNav") && $(window).width() > 1024) {
			$("#home-vehicle-search-overlay").addClass("visible");
		}
		else {
				$("#vehicle-search-overlay").addClass("visible");
				$("#main-menu-overlay").removeClass("visible");	
		}
		JEGS.header.AjaxShopByVehicleDisplayContent();
	},
	hideVehicleSearch : function(e){
		//document.getElementById("vehicle-search").style.display="none";

		//Overlay Hide
		$("#vehicle-search-overlay").removeClass("visible");
		$("#home-vehicle-search-overlay").removeClass("visible");
		
		//Vehicle Search Hide
		$("#vehicle-search").removeClass("visible");

		//Menu Hide
		$("#shop-by-category").removeClass("visible");

		//Changes Arrow from Open to Close
		$("#menu-arrow").removeClass("fa-caret-down");
		$("#menu-arrow").addClass("fa-caret-right");
		$("body").removeClass("scrollLock");

  },
  hideMobileNav : function (e){
		//$("#store-categories.desktop").addClass("shipNoteNone");

		//Menu Hide
		$("#shop-by-category").removeClass("visible");

		//Changes Arrow from Open to Close
		$("#menu-arrow").removeClass("fa-caret-down");
		$("#menu-arrow").addClass("fa-caret-right");

		//Overlay Hide
		$("#main-menu-overlay").removeClass("visible");
		$("body").removeClass("scrollLock");

  },
  addRSCookie: function(value) {
	   var cookieData = RT.fxns.cookie.get(JEGS.header.rsCookie);
	   if(cookieData == null || cookieData == ''){     
	  		RT.fxns.cookie.set(JEGS.header.rsCookie,value,30); 
	   }
	   else{
	    var valueInArr =cookieData.split("|");   
     	if(valueInArr.length <= 5){ 
       		var tempCookieData="";
			for (i=0; i < valueInArr.length && i < 5 -1; i++){
				if(value != valueInArr[i] && valueInArr[i] != ''){
     				if(i>0){  
     					if(valueInArr[i]==valueInArr[i-1])
     			 		break;
     					tempCookieData = tempCookieData+"|"+valueInArr[i]; 
     				}
     				else{ 
						tempCookieData = valueInArr[i];
					} 
				} 
	      	 }
    	  	cookieData=tempCookieData;	 
     	}
 		cookieData = value+"|"+cookieData;
 		RT.fxns.cookie.set(JEGS.header.rsCookie,value,30);
	  }  
 },
 hideAccountDropDownMenu : function(){
	 JEGS.header.accountMenuTimeOut = setTimeout(function(){		
		if(!JEGS.header.accountMenuMouseOver) {
			$('#header-wrap #account-dropdown.sticky').hide();
		}
		 
		}, 100);
 },
 setUserType: function() {
		var cookieValue = "";
		var userTypes = $("#userType").val();
		if(userTypes == null) 
			return
		var userType  = userTypes.split("_");
		
		if(userType.length != 0) {
			if(userType[0] == "-1002" || (userType[1] == 0 && userType[2] == 'G') ) {
				cookieValue = "Generic";
			} else if (userType[2] == "G") {
				cookieValue = "Guest";
			} else if (userType[2] == "E") {
				cookieValue = "Employee";
				JEGS.header.setUserNameHeader();
			} else if (userType[2] == "RA") {
				cookieValue = "Racer";
				JEGS.header.setUserNameHeader();
			} else {
				cookieValue = "Registered";
				JEGS.header.setUserNameHeader();
			}
			RT.fxns.cookie.set('USER_TYPE', cookieValue, 5);
		}
	},
	setUserNameHeader: function(){
		if($("#userProf").val() != null && $("#userProf").val() != ''){
			var name = $("#userProf").val();
			$('.userNameHeader').text(name);
			if(name!='')
				$('#welcomeTextHeader').removeClass("none");
			    $('#welcomeTextHeadermobile').removeClass("none");
		}
	},
	checkSearchLength: function() {
		var keyword = document.forms["keyword-search-form"]["keyword-search"].value;
		if (keyword.length < 2 || keyword.value == "") {
			alert("Search term too short. Please enter a longer term to use as your search.");
			return false;
		} else {
			return true;
		}
	},
	checkBrandSearchLength: function() {
		var keyword = document.forms["brand-keyword-search-form"]["brand-keyword-search"].value;
		if (keyword.length < 2 || keyword.value == "") {
			alert("Search term too short. Please enter a longer term to use as your search.");
			return false;
		} else {
			return true;
		}
	},
	onClickDispatcher: function (params) {
		var cartURL="/webapp/wcs/stores/servlet/OrderItemDisplay?updatePrices=1&orderId=.&calculationUsageId=-1&catalogId=10002&langId=-1&storeId=10001&ddkey=http:OrderCalculate";
		
		if(($(window).width() < RT.vars.bp.sm)) {//If Mobile 	
		   location.href=cartURL;
		
		}else if (($(window).width() <= RT.vars.bp.lg && $(window).width() > RT.vars.bp.sm && $( ".touch-enabled" ).length)) {//If tablet
			JEGS.minicart.minicart();
			$('#mini-cart-overlay').addClass('visible');
			
		} else {
			location.href=cartURL;
		}

	},
	
	popPromoExclusions: {
	  
	 init: function(){
		   var url = "/promotion/promotion.html";
		   var modal = $('.promoExcModal');
		   if (modal.length == 0) {
				var modalContainer = JEGS.modal.retContainer();
				$(modalContainer).addClass('promoExcModal noHeaderFooter').appendTo($('body'));
				RT.ajax.send(url,JEGS.header.showLoaderSpinner,JEGS.header.popPromoExclusions.showData,JEGS.header.popPromoExclusions.showError);
		    }
		  RT.fxns.setLastFocus($(this));
		  JEGS.modal.open('.promoExcModal');
		  $('.promoExcModal .btnClose').focus();
	   },
	    showData : function(results){
	    	JEGS.header.removeLoaderSpinner();
	    	$('.promoExcModal .content').html(results);
	    	
	    },
	    showError : function(){
	    	JEGS.header.removeLoaderSpinner();
	    	$('.promoExcModal .content').html("Error occurred while retrieving the content. Please refresh the page and try again.");
	    }
	},
	
	showLoaderSpinner: function(){
	  $('body').addClass('disablePage');  
	  $('body').prepend('<div class="spinner"/>');
	  $('.disablePage .modalOverlay').css({'z-index':'130'});	
	},
	
	removeLoaderSpinner: function(){
	  $("body").find(".spinner").remove();
	  $('.disablePage .modalOverlay').css({'z-index':'70'});
	  $('body').removeClass('disablePage');
	},
	
	/*
	 * This script loads Year\Make\Model\Sub-Model in Vehicle Search Widget[Header]
	 */
	AjaxShopByVehicleDisplayContent : function(){
		var params = {};
		params["home"] = "true";
		$.ajax({
			method: "POST",
			data: params,
		    url: "/webapp/wcs/stores/servlet/JEGSAjaxShopByVehicleServiceView?storeId=10001&langId=-1&catalogId=10002"
		 })
		 .done(function(response, ioArgs) {
			$("#ShopByVehicleServiceData_Box").html(response);
			//Vehicle Search Show
			$("#vehicle-search").addClass("visible");
			//document.getElementById("vehicle-search").style.display="block";
			JEGSMkMdYearDriver.Controller.resetIfNeeded();
		})
		.fail(function( jqXHR, textStatus) {
		      console.error("HTTP status code: ", textStatus);
		 });
	},
	showBrRpQv: function(id){
	    if(id!=undefined){
	        $("#br-rp-widget").hide();
	        $('#'+id).removeClass('br-rp-qv-hide').addClass('br-rp-qv-show');
	    }
	},
	hideBrRpQv: function(id){
		if(id!=undefined){
	        $('#'+id).removeClass('br-rp-qv-show').addClass('br-rp-qv-hide');	
	        $("#br-rp-widget").show();
		}
	}
};

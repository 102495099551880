function getxmlDocAsString(xmlDoc){
	var xmlStr;
	if(window.ActiveXObject){
		xmlStr = xmlDoc.xml;
	} else if (document.implementation &&
			document.implementation.createDocument){
		var xmlSerializer = new XMLSerializer();
		xmlStr = xmlSerializer.serializeToString(xmlDoc);
	}
	return xmlStr;	
}

function createXMLDoc(){
	var xmlDoc;
	if (window.ActiveXObject)
	{
		xmlDoc = new ActiveXObject("Microsoft.XMLDOM");
	}
	// code for Mozilla, etc.
	else if (document.implementation &&
	document.implementation.createDocument){
		xmlDoc= document.implementation.createDocument("","",null);
	}
	else{
		alert('Your browser is not capable of creating XML');
	}	
	return xmlDoc;		
}

function setSearchAttribute(name, value){	
	var searchCriteria = getSearchCriteria();
	searchCriteria.setAttribute(name, value);	
}

function getSearchAttribute(name){
	var searchCriteria = getSearchCriteria();
	return searchCriteria.getAttribute(name);
}

function getSearchCriteria(){
	return xmlDoc.getElementsByTagName('SEARCH').item(0);
}

/**
 * Evaluate scripts in an HTML string. Will eval both <script src=""></script>
 * and <script>javascript here</scripts>.
 *
 * @param element a new HTML(text).getElement()
 */
function evalScripts(response) {
    //alert("evalScripts - step 1");
	var xmlDoc;
	if (window.ActiveXObject){ 
	var xmlDoc = createXMLDoc();          
	xmlDoc.async="false";          	 
	xmlDoc.loadXML(response);	      	  
	}  else if (document.implementation &&
		document.implementation.createDocument){
		parser = new DOMParser();
		xmlDoc = parser.parseFromString(response, "text/xml");
	} else{
		alert('Your browser is not capable of creating XML');
	}	
	var element = xmlDoc.documentElement;
	var scripts = element.getElementsByTagName("script");
    //alert("evalScripts - step 2["+scripts.length+"]");    
    for (i=0; i < scripts.length; i++) {
    	var javaScrStr = "";
    	 for (j=0; j < scripts[i].childNodes.length; j++){
     	 	javaScrStr += scripts[i].childNodes[j].nodeValue; 
    	 }
	     //alert("evalScripts - step 3["+javaScrStr+"]");
    	 eval(javaScrStr);        
    }
}

READY.slick = {
  init: function () {
    JEGS.slick.ready()
  }
};
/*
LOAD.slick = {
  init: function () {
    console.log('LOAD.slick init')
    JEGS.slick.load()
  }
};
*/
RESIZE.slick = {
  init: function () {
    JEGS.slick.resize()
  }
};
JEGS.slick = {
  logger: 1,
  ready: function () {
	JEGS.images.updateCarouselMedia('carousel-hero');//Updates carousel media based on the device width
	JEGS.slick.initSliders();
	JEGS.slick.initCarousels();
	JEGS.slick.registerEvents();
  },
  /*
  load: function () {
    var sliders = $('.slick-slider');
    sliders.each(function (idx, slider) {
      $(slider).on('afterChange', function (slick, currentSlide) { JEGS.slick.images(slick, currentSlide) });
    });
  },
  */
  registerEvents: function(){
	  $('body').on('click', '#see-more-brands', JEGS.slick.seeMoreBrands);
	  $('body').on('click', '#see-less-brands', JEGS.slick.seeLessBrands); 
  },
  
  seeMoreBrands: function(e){
	  $('#brand-filter').slick('unslick');
		$('#brand-filter').css('overflow-y', 'visible');
		$('#brand-filter').css('height', 'auto');
		$(e.currentTarget).hide();
		$('#see-less-brands').show();
  },
  
  seeLessBrands: function(e){
	    $(e.currentTarget).hide();
		$('#see-more-brands').show();
		$('#brand-filter').slick({
		slidesToShow : 6,
		slidesToScroll : 6,
		centerMode : false,
		infinite : true,
		centerPadding : '0px',
		prevArrow : '<div class=\"left-arrow\"><i class=\"fa fas fa-angle-left\"></i></div>',
		nextArrow : '<div class=\"right-arrow\"><i class=\"fa fas fa-angle-right\"></i></div>',
		responsive : JEGS.slick.settings.responsive.fiveColumn()
	});
  },
    
  initSliders: function () {
    var sliders = $('.carousel-hero');
   if (sliders.length > 0) {
      $('.carousel-hero').not('.slick-initialized').slick({
    	lazyLoad: 'ondemand',
    	slidesToShow : 1,
        slidesToScroll : 1,
        autoplay: true,
        autoplaySpeed: 7000,
        centerMode : false,
        infinite : true,
        centerPadding : '0px',
        prevArrow : '<div class=\"left-arrow\"><i class=\"fa fas fa-angle-left\"></i></div>',
        nextArrow : '<div class=\"right-arrow\"><i class=\"fa fas fa-angle-right\"></i></div>'
      });
    };
   
    var sliders_4col = $('.carousel-four-column');
    if (sliders_4col.length > 0) {
      sliders_4col.slick({
        slidesToShow : 4,
        slidesToScroll : 4,
        centerMode : false,
        infinite : false,
        centerPadding : '0px',
        prevArrow : '<div class=\"left-arrow\"><i class=\"fa fas fa-angle-left\"></i></div>',
        nextArrow : '<div class=\"right-arrow\"><i class=\"fa fas fa-angle-right\"></i></div>'
      });
    };

    var pricingSlider = $('.pricingSlider');
    if (pricingSlider.length > 0) {
      var slickSlider = pricingSlider.find('.flexSlider').not('.slick-initialized');
      if (slickSlider.length > 0) {
        slickSlider.slick(JEGS.slick.settings.pricingSlider);
      }
    };

    $('body').on('change', '.breakPointSelect', JEGS.slick.breakPointSelect)

    var tabs = $('.tab-label');
    if (tabs.length > 0) {
      tabs.on("click", JEGS.slick.reInitSliderInTab);
    }
  },
  breakPointSelect: function (e) {
    var bpClass = $(e.currentTarget).val();
    if (bpClass != '') {
      $('.flexSlider').prop('class','flexSlider ' + bpClass);
    }
  },
  resize: function () {
    RT.log("call slick resize fxn", this);
    //On window resize, need to destroy and reinit the carousel to change the number of items to scroll, the numbers to show, and whether the arrows should be displayed or not (scrollNum < scrollCount )
    JEGS.slick.initCarousels(); // TODO - pull code into this module
    // JEGS.slick.reInitSliders(); // currently only (HOME) pricing slider
  },
  reInitSliders: function () {
    $('.pricingSlider').each(function(){
      // if this is already initialized, destroy and rebuild else build
      var slickSlider = $(this);
      if (slickSlider.hasClass('slick-initialized')) {
        slickSlider.slick('unslick');
      };
      slickSlider.slick(JEGS.slick.settings.pricingSlider);
    });
  },
  reInitSliderInTab: function (e) {
    setTimeout(function () {
      var slick = $('.ui-tabs-panel').find('.carousel-four-column');
      slick.slick('unslick').slick({
        slidesToShow : 4,
        slidesToScroll : 4,
        centerMode : false,
        infinite : true,
        centerPadding : '0px',
        prevArrow : '<div class=\"left-arrow\"><i class=\"fa fas fa-angle-left\"></i></div>',
        nextArrow : '<div class=\"right-arrow\"><i class=\"fa fas fa-angle-right\"></i></div>',
        responsive : JEGS.slick.settings.responsive.fourColumn()
      });
    }, 100)
  },
  settings: {
    pricingSlider: {
      slidesToShow : 1,
      slidesToScroll : 1,
      autoplay: true,
      autoplaySpeed: 7000,
      centerMode : false,
      infinite : false,
      centerPadding : '0px',
      arrows: false,
      dots: true
   },
   swipe: function(width,count){
	   return {breakpoint: width, settings: {slidesToShow: count, slidesToScroll: count}};
   },
   responsive: {
	   fourColumn: function(){
	                 return [ 
	                 JEGS.slick.settings.swipe(RT.vars.bp.xl, 4),
                     JEGS.slick.settings.swipe(RT.vars.bp.lg, 3),
	                 JEGS.slick.settings.swipe(RT.vars.bp.md, 3),
	                 JEGS.slick.settings.swipe(RT.vars.bp.sm, 2),
	                 JEGS.slick.settings.swipe(RT.vars.bp.xs, 1)
	               ];},
       fiveColumn: function(){
					return [
					JEGS.slick.settings.swipe(RT.vars.bp.xl, 5),
					JEGS.slick.settings.swipe(RT.vars.bp.lg, 4),
					JEGS.slick.settings.swipe(RT.vars.bp.md, 3),
					JEGS.slick.settings.swipe(RT.vars.bp.sm, 2),
					JEGS.slick.settings.swipe(RT.vars.bp.xs, 2)
                   ];},               
	   sixColumn: function(){
			        return [
					JEGS.slick.settings.swipe(RT.vars.bp.xl, 5),
					JEGS.slick.settings.swipe(RT.vars.bp.lg, 4),
					JEGS.slick.settings.swipe(RT.vars.bp.md, 3),
					JEGS.slick.settings.swipe(RT.vars.bp.sm, 1)
				  ];},               
	  sevenColumn: function(){
	                return [
					JEGS.slick.settings.swipe(RT.vars.bp.xl, 7),
					JEGS.slick.settings.swipe(RT.vars.bp.lg, 5),
					JEGS.slick.settings.swipe(RT.vars.bp.md, 4),
					JEGS.slick.settings.swipe(RT.vars.bp.sm, 3),
					JEGS.slick.settings.swipe(RT.vars.bp.xs, 2)
	               ];} 
      }               
  	
 },
 
  initCarousels : function(){

	  $('.slick1.old, .slick2, .slick3, .carousel-six-column').each(function(){
	  	//if this is already initialized, destroy and rebuild else build
	  	if($(this).hasClass('slick-initialized')){
	  					//destroy
	  		$(this).slick('unslick');
	  	} 
	  	$(this).slick({
	  		slidesToShow : 6,
	  		slidesToScroll : 6,
	  		centerMode : false,
	  		infinite : true,
	  		centerPadding : '0px',
	  		prevArrow : '<div class=\"left-arrow\"><i class=\"fa fas fa-angle-left\"></i></div>',
	  		nextArrow : '<div class=\"right-arrow\"><i class=\"fa fas fa-angle-right\"></i></div>',
	  		responsive : JEGS.slick.settings.responsive.sixColumn()
	  	}); 
	  });

	  $('.carousel-five-column').each(function(){	
	  	//if this is already initialized, destroy and rebuild else build
	  	if($(this).hasClass('slick-initialized')){
	  					//destroy
	  		$(this).slick('unslick');
	  		 } 	
	  		$(this).slick({
	  		slidesToShow : 6,
	  		slidesToScroll : 6,
	  		centerMode : false,
	  		infinite : true,
	  		centerPadding : '0px',
	  		prevArrow : '<div class=\"left-arrow\"><i class=\"fa fas fa-angle-left\"></i></div>',
	  		nextArrow : '<div class=\"right-arrow\"><i class=\"fa fas fa-angle-right\"></i></div>',
	  		responsive : JEGS.slick.settings.responsive.fiveColumn()
	  	});
	  });



	  $('.carousel-four-column').each(function(){	
	  	//if this is already initialized, destroy and rebuild else build
	  	if($(this).hasClass('slick-initialized')){
	  					//destroy
	  		$(this).slick('unslick');
	  		 } 
	  		$(this).slick({
	  		slidesToShow : 4,
	  		slidesToScroll : 4,
	  		centerMode : false,
	  		infinite : true,
	  		centerPadding : '0px',
	  		prevArrow : '<div class=\"left-arrow\"><i class=\"fa fas fa-angle-left\"></i></div>',
	  		nextArrow : '<div class=\"right-arrow\"><i class=\"fa fas fa-angle-right\"></i></div>',
	  		responsive : JEGS.slick.settings.responsive.fourColumn()
	  	});
	  });

	  $('.carousel-logos').each(function(){	

	  		$(this).slick({
	  		slidesToShow : 8,
	  		slidesToScroll : 8,
	  		centerMode : false,
	  		infinite : true,
	  		centerPadding : '0px',
	  		prevArrow : '<div class=\"left-arrow\"><i class=\"fa fas fa-angle-left\"></i></div>',
	  		nextArrow : '<div class=\"right-arrow\"><i class=\"fa fas fa-angle-right\"></i></div>',
	  		responsive : JEGS.slick.settings.responsive.sevenColumn()
	  	});
	  });

	  $('.carousel-single').each(function(){	
	  	//if this is already initialized, destroy and rebuild else build
	  	if($(this).hasClass('slick-initialized')){
	  					//destroy
	  		$(this).slick('unslick');
	  		 } 	
	  	$(this).slick({
	  		slidesToShow : 1,
	  		slidesToScroll : 1,
	  		autoplay: true,
	  		arrows: false,
	  			autoplaySpeed: 5000,
	  		centerMode : true,
	  		infinite : true,
	  		centerPadding : '0px'
	  	});
	  });

  }
};


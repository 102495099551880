READY.smsSubmit = {
  init: function () {
    	JEGS.smsSubmit.ready();
  }
};

JEGS.smsSubmit = {
  ready: function () {
    	JEGS.smsSubmit.form();
  },
  form: function () {
		$("#jegsPhone #smsSubmit").click(JEGS.smsSubmit.submit);
  },
  submit: function (e) {
    e.preventDefault();
    var F = $('form#jegsPhone');
    var phoneObj = F.find("#phone");
    $('.alertMsg').hide().attr('aria-hidden', true);
    $("#phoneAlert").hide().attr('aria-hidden', true);
    $("#ThanksTextMSG").hide();
    phoneObj.removeClass('validate');
    var phone = $.trim(phoneObj.val());
    var isPhoneValid = RT.data.validate.phone(phone);
    if (!isPhoneValid) {
      $("#phoneMsg").show().attr('aria-hidden', false);
      phoneObj.addClass('validate');
    } else {
      $('#ThanksTextMSG').show();
    }
  }
};
// search / type ahead (container sizing) functionality
AJAX.SUCCESS.searchTypeAhead = {
	init: function (e, xhr, options) {
   		if (xhr.responseText != undefined && xhr.responseText.indexOf('typeAheadProducts') > -1) {
            console.log('searchTypeAhead response...')
			JEGS.searchTypeAhead.container();
			//JEGS.searchTypeAhead.addCloseBtn();
			JEGS.searchTypeAhead.clickEvents();
			JEGS.searchTypeAhead.toolTip();
			if($(window).width() > 1024){
				$('#clear-icon').remove();
			}
		}
	}
};
RESIZE.searchTypeAhead = {
	init: function () {
		var vis = $('#autocomplete:visible');
		if (vis.length !== 0) {
			vis.removeAttr('height').removeAttr('style');
			setTimeout(function () {
				JEGS.searchTypeAhead.container();
			}, 100)
		}
	}
};

READY.searchTypeAhead = {
	init: function () {
		JEGS.searchTypeAhead.ready();
		JEGS.searchTypeAhead.toolTip();
	}
};

JEGS.searchTypeAhead = {
	logger: 1,
	vars: {
		AUTOCOMPLETE_DIV: "#autocomplete",	// identifier of autocomplete div
		SEARCH_BOX: "#keyword-search",		// identifier of search box to trigger autocomplete
		KEYWORD_TRIGGER_LENGTH: 3,         // length of search term required to start autocompleting
		keywordQuery: null                   //Controls auto suggest to fire only when characters are keyed in from the keyboard.
	},
	ready: function () {
		JEGS.searchTypeAhead.events();
		JEGS.searchTypeAhead.setup();
	},
	toolTip: function () {
	    
	    var closeToolTip = function () {
				$('.ui-tooltip-content').parent().bind( "click", function() {
					$('.seeInCartToolTip').removeClass("fa-times-circle").addClass("fa-info-circle").tooltip( "close" );
				});
	    };
	    
	    if ($(window).width() <= RT.vars.bp.sm) {
				// hiding the shop by vehicle section in cart page for mobile.
				$(".page-content.cart #shop-by-vehicle").css('display','none');			
			} else if ($(window).width() >= RT.vars.bp.lg) {
	      $(".seeInCartToolTip").tooltip({
	        items:"i",
	        content: "JEGS is committed to offering the lowest prices possible. Since we've priced this item lower than the manufacturer suggests, the price can't be displayed until the item is in your shopping cart. Don't worry, you can easily remove it from your cart if you decide not to buy it."
	      });
			} else {							
				$('.seeInCartToolTip').click( function() {
					if ($('.ui-tooltip-content').length == 0) {
						$(this).removeClass("fa-info-circle").addClass("fa-times-circle");			
						$('.seeInCartToolTip').tooltip({
			    			content: "JEGS is committed to offering the lowest prices possible. Since we've priced this item lower than the manufacturer suggests, the price can't be displayed until the item is in your shopping cart. Don't worry, you can easily remove it from your cart if you decide not to buy it.",
						    items: 'i'
						  }).tooltip( "open" );
						setTimeout(closeToolTip, 1000);				   				
					} else {
						$('.seeInCartToolTip').tooltip( "close" );
						$(this).removeClass("fa-times-circle").addClass("fa-info-circle");
					}						   
				});
			}
	  },
	events: function () {
		$(document).keyup(JEGS.searchTypeAhead.showOrHide);
		$(document).mouseup(JEGS.searchTypeAhead.hide);
		$('body').on('mouseenter', '.typeAheadGroupItem', JEGS.searchTypeAhead.setActive);
		$('body').on('mouseleave', '.typeAheadGroupItem', JEGS.searchTypeAhead.resetActiveInitState);
		$('body').on('blur', JEGS.searchTypeAhead.vars.SEARCH_BOX, JEGS.searchTypeAhead.resetActiveInitState);
		$(JEGS.searchTypeAhead.vars.SEARCH_BOX).keydown(JEGS.searchTypeAhead.moveThroughSuggestions);
	},
	showOrHide: function (e) {
		var v = JEGS.searchTypeAhead.vars;
		if($(v.SEARCH_BOX).val() !=undefined){
			if ($(v.SEARCH_BOX).val().length >= v.KEYWORD_TRIGGER_LENGTH) return;
			else{
				$("#clear-icon").hide();
			}
		}	
		$(v.AUTOCOMPLETE_DIV).html("").hide();
		JEGS.searchTypeAhead.resetActiveInitState();

	},
	hide: function (e) {
		var v = JEGS.searchTypeAhead.vars;
		var container = $(v.AUTOCOMPLETE_DIV);
		if (!container.is(e.target) // if the target of the click isn't the container...
				&& container.has(e.target).length === 0) // ... nor a descendant of the container
		{
			container.hide();
		}
	},
	setActive: function(e){
		var target = $(e.currentTarget);
		var prevActiveTerm = target.parent().find('.active');
		if(prevActiveTerm)
			prevActiveTerm.removeClass('active');
		target.addClass('active');
	},
	resetActiveInitState: function(e){
		 JEGS.searchTypeAhead.vars.keywordQuery = null;
		 var termsListParent = $('.typeAheadGroupList');
         var activeTerm = termsListParent.find('.typeAheadGroupItem.active');
         if(activeTerm)
        	 activeTerm.removeClass('active');
	},
	
	moveThroughSuggestions: function(e){//keyDown Event 
		var keyCodes = RT.vars.keyCodes;
		
		switch (e.which) {

	        case keyCodes.UP:
	          RT.log("UP", this);
	          e.preventDefault(); // prevent moving the cursor
	          var termsListParent = $('.typeAheadGroupList');
	          var termsList = termsListParent.find('.typeAheadGroupItem');
	          var activeTerm = termsListParent.find('.typeAheadGroupItem.active');
	          var previousTerm = activeTerm.prev('.typeAheadGroupItem');
	          if(activeTerm.length > 0 && previousTerm.length > 0){
	        	  var prevTermText = previousTerm.text().trim();
	        	  JEGS.searchTypeAhead.vars.keywordQuery = prevTermText;
	        	  $(JEGS.searchTypeAhead.vars.SEARCH_BOX).val(prevTermText);
	        	  activeTerm.removeClass('active');
	        	  previousTerm.addClass('active');
	        	  
	          }	  
	          break;
	  
	        case keyCodes.DOWN:
	          RT.log("DN", this);
	          e.preventDefault(); // prevent moving the cursor
	          var termsListParent = $('.typeAheadGroupList');
	          var termsList = termsListParent.find('.typeAheadGroupItem');
	          var activeTerm = termsListParent.find('.typeAheadGroupItem.active');
	          var nextTerm = activeTerm.next('.typeAheadGroupItem');
	          if(activeTerm.length == 0){
	        	  var firstTerm = termsList.first().text().trim();
	        	  JEGS.searchTypeAhead.vars.keywordQuery = firstTerm;
	        	  $(JEGS.searchTypeAhead.vars.SEARCH_BOX).val(firstTerm);
	        	  termsList.first().addClass('active');
	        	  
	          }else if(activeTerm.length > 0 && nextTerm.length > 0){
	        	  var nextTermText = nextTerm.text().trim();
	        	  JEGS.searchTypeAhead.vars.keywordQuery = nextTermText;
	        	  $(JEGS.searchTypeAhead.vars.SEARCH_BOX).val(nextTermText);
	        	  activeTerm.removeClass('active');
	        	  activeTerm.next().addClass('active');
	        	 
	          }
	          break;
	          
	        default:
	            RT.log("DEF", this);
	            break;   
	  }    

	},
	
	setActiveTerm: function(e){
		var target = $(e.currentTarget);
		target.css('background-color','#fff200');
	},
	
	setup: function () {
		var v = JEGS.searchTypeAhead.vars;
		$(v.SEARCH_BOX).autocomplete({
			source: function(request, response) {
				var autoReg = /^[A-Za-z0-9][A-Za-z0-9, &'"]+$/;
				var searchTerm = request.term;
				var year = $("#year").val();
				var make = $("#make").val();
				var model = $("#model").val();
				var submodel = $("#submodel").val();
				var enginetype = $("#enginetype").val();
				
				if (searchTerm.length < v.KEYWORD_TRIGGER_LENGTH){
					$(v.AUTOCOMPLETE_DIV).html("").hide();
					$("#clear-icon").hide();
					//$("#clear-icon").attr("style","display:none");
				}else{
					$("#clear-icon").removeAttr("style");
					$("#clear-icon").show();
				}
				
				if (autoReg.test(searchTerm) && searchTerm != JEGS.searchTypeAhead.vars.keywordQuery) {// Fire only when current search string changes from the previously entered string
					JEGS.searchTypeAhead.vars.keywordQuery = searchTerm;
					//to implement type ahead caching
					//if ( term in cache ) {
						//response( cache[ term ] );
						//return;
					//}
					$.ajax({
						url: "/webapp/wcs/stores/servlet/BRTypeAheadView",
						data: {
							"storeId" : "10001",
							"langId" : "-1",
							"Ntt" : searchTerm,
							"year" : year,
							"make" : make,
							"model" : model,
							"submodel" : submodel,
							"enginetype" : enginetype
						},
						success:function(htmlResponse,status,xhr) {
							//cache[ term ] = htmlResponse;
							if (htmlResponse.trim().length > 0){
								var h = $(htmlResponse);
								
								// Add bold to text matching the keyword in replaceBold spans
								// Wrap any content that should be bold with <span class="replaceBold"></span>
								// The span will be removed and content with added bold will take its place
								h.find("span.replaceBold").each(function(){
									var spanContent = $(this).html();
									var boldContent = RT.ui.boldKeyword(spanContent, searchTerm.trim());
									$(this).replaceWith(boldContent);
								});
								
								$(v.AUTOCOMPLETE_DIV).html(h).show();
							}
							else {
								// If service returns no results, clear and hide autocomplete div
								$(v.AUTOCOMPLETE_DIV).html("").hide();
							}
						} 
					});
				} else {}
			},
			minLength: v.KEYWORD_TRIGGER_LENGTH,
			delay: 200,
			select: function (event, ui) {
				//assign value back to the form element
				if (ui.item){
					$(event.target).val(ui.item.value);
				}
				//submit the form
				if (ui.item.urlval=='redirect') {
					$(event.target.form).submit();
				} else {
					window.location.href = ui.item.urlval;
				}
			}
		});
	},
	container: function () {
		var container = $('#autocomplete');
		var winH = $(window).height();
		var H = container.innerHeight();
		var kwSearch = $('#keyword-search');
		var top = kwSearch.offset().top;
		var scrollTop = $(window).scrollTop();
		RT.log("top: " + top + "  H: " + H, this);
		if (H > winH - (top + 20)) {
			var newH = winH - (top + 120) + scrollTop;  // + 100
			container.height(newH).css('overflow-y','auto')
		}
		if (RT.vars.touch) {
			$('body').addClass('scrollLock');
		}
	},
//	addCloseBtn: function () {
//		var closeBtn = '<div class="relative"><button class="btn btnClose desk-show"><span class="text screenReader">Close</span>' +
//			'<span class="iconContainer"><i class="fa fa-times" aria-hidden="true"></i></span></button></div>';
//		$('#autocomplete').prepend(closeBtn);
//	},
	close: function (e) {
		$('body').removeClass('scrollLock');
		$('#autocomplete').hide();
//		$('#keyword-search').val('');
		$('#keyword-search').focus();
		$("#clear-icon").hide();
		JEGS.searchTypeAhead.resetActiveInitState();
	},
	clearClose: function (e) {
		$('body').removeClass('scrollLock');
		$('#autocomplete').hide();
		$('#keyword-search').val('');
		$('#keyword-search').focus();
		$("#clear-icon").hide();
		$("#clear-icon").attr("style","display:none");
		JEGS.searchTypeAhead.resetActiveInitState();
	},
	clickEvents: function () {
//		var container = $('#autocomplete');
		var container = $('#clear-icon');
		var kwSearch = $('#keyword-search');
//		container.on('click', '.btnClose', JEGS.searchTypeAhead.close);
		container.on('click', JEGS.searchTypeAhead.clearClose);
		kwSearch.on('click', JEGS.searchTypeAhead.close);
		$('.scrollLock').on('click', JEGS.searchTypeAhead.close);		
	}
};
/*
rename "footer" for your own specific name and delete "event" namespaces which are not needed
technically only function calls from top level event functions should be used
The below (non-JEGS) modules should only have 1 fxn call which is a reference to the main footer function
*/
READY.footer = {
  init: function () {
    JEGS.footer.ready();
  }
};
LOAD.footer = {
  init: function () {
    JEGS.footer.load();
  }
};
// AFTERLOAD - see afterLoad.js for 3rd party script loading
SCROLL.footer = {
  init: function () {
    JEGS.footer.scroll();
  }
};

RESIZE.footer = {
  init: function () {
    JEGS.footer.resize();
  }
};
AJAX.footer = {
  init: function () {
    JEGS.footer.ajax();
  }
};
// JEGS.footer contains all functions, variables, etc. used specifically by the footer
// RT.vars, RT.url, RT.data namespaces should be used for global "runtime" access
JEGS.footer = {
  logger: 1, // uses custom console.log(), set as 1 or 0
  mypos : null,
  topOffset : null,
  newscroll : null,
  ready: function () {
	JEGS.footer.initVars();
	JEGS.footer.registerEvents();
	JEGS.footer.footerAccordionsInit();
  },
  load: function () {
    // typically used for repaint events / image loading
  },
  initVars: function(){
	  JEGS.footer.mypos = $(window).scrollTop();
	  if($('.jegs-footer').length > 0) 
	   JEGS.footer.topOffset = $('.jegs-footer').offset().top;
  },
  
  registerEvents: function(){
	  $('body').on('click', '.jegs-footer .headerBtn', JEGS.footer.footerNavAccordion);
	  $('body').on('click', '.scrollToTop', JEGS.footer.scrollToTop);
  },
  
  scrollToTop: function(e){
	  $('html, body').animate({scrollTop : 0},800);
  },
  
  scroll: function () {
	JEGS.footer.newscroll = $(window).scrollTop();
	var newscroll = JEGS.footer.newscroll;
	if(newscroll > JEGS.footer.mypos ) {			
		if( newscroll >= (JEGS.footer.topOffset - 700)) {
			$('.scrollToTop').show().stop();
		} else {
			$('.scrollToTop').hide().stop();
		}
	}else if(newscroll < JEGS.footer.mypos) {		
		if( newscroll >= 800) {
			$('.scrollToTop').show().stop();
		} else {
			$('.scrollToTop').hide().stop();
		}
		if($(window).width() < 767  && newscroll <= 2050 ){		
			$('.jegs-footer .headerBtn').addClass('accordion').attr({'aria-expanded':'false', 'tabindex':'0'}).removeClass('show-content');
	        $('.footer-nav').addClass('closed').attr({'aria-hidden': "true" });
			$('.jegs-footer .headerBtn').attr('class','headerBtn accordion');		
		}
	}
	JEGS.footer.mypos = $(window).scrollTop();
  },
  resize: function () {
	 JEGS.footer.footerAccordionsInit();
  },
  ajax: function () {
    // typically use for AJAX'd form response
  },
  footerNavAccordion: function(e){
    var contentId = $(e.currentTarget).attr('data-content');
	var content = $('#' + contentId);
	if($(e.currentTarget).hasClass('accordion')){
		$(e.currentTarget).toggleClass('show-content');
		($(e.currentTarget).hasClass('show-content')) ? $(e.currentTarget).attr({'aria-expanded': 'true'}) : $(e.currentTarget).attr({'aria-expanded': 'false'});

		$(content).toggleClass('closed');
		($(content).hasClass('closed')) ? $(content).attr({'aria-hidden': 'true'}) : $(content).attr({'aria-hidden': 'false'});
	}else{
		//do nothing
	} 
  },
  footerAccordionsInit: function () {
      if ($(window).width() <= RT.vars.bp.sm) {
        $('.jegs-footer .headerBtn').addClass('accordion').attr({'aria-expanded':'false', 'tabindex':'0'});
        $('.footer-nav').addClass('closed').attr({'aria-hidden': "true" });
      } else {
        $('.jegs-footer .headerBtn').removeClass('accordion').attr({'aria-expanded':'true', 'tabindex':'-1'});
        $('.footer-nav').removeClass('closed').attr({'aria-hidden': "false" });
      }
  }
};
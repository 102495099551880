var createItemImageDIV = function(id, catentryId,imgUrl,textValue) 	{
		return	$( "<div/>",{
							          "class" : "hp-image",
									  "id": id, 	
									  click: function() { } ,
									  "style":  "background-image:url("+imgUrl+"); opacity:1",	
									  "text":textValue,	  
					       });
		} // end of createItemImageDIV
        
var createPriceSpan = function () {
                        return      $( "<span/>",{
									  "class" : "x_cart_price",
									  "id": "priceContainer", 	
									  click: function() { } 									 	
									  });
                      }    
                      
var createSPAN = function (classname ,id,textValue) {
                        return      $( "<span/>",{
									  "class" : classname,
									  "id": id, 
									  html :textValue,									 	
									  });
                      }    
                      

var createDIV = function ( classname ,id, textValue,style ) {
			return $( "<div/>",{
						"class" : classname,
						"id": id, 
				        html : textValue,
				        "style" :style
				
			       }  );   
		               
				}	
	
var createBUTTON = function ( classname ,id, textValue ,functionName ) {

			return $( "<button/>",{
						"class" : classname,
						"id": id, 
				        text : textValue,
				        click: functionName 
				       
						} );   
			               
	}	
	
var createLI = function (classname,id,textValue,style){
	
		return $( "<li/>",{
						"class" : classname,
						"id": id, 
				        html : textValue,
				        "aria-hidden":"true",
				        "style":style
						} );   	
	       }
	       
var createUL = function (classname,id,textValue){
	
		return $( "<ul/>",{
						"class" : classname,
						"id": id, 
				        text : textValue
	       
						} );   	
	       }
	       	
var createFORM = function (formName,id,action,method){
	
		return $( "<form/>",{
						"action" : action,
						"name": formName, 
						"id":id,
						"method":method
			       
						} );   	
	       }
	
	
var createINPUT = function (classname,id,name,type ,placeholder ,maxlength ,value ,style ,pattern){	
		return $( "<input/>",{
		                "class" : classname,   
						"type" : type,
						"id": id, 
						"name":name,
						"maxlength":maxlength,
						"placeholder" : placeholder,
						"value" : value ,
						"style" : style,
						"pattern" : pattern
			       
						} );   	
	       }
	       
var createLABEL = function ( inputname ){
	return $( "<label/>",{
						"for" : inputname
						} );   	
    }
    
var createI = function ( classname,id,style ){
	return $( "<i/>",{
						"class" : classname,   
						"id": id,
						"style":style
						} );   	
    }
    

var createHREF = function ( link ,id){
	return $( "<a/>",{
					 "href" : link,
					 "id" :id
					} );   	
    }  

var createIframe = function(classname,id,src) {
	return $( "<iframe/>",{
		"class" : classname,   
		"id": id,
		"src":src,
		"frameborder" :"0"
	
		} );   	
}
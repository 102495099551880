READY.emailSpecials = {
  init: function () {
    JEGS.emailSpecials.ready()
  }
};

JEGS.emailSpecials = {
  ready: function () {
    JEGS.emailSpecials.form()
  },
  form: function () {
    $("#jegsEmail #formSubmit").click(JEGS.emailSpecials.submit);
  },
  submit: function (e) {
    e.preventDefault();
    var F = $('form#jegsEmail');
    var emailObj = F.find("#email");
    $('.alertMsg').hide().attr('aria-hidden', true);
    emailObj.removeClass('validate');
    var email = $.trim(emailObj.val());
    var isEmailValid = RT.data.validate.email(email);
    if (!isEmailValid) {
      $("#emailMsg").show().attr('aria-hidden', false);
      emailObj.addClass('validate');
    } else {
      $('#ThanksMSG').show();
    }
  }
};
/*
THIS IS A PAGE / ROUTE-BASED MODULE WHICH CAN CALL FXNS BASED ON REGEX OF THE PATHNAME
IF you have a component-specific set of fxns (like navigation), create a component module instead
component-based module-fxns may be referenced here if they do not have defined READY|LOAD|RESIZE|SCROLL events
This module may also be used to assign click events for google analytics
*/
READY.page = {
  init: function () {
    JEGS.page.ready();
  }
};
LOAD.page = {
  init: function () {
    JEGS.page.load();
  }
};
// AFTERLOAD - see afterLoad.js for 3rd party script loading
SCROLL.page = {
  init: function () {
    JEGS.page.scroll();
  }
};
RESIZE.page = {
  init: function () {
    JEGS.page.resize();
  }
};
AJAX.page = {
  init: function () {
    JEGS.page.ajax();
  }
};
// JEGS.page contains all functions, variables, etc. used specifically by the page
// RT.vars, RT.url, RT.data namespaces should be used for global "runtime" access
JEGS.page = {
  logger: 1, // uses custom console.log(), set as 1 or 0
  vars: {
    // set all pages to false
    isHome: false,
    isListPage: false,
    isPDP: false,
    isOrderItemDisplay: false,
    isPostAddtoCart: false,
    isViewOrder: false,
    isGenesysChat:true,
    isPayPalSDKEnabled:true
  },
  ready: function () {
    JEGS.page.route.which();

  },
  load: function () {
    // typically used for repaint events / image loading
  },
  scroll: function () {

  },
  resize: function () {
    JEGS.page.events.resize.any()
  },
  ajax: function () {
    // typically use for AJAX'd form response
  },
  
  route: {
    // build functions based on which page has been called
    which: function () {
      // RT.log("call JEGS.page.page", this);
      var page = RT.vars.page;
      if (page == '/') {
        RT.log("call JEGS.page.is.home", this);
        JEGS.page.vars.isHome = true;
        // Note: body addClass home in runtime.js
        // JEGS.page.is.home();

      } else if (/\/i\//.test(page)) {
        RT.log("call JEGS.page.is.pdp", this);
        JEGS.page.vars.isPDP = true;

      } else if (/\/PostAddtoCart\//.test(page)) {
        RT.log("call JEGS.page.is.pdp", this);
        JEGS.page.vars.isPostAddtoCart = true;
        JEGS.page.is.postAddtoCart();
      } else if (/\/ViewOrders/.test(page)) {
    	  RT.log("call JEGS.page.is.orderHistory", this);
    	  JEGS.page.vars.isViewOrder = true;    	 
      }
      
      /*if (JEGS.page.multiPage(page, 'cart')) { // multiPage test
        RT.log("call JEGS.page.is.orderItemDisplay", this);
        JEGS.page.vars.isOrderItemDisplay = true;
      };*/
      

      JEGS.page.is.any();
    },
    multiPage: function (page, pageGroup) {
      var isMatch = false;
      if (pageGroup == 'cart') {
        if (
          /\/OrderItemDisplay/.test(page) || 
          /\/PostAddtoCart/.test(page) || 
          /\/JegsCheckoutCmd/.test(page)
        ) { // test multiple pages here
          isMatch = true;
        }
      };
      return isMatch;
    }
  },
  is: {
    // EXECUTES AFTER CSS RENDERING, see runtime.js for pre-render events 
    home: function () {
    },
    brand_category_search: function () {
      JEGS.page.fxns.brand_category_search();
      JEGS.page.events.ready.brand_category_search();
    },
    pdp: function () {
      
    },
    postAddtoCart: function () {
      JEGS.page.fxns.postAddtoCart();
    },
    any: function () {
		
    }
  },
  events: {
    // logic to call these functions is done in the "caller"
    ready: {
     
    },
    resize: {
      any: function () {
        
      }
    },
    scroll: {
      home: function () {
        
      },
      any: function () {
        
      }
    },
  },
  fxns: {
        // put all of our extended functions at the bottom for better visual
    postAddtoCart: function () {
      // copied from servlet/PostAddToCart
      var pageX = 0,pageY = 0;
      var timer;
      var buid =  true;
      $('#store-categories .dropdown').on("mouseover", function(c) {
        clientX = c.clientX;
        var x_clientX = c.clientX;
         clientY = c.clientY;
         if(buid) { 			
          $(this).find(".overlay-element").addClass("dropdown-overlay");
        }
        $( ".dropdown .dropdown-overlay" ).mousemove(function( event ) {
            var clientCoords = "( " + event.clientX + ", " + event.clientY + " )";		 
          if(event.clientX < clientX ) {				
             $('.dropdown .overlay-element').removeClass("dropdown-overlay");
           }
          if(event.clientX == x_clientX && ((clientY + 5) < event.clientY || (clientY - 5) > event.clientY)) {				
             $('.dropdown .overlay-element').removeClass("dropdown-overlay");
           }
           clientX = event.clientX;
        });
        $( ".dropdown .dropdown-overlay" ).mouseleave(function( event ) {
          $('.dropdown .overlay-element').removeClass("dropdown-overlay");
        });
        $(this).find( ".dropdown-content" ).mouseenter(function() {
          buid = false;
          $('.dropdown .overlay-element').removeClass("dropdown-overlay");
        });
        $(this).find( ".dropdown-content" ).mouseleave(function() {
          buid = true;
        });
      });
    }
  }
};

READY.modifyDOM = {
  init: function () {
    JEGS.modifyDOM.ready();
  }
};
LOAD.modifyDOM = {
  init: function () {
    JEGS.modifyDOM.load();
  }
};

SCROLL.modifyDOM = {
  init: function () {
    JEGS.modifyDOM.scroll();
  }
};
RESIZE.modifyDOM = {
  init: function () {
    JEGS.modifyDOM.resize();
  }
};
AJAX.modifyDOM = {
  init: function () {
    JEGS.modifyDOM.ajax();
  }
};

JEGS.modifyDOM = {
  logger: 1,
  ready: function () {
	if ($( ".match-height" ).length)
	   JEGS.modifyDOM.adjustHeight();
	if ( $( "#attribItemTable" ).length )
		JEGS.modifyDOM.wrapAttrib();
	JEGS.modifyDOM.hideMoreText();
  },
  load: function () {
    
  },
  scroll: function () {

  },
  resize: function () {

  },
  ajax: function () {
    
  },
  adjustHeight: function(){
	var maxHeight = 0;

	//Featured Items on Level 1 Category Pages
	$(".featured-item-partial").each(function(){
		if ($(this).height() > maxHeight) { maxHeight = $(this).height(); }	
	});

	$(".featured-item-partial").height(maxHeight);

	//Featured Items on Level 1 Category Pages
	$(".featured-item").each(function(){
		if ($(this).height() > maxHeight) { maxHeight = $(this).height(); }	
	});

	$(".featured-item").height(maxHeight);

	//Cross-Sell Tiles on PDP
	$(".relatedItemCell").each(function(){
		if ($(this).height() > maxHeight) { maxHeight = $(this).height(); }	
	});

	$(".relatedItemCell").height(maxHeight);  
  },
  wrapAttrib: function(){
    var divs = $("#attribItemTable > div");
	for(var i = 0; i < divs.length; i+=2){
		divs.slice(i, i+2).wrapAll("<div class='cf'></div>");
	} 
  },
  hideMoreText: function(){
	//Crops headlines in Spotlight Carousels on Homepage & Cross-Sell Areas on PDP
	  $(".carousel-five-column .featured-item h2").each (function () {
	  	if ($(this).text().length > 40)
	  	$(this).text($(this).text().substring(0,40) + '...');
	  });

	  $(".match-height .marketingSpotTileCellTitle a,.itemAccessoryCell .marketingSpotTileCellTitle").each (function () {
	  	if ($(this).text().length > 60)
	  	$(this).text($(this).text().substring(0,60) + '...');
	  });
  }
};

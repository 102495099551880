var listRakMiniCart = {'orderId':null,'subTotal':null,'emailAddress':null,'orderItems':[]};
READY.minicart = {
  init: function () {
    JEGS.minicart.ready();
  }
};
LOAD.minicart = {
  init: function () {
    JEGS.minicart.load();
  }
};
// AFTERLOAD - see afterLoad.js for 3rd party script loading
SCROLL.minicart = {
  init: function () {
    JEGS.minicart.scroll();
  }
};
RESIZE.minicart = {
  init: function () {
    JEGS.minicart.resize();
  }
};
AJAX.minicart = {
  init: function () {
    JEGS.minicart.ajax();
  }
};
// JEGS.minicart contains all functions, variables, etc. used specifically by the module
// RT.vars, RT.url, RT.data namespaces should be used for global "runtime" access
JEGS.minicart = {
  logger: 1, // uses custom console.log(), set as 1 or 0
  invokeMiniCart: true,
  miniCartRequested: false,
  mcTotalItemQtyCount: 0,
  mcTotalItemCount: 0,
  hidemCart: false,
  onMouseOver: false,
  timeOutTimer: null,
  miniCartFocusID: "",
  paypalInitialized: false,
  
  ready: function () {
	JEGS.minicart.registerEvents();
	JEGS.minicart.toolTip();
	
  },
  toolTip: function () {
	    
	    var closeToolTip = function () {
				$('.ui-tooltip-content').parent().bind( "click", function() {
					$('.seeInCartToolTip').removeClass("fa-times-circle").addClass("fa-info-circle").tooltip( "close" );
				});
	    };
	    
	    if ($(window).width() <= RT.vars.bp.sm) {
				// hiding the shop by vehicle section in cart page for mobile.
				$(".page-content.cart #shop-by-vehicle").css('display','none');			
			} else if ($(window).width() >= RT.vars.bp.lg) {
	      $(".seeInCartToolTip").tooltip({
	        items:"i",
	        content: "JEGS is committed to offering the lowest prices possible. Since we've priced this item lower than the manufacturer suggests, the price can't be displayed until the item is in your shopping cart. Don't worry, you can easily remove it from your cart if you decide not to buy it."
	      });
			} else {							
				$('.seeInCartToolTip').click( function() {
					if ($('.ui-tooltip-content').length == 0) {
						$(this).removeClass("fa-info-circle").addClass("fa-times-circle");			
						$('.seeInCartToolTip').tooltip({
			    			content: "JEGS is committed to offering the lowest prices possible. Since we've priced this item lower than the manufacturer suggests, the price can't be displayed until the item is in your shopping cart. Don't worry, you can easily remove it from your cart if you decide not to buy it.",
						    items: 'i'
						  }).tooltip( "open" );
						setTimeout(closeToolTip, 1000);				   				
					} else {
						$('.seeInCartToolTip').tooltip( "close" );
						$(this).removeClass("fa-times-circle").addClass("fa-info-circle");
					}						   
				});
			}
	  },
  registerEvents: function(){
	  $('body').on('click', '#mini-cart-overlay', JEGS.minicart.closeMiniCart);
	  $('body').on('mouseenter', '#accountHeader,#account-wrap', JEGS.minicart.closeMiniCart);
  },
  init: function(){
	  JEGS.minicart.mouseEnterDispatcher();
  },
  minicart: function(params){
	    var userId=$('#mcuserId').val();
		var jsv4Enabled = $('#jsv4Enabled').val();
	    var	requestparams ={'requesttype': 'ajax','userId': userId ,'storeId':'10001','catalogId':'10002','langId':'-1','paypal':jsv4Enabled};
	    
		//setLeftMargin();
		
	   $('.x_cart').addClass('visible');
	   $('.x_cart').addClass('x_cart_fixed');
	   $('#mini-cart-overlay').addClass('visible');
	   $('#floating-atc').hide();
		
	   if($(window).width() < RT.vars.bp.sm) {
			
			$('.x_cart').show();
		    $('.apple-pay-button').show();
		}

	if(JEGS.minicart.invokeMiniCart) {
		//flag to avaoid  race condition
			if(!JEGS.minicart.miniCartRequested) {		
				JEGS.minicart.miniCartRequested=true;
		  
			 $('#x_cartSummary').html("");
			 $('#x_cartItems').html("");
			 $('#x_cartActions').html("");
			// $('#cart').unbind("mouseover");
			 
			 var miniCartUrl ="/webapp/wcs/stores/servlet/AjaxMiniCartDetailsView";
					$.ajax({
						url: miniCartUrl,
						type: 'post',
					    data: requestparams,
					    beforeSend: function(){			    
						JEGS.minicart.mcTotalItemQtyCount=0;
						JEGS.minicart.mcTotalItemCount=0;			    
						    $('#x_loading').show();
						    
		                
		                },
		                complete: function(){
		                	$('#x_loading').hide();
		                	JEGS.minicart.miniCartRequested=false;
		                 },
						error: function(results) 
						{			 
						//  console.log("ERROR"+results);
						 
						},				
						success: function(results)  {
							document.getElementById("mcInner").innerHTML = results;
							
							evalScripts(results);
							JEGS.minicart.hidemCart=false;	
						     if($("#noItemBag").length == 0) {
						    	if(JEGS.page.vars.isPayPalSDKEnabled == true) {
						    		if(!JEGS.minicart.paypalInitialized){
							    	  AFTERLOAD.paypal.showButtons(function(){
							    		  JEGS.minicart.executePayPalSDK('mini-cart', $("#payPalEnv").val());
							    		  JEGS.minicart.paypalInitialized = true;
							    	  });
							    	}else{
							    		JEGS.minicart.executePayPalSDK('mini-cart', $("#payPalEnv").val());
							    	}
								}else{
							    	if(!JEGS.minicart.paypalInitialized){
							    	  AFTERLOAD.paypal.showButtons(function(){
							    		  JEGS.minicart.executePayPal('mini-cart',$("#payPalEnv").val());
							    		  JEGS.minicart.paypalInitialized = true;
							    	  });
							    	}else{
							    		JEGS.minicart.executePayPal('mini-cart',$("#payPalEnv").val());
							    	}  
								}
								$('.x_cart').addClass('visible');
								JEGS.minicart.invokeMiniCart = false;
					            listTrackCartCollection(listRakMiniCart);
					            JEGS.minicart.createAnimations();
					            JEGS.minicart.mcTotalItemQtyCount = $("#mc_count").data("value");
					            $('#items-in-cart').text(JEGS.minicart.mcTotalItemQtyCount);
					            $('#floating-top-nav #items-in-cart').text(JEGS.minicart.mcTotalItemQtyCount);
					        	if(JEGS.minicart.mcTotalItemQtyCount!=0) {
					        		$('#cart').addClass('hasItem');
					        	}
					            //$('#mobCartCount').text(mcTotalItemQtyCount);		
					            $('#cart-icon').mouseenter(null); 
				            	if($('#mcorderCount').val()==0 ) {
					            	 $('#cart-icon').mouseenter(JEGS.minicart.mouseEnterDispatcher);
					            	 $('#floating-top-nav #cart-icon').mouseenter(JEGS.minicart.mouseEnterDispatcher);
					            	 $('#mcorderCount').val(JEGS.minicart.mcTotalItemQtyCount);
				            	}
				            	JEGS.minicart.mcIphoneApplePay();
				            	          	
				            	/*if(params!=null && params.jegserrorCode!=null ) {				            	
				            		printErrorMessage(params) ;
					            }*/
	  
					         } else if($("#noItemBag").length > 0) {
					        	JEGS.minicart.invokeMiniCart = false;
					        	JEGS.minicart.createNoItemShoppingBag();
					            $('.x_cart').addClass('visible');
								$('.x_cart').css('height', '130px');
					            $('#cart-icon').unbind('mouseenter mouseleave');
					            $('#floating-top-nav #cart-icon').unbind('mouseenter mouseleave');
					            $('#mcorderCount').val(0);
					         // ListRak empty cart 
					            listRakMiniCart.orderItems = [];
					            listRakMiniCart.orderId = null;
					            listRakMiniCart.subTotal = null;
					            listRakMiniCart.emailAddress = null;
					            listTrackCartCollection(listRakMiniCart);
				           }
			          
				   },
						  cache: true
					});
			}	// end of minicart not requested b4 flow
		} else {
			if(JEGS.minicart.mcTotalItemQtyCount!=0) {
				
				$('.x_cart').addClass('visible');
			}
			JEGS.minicart.createAnimations();					   
		}	
				  
  },
  load: function () {
    // typically used for repaint events / image loading
  },
  scroll: function () {

  },
  resize: function () {

  },
  ajax: function () {
    // typically use for AJAX'd form response
  },
  closeMiniCart: function(e){
	  JEGS.minicart.hidemCart=false;
	 $('.x_cart').removeClass('visible');
	 $('#mini-cart-overlay').removeClass('visible');
	 $('.x_cart').removeClass('x_cart_fixed'); 
	 $('#mcitemAdded').html("");
	 $('#x_cart_tickli').hide();
     $('#x_loading').hide();
	 $('.apple-pay-button').hide();
	 if($(window).width() < RT.vars.bp.sm) {
		$('.x_cart').hide();
	 }
  },
  createAnimations: function () {
		
	    $('#expressCart').mouseover( function () {
	    	JEGS.minicart.onMouseOver=true;
		      $('.x_cart').addClass('visible');		
		      clearTimeout(JEGS.minicart.timeOutTimer)	;
		});
		
		 $('#cart-icon').mouseover( function () {
			 JEGS.minicart.onMouseOver=true;
		      $('.x_cart').addClass('visible');		
		      clearTimeout(JEGS.minicart.timeOutTimer)	;
		});
		 
		 $('#cart-icon').focus( function () {
			 JEGS.minicart.onMouseOver=true;
		      $('.x_cart').addClass('visible');		
		      clearTimeout(JEGS.minicart.timeOutTimer)	;
		});
		 
	    var focusvar= "FeaturedProducts-image-"+JEGS.minicart.miniCartFocusID ;   
	    var focusDiv =  $('#'+focusvar) ;    
	    if (focusDiv!=null ) {
	    	$(focusDiv).focus(); 
	    }
	    $('#expressCart').mouseleave(  function( ) {
	    	JEGS.minicart.onMouseOver=false;
	    	JEGS.minicart.hideminiCart();
	    			
	    });
		
		$('#cart-icon').mouseleave(  function( ) {
			JEGS.minicart.onMouseOver=false;
			JEGS.minicart.hideminiCart();
	    			
	    });
	 	JEGS.minicart.tabTimeOut();

	},
	hideminiCart: function(){
	  if(!($(window).width() < RT.vars.bp.sm)) {
	  JEGS.minicart.timeOutTimer = setTimeout(function(){		
				
				   if(!JEGS.minicart.onMouseOver && !JEGS.checkoutButtons.express.orderPlaced) {
					   JEGS.minicart.hidemCart=false;
					   
				      $('.x_cart').removeClass('visible');
					  $('#mini-cart-overlay').removeClass('visible');
					  $('.x_cart').removeClass('x_cart_fixed'); 
					  $('.apple-pay-button').hide();
				   }
			 
	       }, 3000);	
		}
	},
	
	tabTimeOut : function() {
		
		var winWidth=$(window).width();
		
		if((winWidth > RT.vars.bp.sm ) && (winWidth < RT.vars.bp.md)) {
			JEGS.minicart.onMouseOver=false;
			  $('#expressCart').on({ 'touchstart' : JEGS.minicart.tabTimeoutTouchStart });
		
		} else if( navigator.userAgent.match(/iPad/i)) {
			
			  $('#expressCart').on({ 'touchstart' : JEGS.minicart.tabTimeoutTouchStart });
		}
	},
	tabTimeoutTouchStart: function () {
	  JEGS.minicart.onMouseOver=false;clearTimeout(JEGS.minicart.timeOutTimer);  
	  JEGS.minicart.hideminiCart();
	},
	mouseEnterDispatcher: function(){
		if(($(window).width() < RT.vars.bp.lg || $( ".touch-enabled" ).length)) { 	
			return false;
	}
	else {		
		JEGS.minicart.minicart();		
		$('.x_cart').removeClass('x_cart_fixed');
   		$('#mini-cart-overlay').removeClass('visible');
	}	
	},
	mcIphoneApplePay: function(){
		
		var isIPhone = false;
	    if(RT.vars.ua.indexOf("iphone") != -1) {
	    	 isIPhone=true;
	     }
	     if ( $('#applePayenable').val()=='true'  &&  isIPhone  && window.ApplePaySession && ApplePaySession.canMakePayments) {
	           var applePayButton =  createBUTTON( "apple-pay-button","ppc_applePay","" ,applePayButtonClicked);
			   var applePaySpinner = createI("fa fa-spinner fa-spin fa-3x fa-fw ap-loader","x_loading_ap","display:none");
			   $('#x_cartActions').append(applePayButton) ;
			   $('#ppc_applePay').append(applePaySpinner);
			   $('.x_cart').addClass('w-apple-button');
	    } 
	},
	
	executePayPalSDK: function(clickFrom, env) {
	    var URL = '/wcs/resources/store/10001/cart/paypal';
        var form = "PayPalForm";
        if(clickFrom === "checkout") {
            form = "JegsOrderForm";
        }
        var orderId = $("#" + form + " input[name=orderId]").val();    
        console.log("OrderID: "+orderId);
		paypal.Buttons({
            style: {
                layout: 'horizontal',
	            color: 'silver',
                shape: 'rect',
	            tagline: false
            },
        	createOrder: function(data, actions) {
                return fetch(URL, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                	body: JSON.stringify({
            	orderID: orderId
                })
            })
            .then(function(response) {
                if (!response.ok) {
                    throw new Error('Failed to create order');
                }
                return response.json();
            })
            .then(function(order) {
                console.log("Order ID created: ", order.id); 
                return order.id;
            })
            .catch(function(error) {
                console.error("Error in createOrder:", error);
                throw error;
            });
    	},
    	onApprove: function(data) {
            $('body').addClass('modalOpen scrollLock');
            $('body').prepend('<div class="spinner"/>');
            $('.modalOpen').css({'z-index':'130'});
            var orderData = {
            	payPalSDKOrderID: data.orderID,
                calculateOrder: 1,
                URL: "JegsCheckoutCmd",
                selectedShippingOption: "",
                isPayPalCredit: "false",
                shipPartial: ""
            };
            var form = document.createElement("form");
            form.method = "POST";
            form.action = "/webapp/wcs/stores/servlet/JegsCheckoutCmd?storeId=10001&catalogId=10002&langId=-1&orderId=" + orderId + "&checkoutAction=After_PayPalSDK&paymentType=PAYPAL";
            for (var key in orderData) {
                if (orderData.hasOwnProperty(key)) {
                    var hiddenField = document.createElement("input");
                    hiddenField.type = "hidden";
                    hiddenField.name = key;
                    hiddenField.value = orderData[key];
                    form.appendChild(hiddenField);
                }
            }
            document.body.appendChild(form);
            form.submit();
        },
        onCancel: function(data) {
            console.log("Transaction cancelled", data);
        },
        onError: function(err) {
            console.error("Error during PayPal transaction:", err);
        }
		}).render('#paypal-button');
    },

   executePayPal: function (clickFrom, environment) {
		var form ="PayPalForm";
		if(clickFrom == "checkout") {
			form ="JegsOrderForm";
		}
		var orderId = $("#"+form+" input[name=orderId]").val();	
	    paypal.Button.render( {
		    style: {
		    	size: 'responsive',
		    	layout: 'horizontal',
		    	shape: 'rect',
		    	color: 'silver',
	            tagline: false
		    },
		    funding: {
		    	allowed: [ paypal.FUNDING.CARD,paypal.FUNDING.CREDIT  ],
		    	disallowed: [ ]	
			},
			env: environment, //'sandbox', Or 'production',
			validate: function(actions) {		
			},		
			onClick: function() {	 
				/*if(clickFrom == "cart") {
					PayPalCheckoutPrepare(form,$("#totalNumItems").val());
				} else if(clickFrom == "checkout") {
					checkoutPrepare(document.JegsOrderForm, 'Submit_Order');
				} */
			},
			payment: function (data, actions) {		  	
		    	return actions.request.post('/webapp/wcs/stores/servlet/JegsPayPalOrderPrepare',JEGS.minicart.getPayPalParam(form))
		         .then(function(res) {  return res.ProcessorTransactionId;  });
			},
			onAuthorize: function (data, actions) {		    	  
				return actions.redirect(window, '/webapp/wcs/stores/servlet/JegsCheckoutCmd?storeId=10001&catalogId=10002&langId=-1&orderId='+orderId+'&checkoutAction=After_PayPal&policyId=10052&paymentType=PAYPAL');    	 
			},
			onCancel: function (data, actions) {
				//return actions.redirect(window, '/webapp/wcs/stores/servlet/OrderItemDisplay?updatePrices=1&orderId=.&calculationUsageId=-1&catalogId=10002&langId=-1&storeId=10001');
			},
			onError: function (error) {	    
			}
		}, '#paypal-button');
	},
	
	createNoItemShoppingBag: function( ) {
		  
	     $('#items-in-cart').text("0");
	     $('#floating-top-nav #items-in-cart').text("0");
	     $('#cart').removeClass('hasItem');
	     $('#cart-total').text("$0.00" );
	 	 $('#mc_count').html("Edit Cart (0)" ) ;
	     //$('#mobCartCount').text("0");
    },
    viewMainCart: function (){
      var cartURL ="/webapp/wcs/stores/servlet/OrderItemDisplay?updatePrices=1&orderId=.&calculationUsageId=-1&catalogId=10002&langId=-1&storeId=10001&ddkey=http:OrderCalculate";
      location.href=cartURL;
    },
    getPayPalParam: function(form) {
    	return $("#"+form).serializeArray().reduce(function(a, x) { a[x.name] = x.value; return a; }, {});	
    },
    navigateToHome: function(){
    	window.location.href = "/";
    },
    
};

// feedback module
READY.feedback = {
  init: function () {
    JEGS.feedback.ready();
  }
};
AJAX.feedback = {
	init: function () {
		JEGS.feedback.load();
	}
};
JEGS.feedback = {
  logger: 0,
  ready: function () {
		JEGS.feedback.setClick();
	},
	setClick: function () {
  	$('body').on('click', '.fxnFeedback', JEGS.feedback.showModal);
	},
	showModal: function (e) {
		var FBmodal = $('.modalFeedback');
		// create the container if doesn't exist yet
		if (FBmodal.length == 0) {
			var data = {
				title: "Send Us Feedback",
				cancel: "Cancel",
				submit: "Send"
			};
			var fbContainer = JEGS.modal.retContainer(data);
			$(fbContainer).addClass('modalFeedback').appendTo($('body'));
		}
		JEGS.feedback.loadContent(e);
	},
	loadContent: function (e) {
		RT.fxns.setLastFocus(e);
		JEGS.modal.open('.modalFeedback');
		$('.modalFeedback .btnClose').focus();
		JEGS.feedback.getFeedbackJS(); // loads only once
		JEGS.feedback.getRecaptchaJS();
	},
    getFeedbackJS: function () {
		// request feedback.js, the onload script on that file will auto load
		var scriptData = {
            "subDir": "/app/js/ajax",
			"file": "/feedback.js",
			"module": "feedback",
			"id": "feedbackJS"
		};
		RT.ajax.getScript(scriptData);
	},
	getRecaptchaJS: function (){
		var siteKey = JEGS.feedback.recaptcha.siteKey;
		RT.vars.captchaQS = "render="+siteKey;
		RT.vars.captchaLoad = true;
		RT.vars.captchaJSId = "captcha-v3-feedback";
		AFTERLOAD["recaptcha"].init(); // Downloads recpatcha script on client browser
	},
	
	ajaxInit: function () {
		RT.log("feedback ajaxInit placeholder", this)
	},
	recaptcha : {
		"siteKey" : "6Lca6aMUAAAAALOyzQJ-klfQtXZeOp81CfzDOyUP",
		"jsId" : "captcha-v3-feedback",
		"loadCaptcha" : true
	}
};
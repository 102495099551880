// NAVIGATION [ready|load|resize] function calls
READY.nav = {
  init: function () {
    JEGS.nav.ready();
  }
};
/*
LOAD.nav = {
  init: function () {
    JEGS.nav.load(); // empty
  }
};
*/
RESIZE.nav = {
  init: function () {
    JEGS.nav.resize();
  }
};
SCROLL.nav = {
  init: function () {
    JEGS.nav.scroll();
  }
};
AJAX.SUCCESS.nav = {
  init: function () {
    JEGS.nav.desktop.setTabIndexes();
  }
};

// use JEGS main namespace for core functionality
JEGS.nav = {
  logger: 1,
  vars: {
    isHovered: 0,
    x: 0,
    y: 0
  },
  ready: function () {
   JEGS.nav.registerEvents(); 
   //JEGS.nav.getVehicleYears.fetchData();	  
   if (RT.vars.page == "/") { // only run on home page to display
        JEGS.nav.getSetHTML();
      } else {
        $('body').addClass('baseNav');
    }
   JEGS.nav.createNavOnClick();
  },
  registerEvents: function(){
	 //$('body').on('click', '#quicklinksbar2 #departments,button#hamburger,#catNavHeader', JEGS.nav.toggleNav); 
  },
  /*
  load: function () {
    if (RT.vars.touch) {
      // add image loading calls
    }
  },
  */
  resize: function () {
    // console.log("nav resize")
    if (RT.vars.touch) {
      RT.log('NAV resize', this);
      JEGS.nav.mobile.setNavHeight();
    } else {
      JEGS.nav.desktop.closeNav();
    }
    JEGS.nav.desktop.navDropdownPos();
  },
  scroll: function () {
    if (!RT.vars.touch) {
      JEGS.nav.desktop.setInActive();
      JEGS.nav.desktop.dropdownExit();
      JEGS.nav.desktop.setTabIndexes();

      if (RT.vars.page == "/") {
        $('#shop-by-category').removeAttr('style');
        // adjust for scroll lock / locked header
        JEGS.nav.desktop.navDropdownPos();
      }
    }
  },
  ajax: function () {
    JEGS.nav.desktop.setTabIndexes();
  },
  createNavOnClick: function () {
    $('#departments button, #catNavHeader, #hamburger').on("click", JEGS.nav.getSetHTML);
    if(RT.vars.touch)
    	JEGS.nav.mobile.hamburgerMenu();
  },
  getVehicleYears: {
	 successQS : "#vehicle-search #YEAR",
	 yearsData : null,
	 fetchData : function(){
	   var url = "/webapp/wcs/stores/servlet/MMYCboView?storeId=10001&langId=-1&catalogId=10002";
	   RT.ajax.send(url,JEGS.nav.getVehicleYears.statusIndicator,JEGS.nav.getVehicleYears.showData,JEGS.nav.getVehicleYears.showError);
     },
     statusIndicator : function(){
    	$("#vehicle-search #YMM-wait").show(); 
     },
     showData : function(results){
    	$("#vehicle-search #YMM-wait").hide().stop(); 
    	var yearsData = $("<div/>").html(results).find(JEGS.nav.getVehicleYears.successQS);
    	JEGS.nav.getVehicleYears.yearsData = yearsData.html();
    	//$(JEGS.nav.getVehicleYears.successQS).html(yearsData.html());
     },
     showError : function(){
    	$("#vehicle-search #YMM-wait").hide().stop();
    	$("#vehicle-search #YMM-error").html("Error occurred while retrieving the vehicle data. Please reload the page.");
    	$("#vehicle-search #YMM-error").show();
     }
  },
  
  buildNavDisplay: function () {

    RT.vars.newnav = true;
    if (RT.vars.touch) {
      JEGS.nav.mobile.init(); // Categories & Footer Nav Accordion
    } else {
      JEGS.nav.desktop.init();
    }
  },
  isDisplayBuilt: false,
  getSetHTML: function (e) {
    // get the BASE layout HTML from JSP or sessionStorage and set it in the page
    if(e != undefined){
    	JEGS.nav.toggleNav();
    }
    var isNavBuilt = ($('nav').length > 0 ? true : false);
    if (JEGS.nav.isDisplayBuilt) { // clicked to open nav
        return false;
    } 
   if (RT.vars.hasStorage) {
      var strNav = localStorage.getItem("nav");
      if(isNavBuilt){
    	  var arrayJSON = JEGS.nav.retParsedNavAsJSON();
    	  var str = JSON.stringify(arrayJSON);
          localStorage.setItem("nav", str);
      }
      else if (strNav != null) {
        var arrayJSON = $.parseJSON(strNav);
        JEGS.nav.setNavHTML(arrayJSON);
      }
      else if (strNav == null) { //
    	  RT.fxns.cookie.del('nav');
      }
    }else{
    	RT.fxns.cookie.del('nav');
    }
    // call fxn to build the final MOBILE or DESKTOP layout
    JEGS.nav.buildNavDisplay();
    JEGS.nav.isDisplayBuilt = true;
  },

  setNavHTML: function (arrayJSON) {
    RT.log('setNavHTML...', this);
    var parent = $('#shop-by-category-content');
    var nav = $('<nav class="rootCategoriesLinks" role="category-list" aria-label="category" aria-orientation="vertical"></nav>'); // hide
    RT.log('arrayJSON len: ' + arrayJSON.length, this);
    $.each(arrayJSON, function (idx, section) {
      // RT.log(section, this);
      var html = JEGS.nav.build.retCategory(section); // name,type,links
      html.appendTo(nav);
    });
    // console.log("nav:", nav)
    nav.appendTo(parent);
    /* builds this core html structure...
      <nav class="rootCategoriesLinks">
      <div class="category type="top">
        <h3 class="head">
          <span>Top Categories</span>
        </h3>
        <ul level="top-category" itemscope itemtype="http://www.schema.org/SiteNavigationElement">
          <li itemprop="name"><a href="/c/Brakes-Drivetrain/10139/10002/-1" 
            category="Brakes-Drivetrain" categoryId="10139"
            itemprop="url">Brakes &amp; Drivetrain</a>
              <div category="Brakes-Drivetrain" bgImg="/images/categories/category_brakes_and_drivetrain.jpg">
                <ul pos="main">
                  <li itemprop="name"><a href="/c/Brakes-Drivetrain_Brake-Disc-Drum-Kits/10155/10002/-1" id="subM_40" img="/images/categories/10-brakes_disc_drum.jpg" itemprop="url">Brake - Disc &amp; Drum Kits</a></li>
                </ul>
                <ul pos="aside">
                  <li itemprop="name"><a id="subA_410" href="/c/Brakes-Drivetrain_Pedals/10163/10002/-1" itemprop="url">Pedals</a></li>
                </ul>
              </div>
          </li>
        </ul>
      </div>
      </nav>
    */
  },

  // this code builds the <nav> from JSON data saved in sessionStorage
  build: {
    retCategory: function (section) {
      var html = $('<div class="category" type="' + section.type + '">'+
      '<h3 class="head"><span>' + section.name + '</span></h3></div>');
      var links = JEGS.nav.build.retCategoryLinks(section);
      links.appendTo(html);
      return html;
    },
    retCategoryLinks: function (section) {
      var ulObj = $('<ul level="' + section.type + '-category" itemscope itemtype="http://www.schema.org/SiteNavigationElement"></ul>');
      $.each(section.links, function (idx, data) {
        RT.log(data, this);
        
        var linkObj = $('<li itemprop="name">' +
                        '<a href="' + data.categoryURL + '" ' +
                        'title="' + data.categoryText + '" ' +
                        'role="' +  section.type +'-category-tab" '+
                        'aria-controls="' + data.category + '" ' +
                        'category="' + data.category + '"categoryId="' + data.categoryId + '" ' +
                        'itemprop="url">' + data.categoryText + '</a></li>');
        linkObj.appendTo(ulObj); 
      }); 
      return ulObj;
    },
    retSubSections: function (linkArr, linkSection) {
      if (linkArr.length === 0) { return $("") }
      var ulObj = $("<ul pos='" + linkSection + "'></ul>");
      $.each(linkArr, function (idx, link) {
        var imgProp = (link.img ? "img=" + link.img : "");
        var liObj = $('<li itemprop="name"><a href="' + link.url + '" title= "'+link.name+'" id="' + link.id + '" ' + imgProp + ' itemprop="url">' + link.name + '</a></li>')
        liObj.appendTo(ulObj);
      });
      return ulObj;
    }
  },

  retParsedNavAsJSON: function () {
    // loop each nav element & apply to JSON data array
    var data = {},
        nav = $('.rootCategoriesLinks .category');
    RT.log("categories: " + nav.length, this);

    nav.each(function (idx, categorySections) {
      var categ = $(categorySections), 
          name = $.trim(categ.find('.head').text()), 
          type = categ.attr('type'),
          topLevelLIs = categ.find('[level] > li'),
          sectionData = {
            name: name, // Top|All Categories
            type: type, // top|all
            links: {}
          };
      
      RT.log("topLevelLIs: " + topLevelLIs.length, this);
      topLevelLIs.each(function (i, liElem) {
        var li = $(liElem),
            anchor = li.find('> a'),
            div = li.find('div'),
            category = anchor.attr('category'),
            categoryText = $.trim(anchor.text()),
            title = $.trim(anchor.text()),
            categoryId = anchor.attr('categoryId'),
            categoryURL = anchor.attr('href'),
            bgImg = div.attr('bgImg'),
            // subCategories
            main = div.find('[pos="main"] li'),
            aside = div.find('[pos="aside"] li'),
            mainArr = [],
            asideArr = [];

        // RT.log("main: " + main.length, this);
        main.each(function (j, li) {
          var a = $(li).find('a'),
            url = a.attr('href'),
            name = a.text(),
            title = a.text(),
            img = a.attr('img'),
            id = a.attr('id'),
            link = {
              url: url,
              name: name,
              title: name,
              img: img,
              id: id
            }
          mainArr.push(link)
        });
        // RT.log("aside: " + aside.length, this);
        aside.each(function (j, li) {
          var a = $(li).find('a'),
            url = a.attr('href'),
            name = a.text(),
            id = a.attr('id'),
            link = {
              url: url,
              name: name,
              id: id
            }
          asideArr.push(link)
        });

        var categorySection = {
          category: category,
          categoryText: categoryText,
          title:categoryText,
          categoryId: categoryId,
          categoryURL: categoryURL,
          bgImg: bgImg,
          main: mainArr,
          aside: asideArr
        };
        //sectionData.links.push(categorySection);
        sectionData.links[category] = categorySection;
      });

      data[type] = sectionData;
    });
    return data;
  },
  addShopLinks: function () {
      var shopLinks = $("<div class='shopLinks'/>").insertAfter('nav');
      var className = "link shopLink fxnAddVehicleParams";
      var wrapperText = "<span class=text/>";
      var angleRT = '<span class="iconContainer"><i class="fa fa-angle-right"></i></span>';
      var shopBrand = $("<a/>"),deals = $("<a/>"), newProducts = $("<a/>"), techArticles = $("<a/>");
      shopBrand.attr('href','/Brands').addClass(className).text("Shop By Brand").contents().wrap(wrapperText);
      shopBrand.append(angleRT).appendTo(shopLinks);
      deals.attr('href','/Deals').addClass(className).text("Shop Deals").contents().wrap(wrapperText);
      deals.append(angleRT).appendTo(shopLinks);
      newProducts.attr('href','/c/Restoration/366815/10002/-1').addClass(className).text("Shop Restoration").contents().wrap(wrapperText);
      newProducts.append(angleRT).appendTo(shopLinks);
      techArticles.attr('href','/tech-articles').addClass(className).text("Tech Articles").contents().wrap(wrapperText);
      techArticles.append(angleRT).appendTo(shopLinks);
  },
  
  topLevelShopLinks: function (navParent) {
      var divs = navParent.find('#divider ~ .category-name.mobileLeftNav');
      if(divs.length > 0){
      divs.each(function (idx, div) {
        var divObj = $(div);
        var anchor = divObj.find('a');
        var shopLinks = $("<div class='shopLinks'/>").insertAfter('nav');
        anchor
          .attr('id', anchor.text().replace(/ /g,''))
          .contents().wrap('<span class=text/>');
        anchor.append('<span class="iconContainer"><i class="fa fa-angle-right"></i></span>');
        anchor.appendTo(shopLinks);
        divObj.remove();
         });
      }else{
    	  JEGS.nav.addShopLinks();
      }
      $('.fxnAddVehicleParams').on('click', JEGS.nav.mobile.clickEvents.topLevelShopLinkClick);
    },
  
  desktop: {
    init: function () {
      JEGS.nav.desktop.navSetup();
      JEGS.nav.desktop.setupHoverEvents();
    },
    navSetup: function () {
      // format <NAV> HTML for desktop layout
      var nav = $('nav');
      nav.addClass('v01');
      nav.find('a[category]').each(function (idx, anchor) {
        $(anchor).append($('<span class="iconContainer"><i id="arrow" class="fa fa-angle-right"></i></span>'));
      });
      // set tabindex'ing to tab through quicklinks before navigating the Categories
      $('#quicklinksbar2').insertBefore($('#shop-by-category'));
      if(RT.vars.page != "/") 
       JEGS.nav.topLevelShopLinks($('#shop-by-category')); // Adds Shop By Brand, Deals and New Products Links
      nav.find('a[category]').on("click",JEGS.nav.desktop.categoryLinkClick); // Adds top category that is clicked to the cookie 
      nav.find('div[category] a').on("click", JEGS.nav.subMenuLinkClick); // Adds YMM query string to the sub category URL if present
      JEGS.nav.desktop.setTabIndexes();
    },
    categoryLinkClick: function (e) {
        e.stopPropagation();
        e.preventDefault();
        // save the category
        var obj = $(e.currentTarget);
        var category = obj.attr("categoryid");
        RT.log("topcategoryclick: " + category, this);
        RT.fxns.cookie.set("category", category, 1);
        var href = obj.attr('href');
        RT.url.loc(href);
    },
    setTabIndexes: function () {
      RT.log('setTabIndexes...', this);
      $('header, .main-nav, nav, .StickyBannerSaleAd').find('a:visible, button:visible, input:visible').attr('tabindex', 1);
    },
    setupHoverEvents: function () {
      RT.log('setupHoverEvents', this)

      var mainNavLinks = $('.main-nav').find('button, a');
      var topLevelLinks = $('#store-categories .dropdown, #store-categories .dropdown > a');  // TODO: remove
      var subLevel = $('.dropdown-content .flyout'); // TODO: remove
      var subLevelLinks = subLevel.find('a');

      var hideNavMenuObjs = $('.main-nav, nav .category .head');
     
        var topLevelLinks = $('nav a[category]');
        var subLevel = topLevelLinks.find('+ div');
        var subLevelLinks = subLevel.find('a');
        RT.log("subLevelLinks: " + subLevelLinks.length, this);
        RT.log("topLevelLinks len: " + topLevelLinks.length, this);

        mainNavLinks
          .keydown(JEGS.nav.desktop.linkKeyPress)
        hideNavMenuObjs
          .on("mouseover focus", JEGS.nav.desktop.dropdownExit);
        topLevelLinks
          .attr('tabindex',1)
          .keydown(JEGS.nav.desktop.linkKeyPress)
          .on("focus", JEGS.nav.desktop.linkHover)
          .on("blur", JEGS.nav.desktop.linkBlur)
          .on("mouseover", JEGS.nav.desktop.linkHover)
          .on("mousemove", JEGS.nav.desktop.linkHoverMove)
          .on("mouseleave", JEGS.nav.desktop.linkHoverExit);
        subLevel
          .on("mouseover", JEGS.nav.desktop.dropdownHover)
          .on("mouseleave", JEGS.nav.desktop.dropdownExit);
        subLevelLinks
          .keydown(JEGS.nav.desktop.linkKeyPress);

        // fix tabindexes, delay needed for page to load 
        setTimeout(function () {
          $('.slick-slide').removeAttr('tabindex'); // prohibiting <a> focus event
          $('.page-content a[tabindex=0]').attr('tabindex', 1);
        }, 1200);
    
      $('body').on("mousemove", RT.ui.XY);
      $('#catNavHeader').on('click', JEGS.nav.desktop.navDropdownPos);
      JEGS.nav.desktop.navDropdownPos()
    },
    navDropdownPos: function () {
      if (RT.vars.page == "/" && !RT.vars.touch) {
        // when HOME page and desktop, adjust the left position and width of dropdowns
        var navSection = $('#shop-by-category'); // $('nav')

       
          var navW = navSection.outerWidth();
          var LT = parseInt(navSection.offset().left);
          var dropDownWidth = $('#quicklinksbar2').outerWidth();
          $('nav div[category]').css('left', LT + navW).width(dropDownWidth - navW);
     
       
      }
    },
    retDIVobj: function (e) { 
      var linkObj = $(e.currentTarget);
      if (!linkObj.hasClass('dropdown') && !RT.vars.newnav) { // TODO: remove block
        var linkObj = linkObj.closest('.dropdown')
      };
      return linkObj;
    },
    retKeyCode: function (e, obj, nextSiblingParent) {
      var flag = false;
      var keyCodes = RT.vars.keyCodes;

      // Exception - when user taps RT/DN arrow when on Categories button
      if (obj.prop("tagName") == 'BUTTON') {
        var keyCodes = RT.vars.keyCodes;
        var keysArr = [keyCodes.RIGHT, keyCodes.DOWN]; //, keyCodes.RETURN, keyCodes.SPACE
        if ($.inArray(e.keyCode, keysArr) > -1) {
          RT.log("RT/DN", this);
          var isNavOpen = $('#shop-by-category').hasClass('visible');
          if (!isNavOpen) {
            JEGS.nav.toggleNav() // in JegsResponsive.js
          };
          var navObj = $('nav a[tabindex=1]').first();
          navObj.focus();
          return true;
        }        
      }

      switch (e.keyCode) {

        case keyCodes.ESC:
          RT.log("ESC", this);
          JEGS.nav.desktop.dropdownExit(e);
          $('.main-nav li:first-child [tabindex]').focus();
          flag = false;
          break;
  
        case keyCodes.UP:
          RT.log("UP", this);
          JEGS.nav.desktop.setFocusToPreviousItem(e, obj, nextSiblingParent);
          flag = true;
          break;
  
        case keyCodes.DOWN:
          RT.log("DN", this);
          JEGS.nav.desktop.setFocusToNextItem(e, obj, nextSiblingParent);
          flag = true;
          break;

        case keyCodes.LEFT:
          RT.log("LT", this);
          JEGS.nav.desktop.setFocusToParentItem(e, obj);
          flag = true;
          break;
          
        case keyCodes.RIGHT:
          RT.log("RT", this);
          JEGS.nav.desktop.setFocusToSubItem(e, obj);
          flag = true;
          break;          
        
        case keyCodes.SPACE:
          RT.log("SPACE", this);
          JEGS.nav.desktop.setFocusToSubItem(e, obj);
          flag = true;
          break;

        case keyCodes.HOME:
          RT.log("HOME", this);

        case keyCodes.PAGEUP:
          RT.log("PGUP", this);
          JEGS.nav.desktop.setFocusToFirstItem(e, obj);
          flag = true;
          break;
  
        case keyCodes.END:
          RT.log("HOME", this);

        case keyCodes.PAGEDOWN:
          RT.log("PGDN", this);
          JEGS.nav.desktop.setFocusToLastItem(e, obj);
          flag = true;
          break;
  
        case keyCodes.TAB:
          RT.log("TAB", this);
          JEGS.nav.desktop.setFocusToNextItem(e, obj, nextSiblingParent);
          flag = true;
          break;
  
        default:
          RT.log("DEF", this);
          break;
      }
      return flag;
    },
    linkKeyPress: function (e) { // keyDown event
      RT.log("linkKeyPress..." + e.keyCode, this);
      var TF = false;
      var keyCodes = RT.vars.keyCodes;
      var obj = $(e.currentTarget);
      var nextSiblingParent = $('nav');

      var dropDownNav = obj.closest('div[category]');
      if (dropDownNav.length > 0) {
        // console.log('parent -> dropDownNav')
        nextSiblingParent = dropDownNav;
      }

      // if keypress on the main nav items, switch the parent...
      var mainNav = obj.closest('.main-nav');
      if (mainNav.length > 0) {
        nextSiblingParent = $('.main-nav');
      };

      var TF = JEGS.nav.desktop.retKeyCode(e, obj, nextSiblingParent);
      if (TF) {
        e.stopPropagation();
        e.preventDefault();
      }
    },
    setFocusToPreviousItem: function (e, obj, nextSiblingParent) {
      // console.log("nextSiblingParent: ", nextSiblingParent)

      // main categories links & sublinks
      var prevObj = obj.closest('li').prev().find('> a, > button');
      // fix for .moreLink
      if (obj.hasClass('moreLink')) {
        RT.log('prev from moreLink', this)
        prevObj = obj.closest('div').find('a:nth-last-child(-n-1)');
      }

      if (prevObj.length > 0) {
        RT.log('prevObj > 0', this);
        var dropDownNav = obj.closest('div[category]');
        if (dropDownNav.length === 0) {
          JEGS.nav.desktop.setHoveredActive(e, prevObj); 
        };
        prevObj.focus()

      } else if (nextSiblingParent) {
        RT.log('nextSiblingParent...', this);
        var linkObjs = obj.closest(nextSiblingParent).find('a[category][tabindex=1]');

        if (linkObjs.length === 0) { // dropdown links
          var linkObjs = obj.closest(nextSiblingParent).find('a[tabindex=1]');
        }

        // handle end of .main-nav elements focus
        if (linkObjs.length === 0) {
          RT.log("top of nav elements...?",this);

        } else {
          RT.log('find prev sibling from active', this);
          obj.addClass('linkActive');
          var linksLen = linkObjs.length;
          linkObjs.each(function (idx, link) {
            var a = $(link);
            if (a.hasClass('linkActive')) {
              var nextObj = $(linkObjs[idx - 1]);
              nextObj.focus();
              obj.removeClass('linkActive');
              return;
            }
          });
        }

      } else {
        JEGS.nav.desktop.setFocusToLastItem(e, obj);
      }
    },
    setFocusToNextItem: function (e, obj, nextSiblingParent) {
      var nextObj = obj.closest('li').next().find('> a, > button');
      RT.log('setFocusToNextItem -> nextObj.length: ' + nextObj.length, this);
      RT.log('nextSiblingParent', this);
      RT.log(nextSiblingParent, this);

      // fix for .moreLink
      if (obj.hasClass('moreLink')) {
        RT.log('next from moreLink', this)
        nextObj = obj.closest('div').find('a[tabindex=1]').first();
      }

      if (nextObj.length > 0) {
        var dropDownNav = obj.closest('div[category]');
        if (dropDownNav.length === 0) {
          RT.log('call setHoveredActive...', this);
          JEGS.nav.desktop.setHoveredActive(e, nextObj);
        };
        RT.log('next obj focus...', this);
        nextObj.focus()

      } else if (nextSiblingParent) {
        RT.log('nextSiblingParent...', this);
        // top level categories links
        var linkObjs = obj.closest(nextSiblingParent).find('a[category][tabindex=1]');

        if (linkObjs.length === 0) {
          // dropdown links (or main-nav) returned
          var linkObjs = obj.closest(nextSiblingParent).find('a[tabindex=1]');
        }

        // handle end of .main-nav elements focus
        if (nextSiblingParent.hasClass('main-nav')) {
          RT.log("continue tabbing through page content...",this);
          // exits the nav elements
          JEGS.nav.desktop.dropdownExit(e);
          var isNavOpen = $('#shop-by-category').hasClass('visible');
          if (isNavOpen) {
            JEGS.nav.toggleNav() // in JegsResponsive.js
          };
          var nextObj = $('.page-content a[tabindex=1]').first();
          if (nextObj.length === 0) {
            nextObj = $('.page-content a:visible').first();
          };
          nextObj.focus();

        } else {
          RT.log('find next sibling from active', this);
          obj.addClass('linkActive');
          RT.log($('.linkActive').html(), this);
          var linksLen = linkObjs.length;
          linkObjs.each(function (idx, link) {
            var a = $(link);
            if (a.hasClass('linkActive')) {
              var nextObj = $(linkObjs[idx + 1]);
              nextObj.focus();
              obj.removeClass('linkActive');
              return;
            }
          });
        }

      } else {
        RT.log('else...', this);
        JEGS.nav.desktop.dropdownExit(e);
        JEGS.nav.desktop.setFocusToFirstItem(e, obj);
      }
    },
    setFocusToLastItem: function (e, obj) {
      var lastObj = obj.closest('ul').children('li:last-child').find('> a');
      if (lastObj.length > 0) {
        lastObj.focus()
      }
    },
    setFocusToFirstItem: function (e, obj) {
      var firstObj = obj.closest('ul').children('li:first-child').find('> a');
      if (firstObj.length > 0) {
        firstObj.focus()
      }
    },
    setFocusToSubItem: function (e, obj) {
      var subObjContainer = obj.next();
          subObjContainer.find('a').not('.noTab').attr('tabindex', 1);
      var subObj = subObjContainer.find('[tabindex=1]').first();
      RT.log('subObj len: ' + subObj.length, this);
      if (subObj.length > 0) {
        RT.log(subObj.html(), this);
        subObj.focus();
        obj.addClass('active');
      }
    },
    setFocusToParentItem: function (e, obj) {
      var parentObj = obj.closest('div').prev();
      RT.log('parentObj len: ' + parentObj.length, this);
      if (parentObj.length === 0) {
        RT.log('no parent', this); // at top of categories links
        JEGS.nav.desktop.dropdownExit(e);
        $('#departments button').focus();
      }
      parentObj.focus();
    },
    dropdownHover: function () {
      JEGS.nav.vars.isHovered = 1
    },
    dropdownExit: function (e) {
      JEGS.nav.vars.isHovered = 0;
      if (RT.vars.newnav) {
        $('nav .active').removeClass('active');
        $('nav a:active, nav a:focus').blur();
      } else {
        $('.dropdown.active').removeClass('active'); // TODO: remove
      }
    },
    setInActive: function () {
      var lastHovered = JEGS.nav.vars.lastHovered;
      if (lastHovered) {
        lastHovered.removeClass('active');
      }
    },
    handleXYdiff: function (e, linkObj) {
      /*  allow for diagonal mouse events to not trigger "active" hover 
          in order to mousemove to the .dropdown-content object */
      var x = e.clientX, y = e.clientY;
      var diffX = Math.abs(RT.vars.mouse.x - x);
      var diffY = Math.abs(RT.vars.mouse.y - y);
      if (diffX < 2 && diffY < 15) {
        RT.log('set linkHoverMove active', this);
        JEGS.nav.desktop.setHoveredActive(e, linkObj);
      }
    },
    setHoveredActive: function (e, linkObj, subLink) {
      RT.log('setHoveredActive...', this);

      // remove active from last element
      var lastHovered = JEGS.nav.vars.lastHovered;
      if (lastHovered) {
        lastHovered.removeClass('active')
        
        // update the tabindex's
        var lastSubLevel = lastHovered.find('+ div');
        var anchors = lastSubLevel.find('a');
        RT.ui.setFocusableSection(lastSubLevel, anchors, 0);
      };
      // set our active state
      JEGS.nav.vars.isHovered = 1;
      if (RT.vars.newnav) {

        var retBGstyle = function (imgURL) {
          var staticURL = RT.vars.staticDomain + imgURL,
              style = ' style="background-image:url(\'' + staticURL + '\');"';
          return style;
        };

        if (!linkObj) { // focus is in main-nav
          return false;
        };
        JEGS.nav.desktop.buildFlyoutFromStorage(linkObj);//Builds flyout for the top category on hover
        var subLevel = linkObj.next();
// TODO: put this in it's own function call
        if (!subLevel.hasClass('rendered')) {
          RT.log('render div...', this);
          // header image & text
          var bgImg = subLevel.attr('bgimg'), 
              title = linkObj.text(),
              allURL = linkObj.attr('href'),
              id = linkObj.attr('category'),
              headerTitle = '<h4>' + title + '</h4>',
              headLink = $('<a class="headLink" href="' + allURL + '" id="btnHeadLink_' + id + '">' + headerTitle + '</a>'),
              moreLink = $('<a class="moreLink" href="' + allURL + '" id="btnMore_' + id + '"><span>+ View All ' + title + '</span></a>');

          subLevel.addClass('rendered').removeAttr('bgimg');
          headLink.prependTo(subLevel);
          // main section images / format
          var mainLIs = subLevel.find('[pos=main] li');
          mainLIs.each(function (idx, li) {
            var liObj = $(li), 
                aObj = liObj.find('a'), 
                imgURL = aObj.attr('img'), 
                imgObj = $('<span class=img ' + retBGstyle(imgURL) + '></span>');
            aObj.contents()
            .filter(function() {
              return this.nodeType === 3;
            })
            .wrap('<span/>')
            .end();
            aObj.prepend(imgObj);
          });
          moreLink.keydown(JEGS.nav.desktop.linkKeyPress);
          subLevel.append(moreLink);
        };
/////////////////////
        // update the tabindex's
        var anchors = subLevel.find('a').not('.noTab');
        RT.ui.setFocusableSection(subLevel, anchors, 1);
      };
      
      linkObj.addClass('active');
      JEGS.nav.vars.lastHovered = linkObj;

      if (subLink) {
        subLevel.find('a').first().focus();
      }
      // must be visible via "active" class
      JEGS.nav.desktop.positionFlyout(linkObj);
    },
    positionFlyout: function (linkObj) {
      
      var top = $(linkObj).offset().top - $(window).scrollTop(); // Calculates the position of the category relative to view port. 
      var height = $(window).height(); //Window Height. Always remains constant for a given resolution.
      var diff = height - top ;  // Distance of the category from the bottom of the window (viewport)

       //Fixed Positioned. Hence the top will be calculated relative to view port.
      if (RT.vars.newnav) {
        var flyout = $(linkObj).next();
        var flyoutHeight = flyout.height();
        RT.log(flyout, this);
        RT.log('flyoutHeight: ' + flyoutHeight, this);

      } else { // TODO: remove
        var flyout = $(linkObj).find('.dropdown-content');
        var flyoutHeight = $(linkObj).find('.flyout').height();
      };
      
      if (diff < flyoutHeight) { // When hovered on the category which is close to bottom of the window, the footer of flyout will be inline to top category text.
        var diff2 = height - (top - (flyoutHeight - top)); // Recalculate the distance to the bottom after adjusting the top.		
        if (top < flyoutHeight && (flyoutHeight > diff2 || top - (flyoutHeight - top) < 0)) // Set the top to 0, if the flyout height is relatively lot bigger.
          $(flyout).css('top', '0px');
        else if (top < flyoutHeight) 
          $(flyout).css('top', (top- (flyoutHeight - top)+20) + 'px');
        else
          $(flyout).css('top', top- (flyoutHeight - diff) + 'px');
      } else {
        $(flyout).css('top', (top-20)+'px');
      }
    },
    
    buildFlyoutFromStorage: function(linkObj){ // constructs desktop flyout if not already built
    	var flyout = $(linkObj).next(); // flyouts
    	if(flyout.length == 0){
			var category = $(linkObj).attr('category');
			var sectionType = $(linkObj).parents("ul").attr('level');
			if(RT.vars.hasStorage){
	    		var strNav = localStorage.getItem("nav");
	    		if (strNav != null) {
	    	        var arrayJSON = $.parseJSON(strNav);
	    	        var data;
	    	        if(sectionType == 'top-category'){
	    	        	data = arrayJSON.top.links[category];
	    	        }else{
	    	        	data = arrayJSON.all.links[category];
	    	        }	
    	        	var subDiv = $('<div id="' + data.category + '" category="' + data.category + '" bgImg="' + data.bgImg + '"></div>');
    	            var parentLinkObj = $(linkObj).parent();
    	            var subLinksMain = JEGS.nav.build.retSubSections(data.main, "main");
    	            var subLinksAside = JEGS.nav.build.retSubSections(data.aside, "aside");
    	            subLinksMain.appendTo(subDiv);
    	            subLinksAside.appendTo(subDiv);
    	            subDiv.appendTo(parentLinkObj);
    	            subDiv
    	            .on("mouseover", JEGS.nav.desktop.dropdownHover)
    	            .on("mouseleave", JEGS.nav.desktop.dropdownExit);
    	            subDiv.find('a').on("click", JEGS.nav.subMenuLinkClick);// To add YMM to sub category links after flyout is built
	    	        JEGS.nav.desktop.navDropdownPos();
	    	    }
	    		
		   }	
    	}
    },
    
    linkHover: function (e) {
      RT.log("linkHover...", this);
      var linkObj = JEGS.nav.desktop.retDIVobj(e);
      if (JEGS.nav.vars.isHovered == 0) {
        JEGS.nav.desktop.setHoveredActive(e, linkObj);
      }
    },
    linkHoverMove: function (e) {
      var linkObj = JEGS.nav.desktop.retDIVobj(e);
      if (JEGS.nav.vars.isHovered == 1) {
        setTimeout(function () {
          JEGS.nav.desktop.handleXYdiff(e, linkObj);
        }, 50)
      }
    },
    linkHoverExit: function (e) {
      RT.log("linkHoverExit...", this);
      var linkObj = JEGS.nav.desktop.retDIVobj(e);
      /* trigger the set timeout ONLY from element we are leaving that is "active"
          user may have a slow hand, so allow more time as needed below */
      if (JEGS.nav.vars.lastHovered == linkObj && JEGS.nav.vars.isHovered == 1) {
        JEGS.nav.vars.timer = setTimeout(function () {
          JEGS.nav.vars.isHovered = 0;
        }, 1250);
      }
    },
    linkBlur: function (e) {
      // handle closing a dropdown menu only if up/dn keys to a sibling link
      // if it's left or right, we need to keep "active" on the link 
      if (e.keyCode) {
        var keyCodes = RT.vars.keyCodes;
        switch (e.keyCode) {

          case keyCodes.UP || keyCodes.DOWN:
            RT.log("UP/DN", this);
            var linkObj = $(e.currentTarget);
            linkObj.removeClass('active');
            break;

          default:
            RT.log("DEF", this);
            break;          
        }
      }
    },
    closeNav: function () {
      var isNavOpen = $('#shop-by-category').hasClass('visible');
      $('#floating-top-nav').removeAttr('style');
      if (isNavOpen) {
        $('#floating-top-nav').removeAttr('style');
        JEGS.nav.toggleNav() // in JegsResponsive.js
      };
    }
  },
  mobile: {
    init: function () {
      console.log('nav mobile init......');
      var navParent = $('#shop-by-category');
      navParent.addClass('categories v01'); // remove version when CSS overrides not needed
      // navParent.attr('id','ShopByCategory'); // TODO: clean out old mobile CSS

      var m = JEGS.nav.mobile;
      // sm Jegs logo & Close [X]
      m.navMenuHeader(navParent);
      // TOP/ALL Categories +/-
      m.topLevelOpenClose(navParent);

      if (RT.vars.newnav) {
        m.topLevelNavLinks(navParent);// Builds both top category buttons and sub category links
      } 
      
      JEGS.nav.topLevelShopLinks(navParent);//Builds Shop By Brand,Deals,New Products Links
      // move all LI elems into a new cleaned up list
      m.submenusLinkLists(navParent);
      // show grey bg links
      m.showMobileNavHeaders(navParent);//Builds Shop By Vehicle Button
      // get rid of visible elements not used on mobile
      m.removeDesktopElems(navParent);
      // set onClick to open slider
      m.setupTransitions();

      m.setNavHeight();

      m.footerAccordions();
    },
    vars: {
      // re-used / shared variables between events in the NAV
      menuScrollPos: 0,
      lastFocusObj: $('#hamburger')
    },
    hamburgerMenu: function () {
      // see toggleNav() - TODO: redo the toggleNav & CSS
      if (RT.vars.pathname == '/' && $(window).width() >= 1024) {
        $('#departments > button').removeAttr('onclick');
        return false;
      };
      $('#hamburger, #departments > button').on('click', JEGS.nav.mobile.clickEvents.menu);
      $('.black-background').on('click', JEGS.nav.mobile.clickEvents.menuClose)
    },
    navMenuHeader: function (navParent) {
      $('#search-row').addClass('mobileNavPosition');
      var header = $('<section class="navMenuHeader">' +
        '<a href="/"><span class="logo"></span></a>' +
        '<button class="btn btnClose">' +
          '<span class="text screenReader">Close</span>' +
          '<span class="iconContainer">' +
            '<i class="fa fa-times" aria-hidden="true"></i>' +
          '</span>' +
        '</button>' +
      '</section>');
      header.prependTo(navParent);
      $('.navMenuHeader .btnClose').on('click', JEGS.nav.mobile.clickEvents.menuClose);
    },
    topLevelOpenClose: function (navParent) {
      RT.log("topLevelOpenClose...", this);
      if (RT.vars.newnav) {
        var categoriesDiv = navParent.find('.rootCategoriesLinks');
      } else {
        var categoriesDiv = navParent.find('#store-categories');  // TODO: remove
      };
      var categoriesSections = $('<section class="categorySections"/>');
      var topLevels = navParent.find('.top-categories, .all-categories, .category[type="top"], .category[type="all"]');  // TODO: remove "top-", "all-"
      topLevels.each(function (idx, section) {
        var sect = $(section);
        if (RT.vars.newnav) {
          var sectionName = sect.attr('type');
          var sectionId = sectionName + "categories";
        } else {  // TODO: remove
          var sectionId = sect.attr('class').replace("-","");
          var sectionName = sectionId;
        };
        var title = sect.find('.cat-section-header, .head').text();
        var content = sect.find('.scroll-pane, ul[level]').html();
        var data = {
          idx: idx,
          title: title,
          content: content,
          sectionId: sectionId,
          sectionName: sectionName
        };
        if (title == "") {
          return true;
        }
        var HTML = JEGS.nav.mobile.retTopLevelOpenCloseHTML(data);
        categoriesSections.append(HTML);
        // get rid of the old HTML
        sect.remove();
      });
      if (RT.vars.newnav) {
        categoriesSections.appendTo(categoriesDiv);
      } else { // TODO: remove
        categoriesSections.insertBefore(categoriesDiv);
      };
      $('.categoryButton').click(JEGS.nav.mobile.clickEvents.topLevelCategoryClick);
    },

    retTopLevelOpenCloseHTML: function (data) {
      RT.log(data, this);
      RT.log(data.sectionId, this);
      
      var opened = (data.sectionId == 'topcategories') ? 'opened' : '';
      var HTML = '<button id="btn' + data.sectionId + '" class="btn categoryButton ' + opened + '">' +  // was TopCategoriesMobile for both
      '<h3 class="header">' +
        '<span class="text">' + data.title + '</span>' +
        '<span class="iconContainer minus"><i class="fa fa-minus" aria-hidden="true"></i></span>' +
        '<span class="iconContainer plus"><i class="fa fa-plus" aria-hidden="true"></i></span>' +
      '</h3>' +
      '</button>' +
      '<section class="categorySection" id="' + data.sectionId + '">' + data.content + '</section>';
      return HTML;
    },

    // old nav
    // TODO: remove
    topLevelLinks: function (navParent) {
      // content in ".scroll-pane" was re-wrapped with ".categorySection" in topLevelOpenClose()
      var categoryDIVlinks = $('.categories .dropdown.category-name');
      categoryDIVlinks.each(function (idx, div) {
        var divObj = $(div);
        var category = divObj.attr('category');
        var anchor = divObj.find('> a');
        var button = $('<button/>')
        // update the content with SPANs
        anchor.contents()
          .filter(function() {
            return this.nodeType === 3;
          })
          .wrap('<span class=text/>')
          .end()
          .filter('.fa')
          .wrap('<span class=iconContainer/>');
        // create & update button attributes, remove HREF
        button.append(anchor.contents()).attr('category', category).addClass('categoryLink').insertBefore(divObj);
        anchor.remove();
      });
      $(".categoryLink").on('click', JEGS.nav.mobile.clickEvents.categoryLinkClick);
    },

    // newnav
    topLevelNavLinks: function (navParent) {
      var categoryLinks = $('.categorySection > li');
      categoryLinks.each(function (idx, li) {
        var liObj = $(li);
        var anchor = liObj.find('> a');
        var anchorText = anchor.text();
        var div = liObj.find('> div');
        var categoryid = anchor.attr('categoryid');
        var identifier = anchor.attr('category');
        var button = $('<button/>')
        // update the content with SPANs
        anchor.contents()
          .filter(function() {
            return this.nodeType === 3;
          })
          .wrap('<span class=text/>')
          .end();
        var angleRT = '<span class="iconContainer"><i id="arrow" class="fa fa-angle-right"></i></span>';
        // create & update button attributes, remove HREF
        button.append(anchor.contents())
        .append(angleRT).attr('category', categoryid)
        .attr('identifier', identifier)
        .addClass('categoryLink').insertBefore(liObj);
        if(!RT.vars.hasStorage){
        	var newLi = $('<li/>');
            //anchor.find('span').html("+ View All " + anchorText);           
            newLi.append(anchor);
            div.find('ul').last().append(newLi);
            div.addClass('submenu').insertBefore(liObj);
        }   
        liObj.remove();
      });
      $(".categoryLink").on('click', JEGS.nav.mobile.clickEvents.categoryLinkClick);
    },

    submenusLinkLists: function (navParent) {
      // extract the links from inside the drop-downs, apply a new list with new CSS parent...
      var submenus = $('.submenu');   // TODO: remove dropdown-content
      // RT.log("dropdowns length: " + dropdowns.length, this);
      submenus.each(function (idx, subMenu) {
        var submenu = $(subMenu);
        // RT.log(idx + "\n" + submenu.html(), this)
        var list = $('<ul/>');
        list.append('<li><button class="btn btnBack">' +
          '<span class="iconContainer"><i class="fa fa-angle-left"></i></span>' +
          '<span class=text> Back to Categories </span></button></li>');

        var links = submenu.find('a');
        // RT.log("links length: " + links.length, this);
        links.each(function (idx, link) {
          var li = $("<li/>");
          var anchor = $(link);
          var text = $.trim(anchor.text());
          anchor
            .attr("class","link subMenuLink")
            .html("<span class='text'>" + text + "</span>")
            .on("click", JEGS.nav.subMenuLinkClick)
          li.append(anchor);
          list.append(li);
        });
        var newSubMenu = $("<div>")
        // reformat the list container to lose the old layout
        newSubMenu.append(list).addClass('submenu').insertAfter(submenu);
        newSubMenu.unwrap('div');
        submenu.remove();
      });
      // Back button click event
      $('.submenu .btnBack').on('click', JEGS.nav.mobile.clickEvents.backButtonClick);
      // remove old flyout containers
      navParent.find('.flyout').remove();
    },
    
    showMobileNavHeaders: function (navParent) {
      // fix Shop By Veh display
      var sbVeh = navParent.find('.shop-by-vehicle-category');
      var target = navParent.find('nav');
      var sbVehParent = sbVeh.parent();
      var newSbVehBtn = $('<button class="btn btnShopByVehicle"><span class=text>Shop By Vehicle</span><span class="iconContainer"><i class="fa fa-tire" aria-hidden="true"></i></span></button>');
      newSbVehBtn.insertAfter(target);
      $('.btnShopByVehicle').on('click', JEGS.nav.mobile.clickEvents.shopByVehicle);
      sbVehParent.remove();
  
      // categories bottom section
      var mobileNav = navParent.find('.mobile-nav-section');
      var greyLinks = mobileNav.find('.category-name'); // these are DIV tags
      greyLinks.each(function (idx, div) {
        var d = $(div);
        var id = d.attr('id');
        var url = d.attr('onclick').split('href=');
        var anchor = $('<a/>');
        anchor.attr('href', url[1].replace(/\'/g,"")).attr('id', id).attr('class','link navLink greyBG').html(d.html());
        anchor.appendTo(mobileNav);
      });
      mobileNav.removeClass('mobile-nav-section').addClass('mobileNavHeaders');
      greyLinks.remove();
    },

    navPanelDisplay: function (e) {
      // view pane for Category navigation
      if (e) {
        var body = $('body');
        body.toggleClass('navOpen');
        if (body.hasClass('navOpen')) {
          $('.categories .btnClose').focus()
        }
      }
      JEGS.nav.mobile.setNavHeight();
    },
    subMenuOpen: function (e) {
      RT.log("subMenuOpen", this);
      // save position of where we are
      var categObj = $('.categories')
      var scrollTop = categObj.scrollTop();
      JEGS.nav.mobile.vars.scrollTop = scrollTop;
      // turn off any active links from previous submenu open
      $('.categoryLink.active').removeClass('active');
      var clicked = $(e.currentTarget);
          clicked.addClass('opened active');
      categObj.animate({scrollTop: 0}, 100);

      var categObjH = categObj.height();
      var height = $('.categoryLink.opened + .submenu').height();
      if (height < categObjH) {
        categObj.css('overflow-y','hidden');
      } else {
        JEGS.nav.mobile.setNavHeight();
      }
      var focusTarget = $(e.currentTarget).next().find('.btnBack');
      JEGS.nav.mobile.menuTransition(e, focusTarget, true);
    },
    setNavHeight: function () {
      var win = $(window),
          w = win.width(),
          x = (w > 1024 ? -1 : 0), 
          headerInner = $('.header-inner'),
          headerWrap = $('.header-wrap'),
          banner = $('#stickyBanner'),
          screenH = document.documentElement.clientHeight;

      // get the height of any header objects opened/visible and subtract from whole window height
      var height = screenH - ( x === 0 ? 0 : headerWrap.height() + banner.height() );
      // RT.log(`navHeight: ${height}`, this);

      var categObj = $('.categories');
      categObj.height(height)
        .css('overflow-y','auto')
        .css('overflow-x','hidden');
    },
    removeDesktopElems: function (navParent) {
      // remove headers & such from flyout menus we don't need for mobile
      var elems = navParent.find('.flyout__header, .flyout__list-item-image-wrap, .overlay-element, .clearBoth'); // .flyout
      elems.remove();
    },
    setupTransitions: function () {
      RT.log("setupTransitions", this);
      // clip is used for animating Left/Right w/in opened Categories nav
      var clipObj = $('.clip');
      if (clipObj.length === 0) {
        var clip = $('<section class="clip"/>');
        var categObj = $('.categories');
        categObj.children().wrapAll(clip);
      }
    },
    menuTransition: function (e, focusTarget, TF) {
      // use open and close (LEFT 0 to -100%) CSS transitions
      if (TF) {
        var btns = ['closed','opening','opened',0];
      } else {
        var scrollTop = JEGS.nav.mobile.vars.scrollTop;
        var btns = ['opened','closing','closed',scrollTop];
      }
      var categObj = $('.categories');
      categObj.removeClass(btns[0]).addClass(btns[1])
      setTimeout(function () {
        categObj
          .addClass(btns[2])
          .removeClass(btns[1])
          .animate({scrollTop: btns[3]}, 500);
        focusTarget.focus();
      }, 500);
    },

    clickEvents: {
      menu: function (e) {
        RT.log('menu click event', this);
        JEGS.nav.vars.scrollTop = $(window).scrollTop();
        console.log(JEGS.nav.vars.scrollTop);

        if ( $("#header-wrap").hasClass("lock-header") ) {
          JEGS.nav.vars.lockHeader = true;
        } else {
          delete JEGS.nav.vars.lockHeader;
        }

        JEGS.nav.mobile.lastFocusObj = $(e.currentTarget);
        JEGS.nav.mobile.navPanelDisplay(e);
      },
      menuClose: function (e) {
        $('body').removeClass('navOpen');
        JEGS.header.hideMobileNav(); // TODO: redo this fxn
        var focusTarget = JEGS.nav.mobile.lastFocusObj; // $('#hamburger');
        JEGS.nav.mobile.menuTransition(e, focusTarget, false);

        $(window).animate({scrollTop: JEGS.nav.vars.scrollTop}, 1);

        if ( JEGS.nav.vars.lockHeader ) {
          $("#header-wrap").addClass("lock-header");
        };

        delete JEGS.nav.vars.lockHeader;

      },
      shopByVehicle: function (e) {
        RT.log("shopByVehicle click", this);
        JEGS.header.showVehicleSearch();
        // close the rest of the menu
        $('body').removeClass('navOpen');
        var focusTarget = $('#close-ymm');
        JEGS.nav.mobile.menuTransition(e, focusTarget, false);
      },
      topLevelCategoryClick: function (e) {
        var clicked = $(e.currentTarget);
        clicked.toggleClass('opened');
      },
      categoryLinkClick: function (e) {
        // console.log("categoryLinkClick")
    	JEGS.nav.mobile.clickEvents.buildSubMenuForTopCategory(e);
    	JEGS.nav.mobile.subMenuOpen(e);
        JEGS.nav.mobile.lastFocusObj = $(e.currentTarget);
        e.stopPropagation();
        e.preventDefault();
        // save the category
        var category = $(e.currentTarget).attr("category");
        RT.fxns.cookie.set("category", category, 1);
      },
      
      buildSubMenuForTopCategory: function(e){// Runs only after local storage has nav JSON.
    	 var linkObj = e.currentTarget; 
      	 var flyout = $(linkObj).next('.submenu');
      	 if(flyout.length == 0){
  			var category = $(linkObj).attr('identifier');
  			var sectionType = $(linkObj).parents("section.categorySection").attr('id');
  			if(RT.vars.hasStorage){
  	    		var strNav = localStorage.getItem("nav");
  	    		if (strNav != null) {
  	    	        var arrayJSON = $.parseJSON(strNav);
  	    	        var data;
  	    	        var subLinksMain;
  	    	        var subLinksAside;
  	    	        if(sectionType == 'topcategories'){
  	    	        	data = arrayJSON.top.links[category];
  	    	        }else{
  	    	        	data = arrayJSON.all.links[category];
  	    	        }
  	    	    subLinksMain = JEGS.nav.build.retSubSections(data.main, "main");
  	    	    subLinksAside = JEGS.nav.build.retSubSections(data.aside, "aside");
  	    	    subLinksAside.append($("<li/>").append(
  	    	          $("<a/>").attr('href',data.categoryURL).attr('id',data.categoryId).html("+ View All " + data.categoryText)
  	    	        ));
    	        var subDiv = $('<div id= "' + data.category + '"category="' + data.category + '"></div>');
    	        subLinksMain.appendTo(subDiv);
    	        subLinksAside.appendTo(subDiv);
  	            subDiv.addClass('submenu').insertAfter(linkObj);
  	            JEGS.nav.mobile.submenusLinkLists($('#shop-by-category'));
  	        }
  	      }	
      	}  
      },
      
      topLevelShopLinkClick: function (e) {
        e.stopPropagation();
        e.preventDefault();
        var url = $(e.currentTarget).attr('href');
        // add Year, Make, Model, etc params
        var qs = JEGS.vehicles.qs;
        if (qs != '') {
          url += '?' + qs
        };
        RT.url.loc(url);
      },
      backButtonClick: function (e) {
        $('.categoryLink.opened').removeClass('opened');
        JEGS.nav.mobile.setNavHeight();
        var focusTarget = JEGS.nav.mobile.lastFocusObj;
        JEGS.nav.mobile.menuTransition(e, focusTarget, false);
      }
    },
    footerAccordions: function () {
      RT.log('footerAccordions...', this);
    }

  },
  toggleNav : function() {

	 if ($("#shop-by-category").hasClass("showLeftNav") && !$("#header-wrap").hasClass("lock-header") && $(window).width() > RT.vars.bp.lg) {
			return;
	  }

	  //New Classes for sliding menu
	  if ($("#shop-by-category").hasClass("visible")) {

			//Menu Hide
			$("#shop-by-category").removeClass("visible");

			//Changes Arrow from Open to Close
			$("#menu-arrow").removeClass("fa-caret-down");
			$("#menu-arrow").addClass("fa-caret-right");

			//Overlay Hide
			$("#main-menu-overlay").removeClass("visible");
			$(".dropdown.active").removeClass("active");

			//Vehicle-Search Hide
			$("#vehicle-search").removeClass("visible");
			$("#vehicle-search-overlay").removeClass("visible");
			if($("#header-wrap").hasClass("lock-header")){
				$("body").removeClass("scrollLock");
			}

		} else {
			var navParent = $("#shop-by-category");

			if($(window).width() <= RT.vars.bp.md) {
				//$("#header-wrap #shop-by-category").detach().insertAfter("#header-wrap");
				navParent.css({"height":"500px","overflow-y": "auto" });			
			}
			//Menu Show
			navParent.addClass("visible");

			if (RT.vars.page == '/' && !RT.vars.touch) { 
				// "home" page is set to 1400px width, adjust the LEFT position and dropdown width
				var floatingNav = $('#header-wrap');
				var floaterW = floatingNav.outerWidth(); // - 40;
				var navLT = floatingNav.offset().left - 20;
				$('#floating-top-nav').css("left", navLT + 20);

				if (RT.vars.newnav) {
					var navW = navParent.find('nav').outerWidth();

				} else {
					var navW = navParent.outerWidth();

				};

				var dropDownWidth = floaterW - navW + 20;
				// console.log('navW: ' + navW + "  floaterW: " + floaterW + "  dropDownW: " + dropDownWidth)

				navParent.attr("style", "left:"+ navLT + "px !important;");
				var subLevel = $('.dropdown-content, nav a[category] + div');
				var dropDownLeft = parseInt(navLT) + parseInt(navW) - 20;
				// also see nav.js : navDropdownPos
				subLevel
					.css('left', dropDownLeft)
					.css('width', dropDownWidth);


			}

			//Changes Arrow from Closed to Open
			$("#menu-arrow").removeClass("fa-caret-right");
			$("#menu-arrow").addClass("fa-caret-down");

			//Overlay Show
			$("#main-menu-overlay").addClass("visible");

			//Vehicle-Search Hide
			$("#vehicle-search").removeClass("visible");
			$("#vehicle-search-overlay").removeClass("visible");

			if ($("#header-wrap").hasClass("lock-header")) {
				$("body").addClass("scrollLock");
			}
		}

	},
	
	subMenuLinkClick: function (e) {
        e.stopPropagation();
        e.preventDefault();
        var obj = $(e.currentTarget);
        var id = obj.attr('id');
        RT.log("subMenuLinkClick: " + id, this);
        JEGS.nav.subCatClick(id);
        var href = obj.attr('href');
        RT.url.loc(href);
    },
    
	subCatClick: function(catId) {
		var catLink = document.getElementById(catId).href;
		var sbvParam = 'sbv=true';
		var year = $('#keyword-search-form #year');
		var make = $('#keyword-search-form #make');
		var model = $('#keyword-search-form #model');
		var submodel = $('#keyword-search-form #submodel');
		var engine = $('#keyword-search-form #engine');
		if(year.length > 0 && year.val()!= '' && make.length > 0 && make.val()!= '' && model.length > 0 && model.val()!= ''){			
			catLink = catLink + '?year='+year.val() + '&make='+make.val() + '&model='+model.val();
			if(submodel.length > 0 && submodel.val()!= '')
				catLink = catLink + '&submodel='+ submodel.val();
			if(engine.length > 0 && engine.val()!= '')
				catLink = catLink + '&engine='+ engine.val();
			if(document.location.href.indexOf(sbvParam) != -1)
				catLink = catLink + '&' + sbvParam;
		}
		var itemPerPage =  RT.fxns.cookie.get('ITEMS_PERPAGE');
		if(itemPerPage != null && itemPerPage != '' && catLink.indexOf('Nrpp') == -1 ) {
			//add the itemsperpage parameter to the category url to fetch the non-cached version.
			if(catLink.indexOf('?')!=-1){
				catLink = catLink + '&Nrpp=' + itemPerPage;
			} else {
				catLink = catLink + '?Nrpp=' + itemPerPage;
			}
		}
		
		document.getElementById(catId).href = catLink;
		return true;
	}
	
};

var RT = RT || {} // runtime
var READY = READY || {} // doc ready
var DOMREADY = DOMREADY || {} // for standalone scripts that are dependent on main script
var LOAD = LOAD || {} // window load
var AFTERLOAD = AFTERLOAD || {} // 500ms after window load events are all called
var RESIZE = RESIZE || {} // scroll fxns
var SCROLL = SCROLL || {} // resize fxns 
var HASHCHANGE = HASHCHANGE || {} // *future use - UI/AJAX updates based on HASH change
var AJAX = AJAX || { SUCCESS: {} } // AJAX module loading functions
var JEGS = JEGS || {} // core app modules

/*
application loader executes init functions for:
"RT" (runtime) - browser, URL & data specific functions
"READY" - all init() fxns to be fired on "document ready"
"LOAD" - all init() fxns to be fired on "window load"
"afterLOAD" - all fxns to be run AFTER page is ready "3rd party scripts" - not used yet
*/
$(document).ready( function () {
  RT.isREADY.init(); // also: calls READY_modules.init
});
// all assets / requests complete and rendered
$(window).on('load', function () {
  /* ex: use for image lazy-load events and "afterLoad" of third party JS */
  RT.isLOADED.init();
});
$(window).on("scroll", function () {
  RT.isSCROLLED.init();
});
$(window).on('hashchange',function(){ 
  RT.isHASHCHANGED.init();
});
// detect when content is updated via AJAX and trigger specific events
$(document).ajaxSuccess(function (event, xhr, options) {
	RT.isAJAX.success(event, xhr, options)
});

// DELAY onResize functions until resize event(s) are actually complete
var resizingEvent = false;
$(window).resize(function () {
  if (resizingEvent !== false) {
    clearTimeout(resizingEvent)
  }
  resizingEvent = setTimeout(function () {
    // RT.log("window 'resizingEvent' : done resizing -> call resizeEventFxns", 0);
    RT.isRESIZED.init();
    setTimeout(function () {
      resizingEvent = false
    }, 250);
  }, 250);
});
// anything that has to do with image manipulation should be put in this module

// wait for LOAD event to finish instead of waiting for READY events
READY.images = {
  init: function () {
    RT.log("READY images...", 0);
    JEGS.images.ready();
  }
};
LOAD.images = {
  init: function () {
    RT.log("LOAD images...", 0);
    JEGS.images.load();
  }
};
RESIZE.images = {
  init: function () {
    RT.log("RESIZE images...", 0);
    JEGS.images.resize();
  }
};
SCROLL.images = {
  init: function () {
    RT.log("SCROLL images...", 0);
    JEGS.images.scroll();
  }
};

JEGS.images = {
  logger: 0,
  ready: function () {
	JEGS.images.fitImagesForIE('src');
	JEGS.images.productResults(true); // show spinner HTML
  },
  load: function () {
    JEGS.images.productResults(true); // show those in view
  },
  resize: function () {
    JEGS.images.productResults(true);
  },
  scroll: function () {
    JEGS.images.productResults(true);
  },
  productResults: function (show) {
    // TODO: remove 'hp-image' from SCSS ()
    // This adds loading spinner to the DIV and loads IMG tag in its place when in view
    var imgDivObjs = $('.dynImgLoad .resultBgImg');
    // RT.log("images.productResults..." + imgDivObjs.length, this); 
    if (imgDivObjs.length > 0) {
      if (!show) {
        imgDivObjs.each(function (idx, img) {
          $(img).html(RT.ui.spinner);
        });
      } else if (show) {
        RT.log("show .resultBgImg", this);
        var mobile = null;
        if (RT.vars.useGIFs) { mobile = false };
        imgDivObjs.each(function (idx, img) {
          var imgObj = $(img), parent = $(imgObj.closest('.dynImgLoad'));
          if (parent.length > 0 && RT.ui.isScrolledIntoView(parent, 200)) {
        	  	var title = imgObj.attr('data-title');
        	  	if(title == 'BR-Pixel'){
        	  		JEGS.images.pushBrWidgetPixel(imgObj.attr('data-wrid'),imgObj.attr('data-wid'),imgObj.attr('data-wty'),imgObj.attr('data-wq'));
        	  		imgObj.remove();
        	  	}else{
	            	var imgPath = imgObj.attr('data-img');
		            var id = imgObj.attr('id');
		            var alt = imgObj.attr('data-alt');
		            var impression = imgObj.attr('data-impression');
		            var dataRel = imgObj.attr('data-rel');
		            if (mobile && dataRel != 'ProductCarousel' && !parent.hasClass('slick-element') && !imgObj.hasClass('noImage')) { imgPath = imgPath.replace(".jpg", ".gif") } // TODO: fix for ProductSpotlightMain
		            var imgSrc = "url('" + imgPath + "')";
		            RT.log('add: ' + imgSrc, this);
		            var imgTag = $("<img src='"+ imgPath +"' id='"+ id +"' title='"+ title +"' alt='"+ alt +"' />");
		            imgTag.insertAfter(imgObj);
		            imgObj.remove();
		            if (impression) {
		              var args = impression.split(',');
		              // RT.log(args, this);
					  if (typeof GetImpression == 'function') {
						GetImpression.apply(null, args);
					  }
		            }
		         }
	         }
        });
      }
    }
  },
  
  	pushBrWidgetPixel : function(wrid, wid, wty, wq){
	  	var brWidgetData = {};
	  	brWidgetData.wrid = wrid;
	  	brWidgetData.wid = wid;
	  	brWidgetData.wty = wty;
	  	if(wq.trim().length > 0){
	  		brWidgetData.wq = wq;
	  	}
	  	brWidgets.push(brWidgetData);
	  	BrTrk.getTracker().logEvent('widget','widget-view',brWidgetData,true);
  	},
  
  updateCarouselMedia : function(className){
	  if($('.'+className).length > 0 ){
		 var images = $('.'+ className + ' img');
		 images.each(function (idx, img) {
			 var imgObj = $(img);
			 var imgLazyAttr = imgObj.attr('data-lazy');
			 if(imgLazyAttr != undefined){
			     var replaceStr = '-xl.jpg';
				 if($(window).width() <= RT.vars.bp.xs) {
					 imgLazyAttr = imgLazyAttr.replace(replaceStr,'-xs.jpg'); 
				 }else if($(window).width() <= RT.vars.bp.sm){
					 imgLazyAttr = imgLazyAttr.replace(replaceStr,'-xs.jpg');
				 }else if($(window).width() <= RT.vars.bp.md){
					 imgLazyAttr = imgLazyAttr.replace(replaceStr,'-md.jpg');
				 }else if($(window).width() <= RT.vars.bp.lg){
					 imgLazyAttr = imgLazyAttr.replace(replaceStr,'-lg.jpg');
				 }
				 imgObj.attr('data-lazy',imgLazyAttr);
			 }
		 });
		 JEGS.images.fitImagesForIE('data-lazy');
	  }
  },
  fitImagesForIE : function(imgAttr){
	  if (RT.vars.ieVersion > 0) { 
       $('.jegs-image,.fh-image,.image').each(function () {
			var $container = $(this),
			    imgUrl = $container.find('img').attr(imgAttr);
			if (imgUrl) {
				$container.css('backgroundImage', 'url(' + imgUrl + ')');
				$container.addClass('ie-object-fit');
			}  
		});
	  }
  }
};


READY.modal = {
	init: function () {
		JEGS.modal.ready();
	}
};

JEGS.modal = {
	identifier: '',	
	ready: function () {
		JEGS.modal.overlay();
		JEGS.modal.clickEvents();
	},
	clickEvents: function () {
		// this assumes there's only one modal open at a time
		$('body').on('click', '.modal .fxnClose, .modalOverlay', JEGS.modal.close);
		$('body').on('click', '.modalLink', JEGS.modal.popStaticContent.init);
	},
	open: function (selector) {
		JEGS.modal.identifier = selector;
		$('body').addClass('modalOpen scrollLock');
		$(selector).addClass('active');	
	},
	close: function (e) {
		RT.ui.setFocusableSection('.modal', null, 0);
		$('body').removeClass('modalOpen scrollLock');
		$(JEGS.modal.identifier).removeClass('active');
		RT.vars.lastFocusObj.focus();
	},
	overlay: function () {
		$('body').append('<section class="modalOverlay"/>');
	},
	retContainer: function (data) {  
		var btnCount = 0;
		var _data = {
			title: "",
			cancel: "Cancel",
			submit: "Submit",
			content: ""
		}
		if (!data) {data = _data};

		var btnSubmit = '', btnCancel = '', content = '';
		if (data.submit) {
			btnCount++;
			btnSubmit = '<div><button class="btn btnSubmit">' + data.submit + '</button></div></div>' // disabled="disabled"
		};
		if (data.cancel) {
			btnCount++;
			btnCancel = '<div><button class="btn btnCancel fxnClose">' + data.cancel + '</button></div>'
		};
		if (data.content) {
			content = data.content;
		};
		// TODO: add logic for passing data in
		var modal = $('<section class="modal v01">' +
		'<div class="modalContentContainer">' +
		'<div class="header"><span class="logo"/>' +
		'<h2 class="title"><span>' + data.title + '</span></h2>' +
		'<div class="success hide"></div>' +
		'<span class="processing hide"></span>' +
		'<button class="btn btnClose fxnClose">' +
		'<span class="text screenReader">Close</span>' +
		'<span class="iconContainer">' +
			'<i class="fa fa-times" aria-hidden="true"></i>' +
		'</span></button></div>' +
		'<div class="content">' + content + '</div>' + // <div class="testContent"></div>
		'<div class="footer btns-' + btnCount + '">' + btnCancel + btnSubmit + '</div></section>');
		return modal;
	},
	
	popStaticContent: {
		modalTagId: null,
		modalTagIndex: 0,
		url: null,
		event: null,
		init: function(e){
		   var modalTagId = $(e.currentTarget).data('modal-id');
		   var url = $(e.currentTarget).attr('href');
		   JEGS.modal.popStaticContent.url = url;
		   JEGS.modal.popStaticContent.event = e;
		   if(!modalTagId && url){
			   RT.ajax.send(url,JEGS.header.showLoaderSpinner,JEGS.modal.popStaticContent.showData,JEGS.modal.popStaticContent.showError);
			   modalTagId = $(e.currentTarget).data('modal-id');
		   }
		   if(modalTagId && url && modalTagId != -1){
			   e.preventDefault();
			   RT.fxns.setLastFocus($(this));
			   JEGS.modal.open("#"+modalTagId);
			   $('#' + modalTagId + ' .btnClose').focus();
		   }
	   },
	    showData : function(results, status, xhr){
		   var modalTagId;
		   if(xhr.getResponseHeader("Content-Type").indexOf("image") != -1 || xhr.getResponseHeader("Content-Type").indexOf("text/html") != -1) {
			   JEGS.modal.popStaticContent.event.preventDefault();
			   var modalContainer = JEGS.modal.retContainer();
			   JEGS.modal.popStaticContent.modalTagIndex = JEGS.modal.popStaticContent.modalTagIndex + 1;
			   modalTagId = 'sc-modal-' + JEGS.modal.popStaticContent.modalTagIndex;
			   $(JEGS.modal.popStaticContent.event.currentTarget).data('modal-id',modalTagId);
			   $(modalContainer).addClass('noHeaderFooter').appendTo($('body'));
			   $(modalContainer).attr('id',modalTagId);
		   }
		   if(xhr.getResponseHeader("Content-Type").indexOf("image") != -1) { // For Images Media
			   var imgHtml = $('<img/>', {"src": JEGS.modal.popStaticContent.url});
			   $('#' + modalTagId + ' .content').append($(imgHtml));
			}else if(xhr.getResponseHeader("Content-Type").indexOf("text/html") != -1){ // For HTML Media
				$('#' + modalTagId + ' .content').html(results);
			}else{
				$(JEGS.modal.popStaticContent.element).data('modal-id',-1);
			}
	    	JEGS.header.removeLoaderSpinner();
	    },
	    showError : function(){
	    	JEGS.header.removeLoaderSpinner();
	    }
	}
};


//////////////////////////////////////////////////////////
//
//This function is used open a pop-up window
//
//////////////////////////////////////////////////////////
function open_window(url) {
if (url.substring(0,1) != '/'){
url = "http://" + location.host + "/webapp/wcs/stores/servlet/" + url
} else{
url = "http://" + location.host + url
}   
itemimage = window.open(url,"ItemImage", "toolbar=no,location=0,directories=0,status=0,menubar=0,scrollbars=yes,resizable=yes,width=360,height=350");  
itemimage.focus();   
}

function open_custom(url, width, height) {
if (url.substring(0,1) != '/'){
url = "http://" + location.host + "/webapp/wcs/stores/servlet/" + url
} else{
url = "http://" + location.host + url
} 
notes = window.open(url,"notes", "toolbar=no,location=0,directories=0,status=0,menubar=0,scrollbars=yes,resizable=yes,width=" + width + ",height=" + height);  
notes.focus();   
}

function open_htmlpopup(htmlcode, width, height) { 
var int_windowLeft = (screen.width - width) / 2;
var int_windowTop = (screen.height - height) / 2;

notes = window.open('',"JEGS", "toolbar=0,location=0,status=0,menubar=0,scrollbars=1,resizable=1,width=" + width + ",height=" + height + ",top=" + int_windowTop + ",left=" + int_windowLeft);  
notes.document.write(
'<html><head><title>JEGS</title></head>'
+'<body bgcolor=white onLoad="self.focus()">'
+htmlcode
+'</body></html>'); 
notes.document.close();
}

function open_htmlpopup_man_avil_unknown_nosim(htmlcode, width, height) { 

//alert("No similar items");
var htmlcode="This item is currently on backorder with no eta date from the manufacturer. You may place an order for this item on line or call 1-800-345-4545 and speak with one of our technical sales representatives to discuss your best options!<br>(Option 1 for sales, then option 2 for a technical sales rep)";

var int_windowLeft = (screen.width - width) / 2;
var int_windowTop = (screen.height - height) / 2;

notes = window.open('',"JEGS", "toolbar=0,location=0,status=0,menubar=0,scrollbars=1,resizable=1,width=" + width + ",height=" + height + ",top=" + int_windowTop + ",left=" + int_windowLeft);  
notes.document.write(
'<html><head><title>JEGS</title></head>'
+'<body bgcolor=white onLoad="self.focus()">'
+htmlcode
+'</body></html>'); 
notes.document.close();
}

function open_htmlpopup_man_avil_unknown_sim(htmlcode, width, height) { 
//alert("No similar items");
var htmlcode="This item is currently on backorder with no eta date from the manufacturer. You may place an order for this item or shop similar items listed below on line or call<br>1-800-345-4545 and speak with one of our technical sales representatives to discuss your best options!<br>(Option 2 for sales, then option 2 for a technical sales rep)";

var int_windowLeft = (screen.width - width) / 2;
var int_windowTop = (screen.height - height) / 2;

notes = window.open('',"JEGS", "toolbar=0,location=0,status=0,menubar=0,scrollbars=1,resizable=1,width=" + width + ",height=" + height + ",top=" + int_windowTop + ",left=" + int_windowLeft);  
notes.document.write(
'<html><head><title>JEGS</title></head>'
+'<body bgcolor=white onLoad="self.focus()">'
+htmlcode
+'</body></html>'); 
notes.document.close();
}
READY.checkoutButtons = {
  init: function () {
    JEGS.checkoutButtons.ready();
  }
};

JEGS.checkoutButtons = {
  logger: 1,
  
  afmDefault : false,
  
  ready: function () {
    
  },
  
  regular: {
   cartCheckOut: function(miniCartOrderId,shipmodId){
  	 ga('send', 'event', 'Checkout', 'Click', 'miniCartCheckout', {'nonInteraction': true}); 
  	 if(JEGS.checkoutButtons.afmDefault){
  		var cartURL ='/webapp/wcs/stores/servlet/JegsCheckoutCmd?orderId='+miniCartOrderId+"&calculationUsageId=-1&updatePrices=1&catalogId=10002&langId=-1&storeId=10001&selectedShippingOption="+shipmodId+"&fm=true";
  	 }else{
  	 var cartURL ='/webapp/wcs/stores/servlet/JegsCheckoutCmd?orderId='+miniCartOrderId+"&calculationUsageId=-1&updatePrices=1&catalogId=10002&langId=-1&storeId=10001&selectedShippingOption="+shipmodId;
  	 }
  	 location.href=cartURL;	
   },
   setAfmDefault : function(){
	   JEGS.checkoutButtons.afmDefault = true;
   }  
  },
  
  express: {
	  orderPlaced: false,
	  expressCartClick: function(){
		$(".expressCheckoutSummary").removeClass("displayNone");
		$(".nonExpressCheckoutSummary").addClass("displayNone");
	  },
	  expressCancelClick: function(){
		$(".expressCheckoutSummary").addClass("displayNone");
		$(".nonExpressCheckoutSummary").removeClass("displayNone");
	  },
	  expressCheckoutSubmit: function(){

			var shipModId = $("#shippingOptionsInfo input[name=shippingOption][checked]").val();
			if(shipModId != undefined ) {
				$("#shipModeId").val(shipModId.substring(0,5));		
			}
			$("#cc_cvc").css({'border': 'none'});
			var cvvRegex = /^[0-9]{3,4}$/; 
			var cvv = $("#cc_cvc").val();

			if(cvv.match(cvvRegex)!=null){
				$('#xprs_loader').show();
				$('#expressCheckout').submit();	  
				ga('send', 'event', 'Checkout', 'Click', 'Express Submit Order', {'nonInteraction': true});
				$('#x_checkoutSubmit').attr("disabled", "disabled");
				JEGS.checkoutButtons.express.orderPlaced=true;	
				
			} else {
				$("#cc_cvc").css({'border': '2px solid red'});
				return false;
			}
      }
  }
};

// Note: AFTERLOAD[module] may be added in any of the module files 
AFTERLOAD = {
		
  zendesk: {
    init: function () {
		if(JEGS.page.vars.isGenesysChat){
		var genesysChat = (function (g, e, n, es, ys) {
		    g['_genesysJs'] = e;
		    g[e] = g[e] || function () {
		      (g[e].q = g[e].q || []).push(arguments)
		    };
		    g[e].t = 1 * new Date();
		    g[e].c = es;
		    ys = document.createElement('script'); ys.async = 1; ys.src = n; ys.charset = 'utf-8'; document.head.appendChild(ys);
		  })(window, 'Genesys', 'https://apps.usw2.pure.cloud/genesys-bootstrap/genesys.min.js', {
		    environment: 'usw2',
		    deploymentId: '2e710a05-cdd2-4147-9ce1-a5a80ba6d56c'
		  });
		setTimeout(genesysChat,3000);
		}
    }
  },		
  paypal: {
    init: function () {
	  AFTERLOAD.paypal.showButtons(); 
    },
    showButtons: function(afterLoad){
    	if(JEGS.page.vars.isPayPalSDKEnabled == true){
    		if ($('#paypal-button').length > 0 || $('#PayPalCredit').length > 0) {
    			var env = $('#payPalEnv').val();
        		var scriptsArr = [
        		  env === 'production' 
        		    ? 'https://www.paypal.com/sdk/js?client-id=AaOx7XYrKWv7SsKA43_FQ0Ax0QRi9cbXmiOKNcIF9YE9t2eiUgf371FZIRuVJE2ndMx9iilMRHxa7NGh&intent=authorize&components=buttons,messages&enable-funding=paylater&disable-funding=venmo,card&commit=false'
        		    : 'https://sandbox.paypal.com/sdk/js?client-id=Abp-aqGwRUCXBiw3soYEnBIrhpqmr5dWmSDNMtI4P4bQOeTAAQ84e-XixspfXfx4hg_297ks-fSQiU0t&intent=authorize&components=buttons,messages&enable-funding=paylater&disable-funding=venmo,card&commit=false'
        		];
        		var callBack;
            	if(afterLoad != undefined){
            		callBack = afterLoad;
                }
            	else
            	   callBack = function () {
            		executePayPalSDK('cart',env);
                    if ($('#PayPalCredit').length > 0) {
                        paypal.Messages().render('#PayPalCredit');
                    }
                };
                RT.ajax.script(scriptsArr, callBack);
    		}
    	}else{
		     if ($('#paypal-button').length > 0) {	
		    	RT.log('afterload:paypal checkout min...', 1)
		        var scriptsArr = ["https://www.paypalobjects.com/api/checkout.min.js"];
		    	var callBack;
		    	if(afterLoad != undefined){
		    		callBack = afterLoad;
		        }
		    	else
		    	   callBack = function () {
		            var env = $('#payPalEnv').val();
		            executePayPal('cart',env);
		          };
		        RT.ajax.script(scriptsArr, callBack);
		    }   
		}
	}
  },
  applePay: {
    init: function () {
		  	if($('#applePayenable').val() == 'true'){
      if (RT.vars.touch && navigator.userAgent.indexOf("iPhone") != -1) {
        RT.log("afterload:applepay...", 1);
        var jsPath = RT.vars.staticDomain + RT.vars.ajaxPath;
        var scriptsArr = [jsPath + "JegsApplePay.js"]
        var callBack = function () {
			          if (window.ApplePaySession && ApplePaySession.canMakePayments) {
            $('#sc_applepay').append('<div id="ppc_applePay" class="apple-pay-button"><i id="x_loading_ap" class="fa fa-spinner fa-spin fa-3x fa-fw ap-loader"></i></div>');
            $('#ppc_applePay').on("click", applePayButtonClicked);
          }
        }
        RT.ajax.script(scriptsArr, callBack);
      }
    }
  		}
  },  

  maker: {
    init: function () {
      if (RT.vars.loadMaker) { // example:  /v/AEM-Induction/598
        RT.log("afterload:loadMaker...", 1);
        var data = {
		    	file: "maker.js",
          id: "maker"
        };
        RT.ajax.getScript(data);
      };
      //   
      if (RT.vars.loadMakerExternal) { // JEGS.page.vars.isPDP
        RT.log("afterload:maker-enhance...", 1);
        var data = {
          url: RT.vars.loadMakerExternal, // "//app.maker.co/enhance/jegs.js",
          id: "maker-external"
        };
        RT.ajax.getScript(data);
      }
    }
  },
  criteo: {
	init: function() {
	  
    } 
  },
  /*
   * Google Translator Feature Disabled. To enable this, un-comment the below code and code in JSP file FooterTile.jsp.
   */
  /*
  googleTranslator: { // Need not be required in checkout page.
	init: function () {  
	  setTimeout(function(){ 
	  RT.log('afterload:googleTranslator...', 1);
	  var data = {
          url: "//translate.google.com/translate_a/element.js?cb=AFTERLOAD.googleTranslator.callBack",
          id: "googleTrans"
      };
	  RT.ajax.getScript(data);
	  },2500);
	 },
     callBack: function(){
    	 new google.translate.TranslateElement(
    	     {pageLanguage: 'en',includedLanguages: 'de,es,fr,ja,nl,no,pt,ru,sv,zh-CN',
    		 layout: google.translate.TranslateElement.InlineLayout.SIMPLE,
    		 gaTrack: true, gaId: 'UA-204283-2'}, 'google_translate_element');
     }
  },*/
  bazaarvoice: {
    init: function () {
      // check if ANY of the BV function calls exist in the page, if so, then load the BV script and call it
      if ( (JEGS.page.vars.isPDP && $('#bazaarVoiceAPI').length === 0 && 
          typeof window.bvItem == 'function' || 
          typeof window.bvUIreviews == 'function' ||
          typeof window.bvUIratings == 'function' || 
          typeof window.bvSICall == 'function' || 
          typeof window.bvUICall == 'function') || JEGS.page.vars.isViewOrder ) {
        RT.log('call bazaarvoice scripts', 1)
        var scriptsArr = ["//display.ugc.bazaarvoice.com/static/jegs/en_US/bvapi.js"];
        var callBack = function () {
          RT.log('bazaarvoice API loaded', 1);
          if (typeof window.$BV == 'object') {
            if (typeof window.bvItem == 'function') {
              RT.log('window.bvItem exists', 1)
              window.bvItem();
            };
            if (typeof window.bvUIratings == 'function') {
              RT.log('window.bvUIratings exists', 1)
              window.bvUIratings();
            };
            if (typeof window.bvUIreviews == 'function') {
              RT.log('window.bvUIreviews exists', 1)
              window.bvUIreviews();
            };
            if (typeof window.bvSICall == 'function') { // ROIBeacon
              RT.log('window.bvSICall exists', 1)
              window.bvSICall();
            };
            if (typeof window.bvUICall == 'function') { // bv Q&A
              RT.log('window.bvUICall exists', 1)
              window.bvUICall();
            };
            if (typeof window.bvInlineRatings == 'object') { // Inline Ratings
                RT.log('window.bvInlineRatings exists', 1)
                window.bvInlineRatings.forEach(function (item, index) {
                	item.call();
                });
            }
            //view order Inline reviews
            if (JEGS.page.vars.isViewOrder) { // bv Q&A            	
            	window.inlineReview();
            }
          }
        };
        RT.ajax.script(scriptsArr, callBack);
      }
    }
  },

  recaptcha: {
    init: function () {
      if (RT.vars.captchaLoad) {
        RT.log("afterload:recpatcha...", 1);
        var queryString = "";
        var jsId = "google-recaptcha";
        if(RT.vars.captchaQS) queryString = "?" + RT.vars.captchaQS;
        if(RT.vars.captchaJSId) jsId = RT.vars.captchaJSId;
        var data = {
          url: "//www.google.com/recaptcha/api.js" + queryString,
          id: jsId
        };
        RT.ajax.getScript(data);
      }
    }
  },
  nortonSecurity: { // TODO: move to footer module when created
    init: function () {
      var dn = "www.jegs.com";
      var lang = "en";
      var tpt = "opaque";
      var splash_url = "https://trustsealinfo.websecurity.norton.com";
      var seal_url = "https://seal.websecurity.norton.com";
      var u1 = splash_url + "/splash?form_file=fdf/splash.fdf&dn=" + dn + "&lang=" + lang;
      var sopener;
      var popup = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (sopener && !sopener.closed) {
          sopener.focus();
        } else {
          var tbar = "location=yes,status=yes,resizable=yes,scrollbars=yes,width=560,height=500";
          var sw = window.open(u1, 'nortonSecurity', tbar);
          if (sw) {
            sw.focus();
            sopener = sw;
          }
        };
        return false;
      };
      $('.nortonSecurity').on('click', popup);
    }
  },
  reviveBannerAd: {
	init: function () {
	  if ($("ins").length > 0) { 
	        RT.log("afterload:reviveAd", 1);
	        var data = {
	          url: "//servedby.revive-adserver.net/asyncjs.php",
	          id: "reviveAd"
	        };
	        RT.ajax.getScript(data);
	  }  
    }
  }
};
// saves vehicle in sessionStorage for later use
READY.vehicles = {
  init: function () {
    JEGS.vehicles.ready();
  }
};
JEGS.vehicles = {
  logger: 1,
  ready: function () {
    JEGS.vehicles.getData();
    JEGS.vehicles.addClickEvents();
  },
  qs: "",
  saved: {},
  getData: function () {
    JEGS.vehicles.getVehicleSession(); // load to vars if in sessionStorage
    JEGS.vehicles.params(); // load to vars if we have params
  },
  getVehicleSession: function () {
    if (RT.vars.hasStorage) {
      var dataStr = sessionStorage.getItem('vehicles');
      if (dataStr) {
        JEGS.vehicles.saved = $.parseJSON(dataStr);
        JEGS.vehicles.qs = $.param(JEGS.vehicles.saved);
      }
      // Action Tray - Search Filters
      var filterVehStr = sessionStorage.getItem('filterVehicle');
      if (filterVehStr != "undefined") {
        JEGS.vehicles.filterVehicle = $.parseJSON(filterVehStr); // see searchFilters.js
      }
    }
  },
  params: function () {
    // save vehicle to localStorage
    var p = RT.vars.params;
    var data = {};
    if (p.make) {
      data = {
        make: p.make,
        model: p.model,
        submodel: p.submodel,
        engine: p.engine,
        vehicle: p.vehicle,
        year: p.year
      };
      // do not save empty params
      Object.keys(data).forEach(function (key) {
        if (data[key] == null) { delete data[key] }
      });
      JEGS.vehicles.saved = data; // for use in this page
      JEGS.vehicles.qs = $.param(JEGS.vehicles.saved); // use to append QS to URLs which need to add year/make/model in search
    };

    if (RT.vars.hasStorage && p.make) {
      RT.log("save vehicle selections to localStorage", this);
      var dataStr = RT.data.str(data);
      RT.log(dataStr, this);
      sessionStorage.setItem('vehicles', dataStr); // TODO: save to user profile when logged in
    }
  },
  addClickEvents: function () {
    // also see: topLevelShopLinkClick()
    // .vehicle-search,
    $('#filter-by-ymm, #results-filters').on('change', 'select', JEGS.vehicles.clickEvents.searchField);
  },
  clickEvents: {
    searchField: function (e) {
      // save vehicle data & text
      var filterVehicle = JEGS.searchFilters.filterVehicle || {};
      var vehInpTextArr = [];
      var filterCounts = $('.filterCount');
          filterCounts.remove();
      // var btn = $(e.currentTarget);
      var form = $('#filter-by-ymm, #results-filters'); // .vehicle-search, 
      var selects = form.find('select');
      selects.each(function (idx, select) {
        var inpObj = $(select);
        var selected = inpObj.find('option:selected');
        var key = inpObj.attr('id').toLowerCase().replace('filter','');
        var value = inpObj.val();
        var param = (value && value.match(/\?/) ? RT.url.QStoJSON(value)[key] : value); // just get the value of the key
        console.log("param: " + key + " = " + param);
        var index = selected.index();
        var text = selected.text();
        if (index != 0 && value) {
          filterVehicle[key + '_text'] = text;
          filterVehicle[key + '_val'] = value; // this is a queryString value
          filterVehicle[key] = param;
          RT.vars.params[key] = param; //value
          
          JEGS.searchFilters.applied[key] = param; // see searchFilters.params.submit()
          vehInpTextArr.push(text);
        }
      });
      RT.vars.params['vehicle'] = vehInpTextArr.join(' ');
      JEGS.searchFilters.filterVehicle = filterVehicle;
      console.log("filterVehicle: ", filterVehicle)
      
      /* 
      if (RT.vars.hasStorage) {
        RT.log("save vehicle selection to sessionStorage", this);
        var dataStr = RT.data.str(vehInpData);
        RT.log(dataStr, this);
        sessionStorage.setItem('filtervehicle', dataStr);
      }
      */

      JEGS.vehicles.getData();
    }
  }
};